import React, { useContext } from "react";
import { Layout, Form } from "antd";
import LoginForm from "./components/forms/LoginForm";
import LoginService from "./services/crud/LoginService";
import { ToastContext } from "./context/ToastContext";
import AdminLoginButton from "./components/buttons/AdminLoginButton";
import { NavigationContext } from "./context/NavigationContext";
import UserLoginButton from "./components/buttons/UserLoginButton";
import DevContainer from "./components/DevContainer";

const { Header, Content } = Layout;

interface LoginData {
  username: string;
  password: string;
}

function Login() {
  const { login, forgotPassword } = useContext(NavigationContext);
  const { loadMessage } = useContext(ToastContext);
  const [form] = Form.useForm<LoginData>();

  const handleLogin = () => {
    form.validateFields().then(async (values) => {
      loadMessage("Checking credentials...", () =>
        login(values.username, values.password)
          .then((user) => "Successfully logged in")
          .catch((res) => {
            throw Error("Wrong username and/or password");
          })
      );
    });
  };

  // const login = () => {
  //   form.validateFields().then(async (values) => {
  //     loadMessage("Checking credentials...", () =>
  //       LoginService.login(values.username, values.password)
  //         .then((user) => {
  //           window.location.href = "/";
  //           return "Successfully logged in";
  //         })
  //         .catch((res) => {
  //           throw Error("Wrong username and/or password");
  //         })
  //     );
  //   });
  // };
  const handleForgotPassword = () => {
    form.validateFields().then(async (values) => {
      loadMessage("Checking credentials...", () =>
        forgotPassword(values.username)
          .then((res) => {
            return "Check your email for password reset link";
          })
          .catch((res) => {
            throw Error("No admin registered with that username");
          })
      );
    });
  };

  return (
    <Layout>
      <Layout>
        <Header
          style={{
            padding: 16,
            lineHeight: "32px",
            backgroundColor: "white",
            borderBottom: "1px solid #d9d9d9",
            display: "flex",
            flexDirection: "row",
            // alignItems: "baseline",
            justifyContent: "flex-end",
            fontFamily: "Sofia Pro",
          }}
        >
          <h1 style={{ position: "relative", top: 4, fontSize: 28, fontWeight: "bold" }}>Admin</h1>
        </Header>
        <Content
          style={{
            padding: 10,
            minHeight: "100%",
            background: "#f5f5f5",
          }}
        >
          <div style={{ margin: "auto", width: 250, height: "auto" }}>
            <img alt="logo" src="/_EC2B_pos.png" style={{ width: 250, height: 250 }} />
          </div>
          <div style={{ margin: "50px auto", width: 600, height: "auto", padding: 24, backgroundColor: "white" }}>
            <LoginForm form={form} onLogin={handleLogin} onForgotPassword={handleForgotPassword} />
            <DevContainer>
              <AdminLoginButton></AdminLoginButton>
              <UserLoginButton></UserLoginButton>
            </DevContainer>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
export default Login;
