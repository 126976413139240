import { Button, Form, Input, Tooltip } from "antd";
import React, { useContext } from "react";
import { QuestionCircleFilled } from "@ant-design/icons";
import AccountService from "../../../services/crud/AccountService";
import { OverlayContext } from "../../../context/OverlayContext";
import { ToastContext } from "../../../context/ToastContext";
import { useForm } from "antd/lib/form/Form";


const AddUserButton = ({ title = "Add user", accountId, onSuccess = () => {}}: {
  title?: String,
  accountId: number,
  onSuccess?: Function,
}) => {
    const [form] = useForm<{ accountId: string | number; emails: string }>();
    const { loading, loadMessage } = useContext(ToastContext);
    const { openModal, closeModal } = useContext(OverlayContext);
    const initialData = { emails: "" };
    const openCreateModal = () => {
      form.resetFields();
      openModal({
        title: "Add emails to account",
        content: (
          <Form form={form} initialValues={initialData}>
            <Form.Item hidden name="id" label="id" required>
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="emails"
              label="Emails"
              rules={[
                {
                  required: true,
                  validator(rule, value, callback) {
                    if (value && value.length > 0) {
                      const emails = value.split(",").map((email) => email.trim());
                      const valid = emails.every((email) => {
                        return email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
                      });
                      if (valid) {
                        callback();
                      } else {
                        callback("Invalid email");
                      }
                    } else {
                      callback("Emails cannot be empty");
                    }
                  },
                },
              ]}
            >
              <Input.TextArea placeholder={"email1@mail.com, email2@mail.com, ..."} />
            </Form.Item>

            <Tooltip title="Emails seperated by comma. For example:ec2b@example.com, email@example.com">
              <QuestionCircleFilled></QuestionCircleFilled>
            </Tooltip>
          </Form>
        ),
        onOk: () => {
          form.validateFields().then(async (values) => {
            const emails = values.emails.split(",").map((email) => email.trim());
            loadMessage("Adding emails", async () => {
              const result = await Promise.any(emails.map(async (email) => AccountService.pregrantEmailToAccount(accountId, email)));
              onSuccess();
            });
            closeModal();
          });
        },
      });
    };
    return(
      <Button type="primary" title="Add users" onClick={openCreateModal} style={{marginBottom:'24px'}}>
        {title}
      </Button>
    );
};

export default AddUserButton;