import Account from "../../models/Account";
import HttpClient from "../../clients/HttpClient";
import { NOT_IMPLEMENTED_EXCEPTION } from "../Exceptions";
import User from "../../models/User";
import Hub from "../../models/Hub";
import HubService from "./HubService";
import internal from "stream";
import { InvoiceInterface } from "../../models/temp/Invoice";
import { OrganizationInterface } from "../../models/temp/Organisation";

export default class AccountService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async list(params = {}): Promise<Array<Account>> {
    return this.httpClient.get("/accounts", params).then((res) => {
      return res.data.map((row) => new Account(row));
    });
  }

  public static async getAccount(id): Promise<Account> {
    return this.httpClient.get(`/accounts/${id}`).then((res) => {
      return new Account(res.data);
    });
  }

  public static async getPrivateAccount(userId): Promise<Account> {
    return this.httpClient.get("/accounts", { userId, type: Account.types.PRIVATE }).then((res) => {
      // User should only have one private account so we return the first one
      const account = res.data.map((row) => new Account(row))[0];
      if (account === undefined) {
        throw new Error("No private account found for user");
      }
      return account;
    });
  }

  public static async addAccount(data): Promise<Account> {
    return this.httpClient.post(`/accounts`, data).then((res) => {
      return new Account(res.data);
    });
  }

  public static async editAccount(id, data): Promise<Account> {
    return this.httpClient.put(`/accounts/${id}`, data).then((res) => {
      return new Account(res.data);
    });
  }

  public static async deleteAccount(id): Promise<boolean> {
    return this.httpClient.delete(`/accounts/${id}`, null).then((res) => {
      return true;
    });
  }
  public static async listAccountsOnUser(userId): Promise<Array<Account>> {
    return this.httpClient.get("/users/admin_accounts_on_user/" + userId).then((res) => {
      return res.data.map((row) => new Account(row));
    });
  }
  /**
   * Grant emails access to an account
   * @param emails array of emails to pregrant to an account
   * @param accountId
   * @returns
   */
  public static async pregrantEmailsToAccount(accountId: string | number, emails: string[]): Promise<Account> {
    return new Account({ id: accountId, users: emails.map((email) => new User({ email })) });
  }

  public static async removeEmailFromAccount(accountId: string | number, email: string): Promise<Account> {
    return this.httpClient.delete("/accounts/detach_user_or_authorized_access_to_account", { accountId, email }).then((res) => {
      return new Account(res.data);
    });
  }

  public static async pregrantEmailToAccount(accountId: string | number, email: string, data?): Promise<Account> {
    return this.httpClient.post("/accounts/attach_user_or_authorized_access_to_account", { accountId, email, ...data }).then((res) => {
      return new Account(res.data);
    });
  }
  /**
   * Adds a hub to an account
   * @param accountId
   * @param hubId
   * @returns
   */
  public static async addHub(accountId: string | number, hubId: string | number): Promise<unknown> {
    return this.httpClient.post(`/accounts_hubs/create`, { accountId, hubId }).then((res) => {
      return res.data?.selected;
    });
  }
  public static async removeHub(accountId: string | number, hubId: string | number): Promise<unknown> {
    return this.httpClient.delete(`/accounts_hubs/delete`, { accountId, hubId }).then((res) => {
      return res.data;
    });
  }

  /**
   * Gives a user permission to an account
   * @param userId
   * @param accountId
   * @param admin
   */
  public static async addUserToAccount(userId, accountId, admin = false) {
    throw NOT_IMPLEMENTED_EXCEPTION;
  }
  /**
   * Updates user access to account. If user does not exist update authorized access to account.
   * @param email
   * @param accountId
   * @param data
   * @returns
   */
  public static async updateAccountUser(email, accountId, data): Promise<unknown> {
    await this.removeEmailFromAccount(accountId, email);
    const result = await this.pregrantEmailToAccount(accountId, email, data);
    return result;
  }

  /**
   *
   * @param accountId
   * @returns
   */
  public static async listAvailableHubs(accountId: string | number): Promise<Hub[]> {
    const hubs = await HubService.list();
    const accountsOnHub = await HubService.list({ accountId });
    const availableHubs = hubs.filter((hub) => !accountsOnHub.find((accountOnHub) => accountOnHub.id === hub.id));
    return availableHubs;
  }

  public static async updateInvoice(payload: UpdateInvoiceRequestPayload): Promise<UpdateInvoiceResponse> {
    const res = await this.httpClient.post("/accounts/update_invoice/" + payload.accountId, payload);
    return res.data;
  }
  public static async getInvoice(payload:{accountId:number|string}): Promise<InvoiceType> {
    const res = await this.httpClient.post("/accounts/get_invoice/" +payload.accountId, payload);
    return res.data;
  }
}
export type UpdateInvoiceResponse = any;

export type UpdateInvoiceRequestPayload = OrganizationInterface;
export type InvoiceType = OrganizationInterface;
class AccountUser {
  id: number;
  userId: number;
  accountId: number;
  admin: boolean;
  constructor(data) {
    this.id = data.id;
    this.userId = data.userId;
    this.accountId = data.accountId;
    this.admin = data.admin;
  }
}
