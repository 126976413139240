import React, { useState, useEffect, useContext } from "react";
import { Table, Typography, Button, Input, Form, Tooltip, Popconfirm, Tree, Dropdown, Space, MenuProps } from "antd";
import { DownOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { OverlayContext } from "../../context/OverlayContext";
import { ToastContext } from "../../context/ToastContext";
import BaseModel from "../../models/BaseModel";
import { underScoreFilter } from "../../utils/common";
import { sortBy } from "lodash";
import ActionsDropdown from "../ActionDropdown";
import { useNavigate } from "react-router";
import { GenericTableProps, TableProps } from "./types";
import lodash from "lodash";
import { json } from "stream/consumers";

/**
 * Builds a table with model, service and form.
 * Requires: form component. Optional: formCreate, formEdit and formDelete.
 * Uses property name as title name.
 * Transforms title name from camelCase to startUpper case for example: startAt => Starts at

 * @param param0
 * @returns
 */
function GenericItemsTable<ItemType extends BaseModel = BaseModel>({
  name: tableName,
  model,
  service,
  form: GenericForm,
  formCreate: GenericFormCreate,
  formEdit: GenericFormEdit,
  formDelete: GenericFormDelete,
  dataLoader,
  dataFilter = (data: ItemType) => true,
  columnFilter = (col) => true,
  columnMapper = (col) => {
    const title = col.title as string;
    // Convert camelCase to startUpper case and captialize
    const newTitle = lodash.capitalize(lodash.startCase(title));
    return { ...col, title: newTitle };
  },
  columnSorter = (a, b) => 0,
  defaultRowSorter,
  defaultPageSize = 10,
  hideButtons = false,
  includeColumns = [],
  actions = { edit: true, delete: true, add: true, view: true, select: true },
  rest,
  search = false,
  displayTitle = false,
  params = {},
  leftButton,
  data: customData,
  contextId,
  onDelete,
  onEdit,
  onAdd,
  onView,
  excludeColumns = [],
}: GenericTableProps<ItemType> & TableProps<ItemType>) {
  const [data, setData] = useState<ItemType[]>([]);
  const [filter, setFilter] = useState("");
  const { openModal, closeModal } = useContext(OverlayContext);
  const { showMessage } = useContext(ToastContext);
  const [selectedRows, setSelectedRows] = useState<Array<ItemType>>([]);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const name = tableName ?? model.name;
  const navigate = useNavigate();
  const goToPage = (route) => {
    navigate(`/${name.toLowerCase()}s/` + route);
  };

  const [loading, setLoading] = useState(false);
  function loadMessage(message: string, callback: () => Promise<any>) {
    setLoading(true);
    callback().finally(() => setLoading(false));
  }
  const operations = {
    list: "list",
    delete: "delete" + name,
    add: "add" + name,
    edit: "edit" + name,
    get: "get" + name,
  };
  function refresh() {
    loadMessage("Loading " + name + "...", async () => {

      let data = dataLoader
        ? await dataLoader()
        : await service[operations.list](params);

      data = data.filter(dataFilter)
      if (defaultRowSorter !== undefined || defaultRowSorter !== null) {
        data.sort(defaultRowSorter)
      }
      setData(data);
      return true;
    });
  }
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (customData) setData(customData);
  }, [customData]);

  // const asyncCall =
  //   (succeed = true) =>
  //   (): Promise<any> => {
  //     const promise = new Promise((resolve, reject) => {
  //       setTimeout(() => {
  //         if (succeed) {
  //           resolve("Operation succeeded");
  //         } else {
  //           reject(Error("Operation failed"));
  //         }
  //       }, 1200);
  //     });
  //     return promise;
  //   };

  // const testLoadMessageSuccess = () => {
  //   loadMessage("Operation in progress", asyncCall(true));
  // };

  // const testLoadMessageFail = () => {
  //   loadMessage("Operation in progress", asyncCall(false));
  // };

  const openCreateModal = () => {
    form.resetFields();
    if (GenericFormCreate) {
      openModal({
        title: "Add new item",
        content: <GenericFormCreate form={form} />,
        onOk: () => {
          form.validateFields().then(async (values) => {
            loadMessage("Adding item...", addItem(values));
            closeModal();
          });
        },
      });
      return;
    }
    openModal({
      title: "Add new item",
      content: <GenericForm form={form} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          loadMessage("Adding item...", addItem(values));
          closeModal();
        });
      },
    });
  };

  const openUpdateModal = (item = null) => {
    form.resetFields();
    if (GenericFormEdit) {
      openModal({
        title: "Update item",
        content: <GenericFormEdit id={item.id} form={form} />,
        onOk: () => {
          form.validateFields().then(async (values) => {
            loadMessage("Updating item...", editItem(values));
            closeModal();
          });
        },
      });
      return;
    }

    openModal({
      title: "Update item",
      content: <GenericForm id={item.id} form={form} />,
      onOk: () => {
        form
          .validateFields()
          .then(async (values) => {
            loadMessage("Updating item...", editItem(values));
            closeModal();
          })
          .catch((error) => console.log(error));
      },
    });
  };

  const openDeleteModal = (item = null) => {
    form.resetFields();
    if (GenericFormDelete) {
      openModal({
        title: "Delete item",
        content: <GenericFormDelete id={item.id} form={form} />,
        onOk: () => {
          loadMessage("Deleting item...", deleteItem(item));
          closeModal();
        },
      });
      return;
    }
    openModal({
      title: "Delete item",
      content: <Typography.Text>Are you sure?</Typography.Text>,
      onOk: () => {
        loadMessage("Deleting item...", deleteItem(item));
        closeModal();
      },
    });
  };

  const addItem = onAdd
    ? (values) => async () => onAdd(values)
    : (values) => async () => {
        console.log(values);
        return service[operations.add]({ ...values })
          .then(async () => {
            // await service[operations.list]().then((data) => setData(data));
            refresh();
            return "Added item";
          })
          .catch((e) => {
            console.log(e);
            throw Error("Could not add " + name);
          });
      };

  const editItem = onEdit
    ? (values) => async () => onEdit(values)
    : (values) => async () => {
        return service[operations.edit](values.id, values)
          .then(async () => {
            // await service[operations.list]().then((data) => setData(data));
            refresh();
            return "Item updated";
          })
          .catch((res) => {
            throw Error("Could not update " + name);
          });
      };

  const deleteItem = onDelete
    ? (item) => async () => onDelete(item)
    : (item) => async () => {
        return service[operations.delete](item.id)
          .then(async () => {
            // await service[operations.list]().then((data) => setData(data));
            refresh();
            return "Item deleted";
          })
          .catch((res) => {
            throw Error("Could not delete " + name);
          });
      };

  const filterTable = (data) => data.filter((item) => new RegExp(filter.toLowerCase()).test(item.toString().toLowerCase()));

  /*   const expandedRowRender = (row) => {
    const data = row.generalChildItems;

    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      },
      {
        title: "At date",
        dataIndex: "atDate",
        render: (text, record, index) => `${record.atDate.format("YYYY-MM-DD")}`,
        sorter: (a, b) => a.atDate.diff(b.atDate),
      },
      {
        title: "Actions",
        width: 150,
        render: (text, record, index) => (
          <div style={{ display: "flex" }}>
            <Button type="link" size="small" onClick={() => openUpdateModal(row)}>
              Edit
            </Button>
            <Button type="link" size="small" onClick={() => openDeleteModal(row)}>
              Delete
            </Button>
          </div>
        ),
      },
    ];
    return <Table rowKey="id" columns={columns} dataSource={data} />;
  }; */

  let generatedColumns: any = generateColumns<ItemType>(data);
  if (excludeColumns.length > 0) {
    generatedColumns = generatedColumns.filter(([key, value]) => !excludeColumns?.includes(key));
  }
  if (includeColumns.length > 0) {
    generatedColumns = generatedColumns.filter(([key, value]) => includeColumns?.includes(key));
  }
  generatedColumns = generatedColumns.map(([key, value]) => {
    const sorter = (a, b) => {
      const vA = a[key];
      const vB = b[key];
      if (typeof value === "string") {
        return vA.localeCompare(vB);
      }
      if (typeof value === "number") {
        return vA - vB;
      }
      if (value instanceof Date) {
        return vA - vB;
      }
      if (typeof value === "object" && value?.length) {
        return vA.length - vB.length;
      }
      // unknown type. Disable sorting
      return 0;
    };
    if (typeof value === "object") {
      // Value is array
      if (value?.length) {
        return {
          key,
          title: key,
          dataIndex: key,
          render: (text, record, index) => {
            return <Typography.Text style={{ textAlign: "center" }}>{text?.length}</Typography.Text>;
          },
          sorter,
        };
      }
      // Value is object generate another table
      return {
        key,
        title: key,
        dataIndex: key,
        render: (text, record, index) => <>{text?.name ?? text?.id}</>,
        sorter: sorter,
      };
    }

    return {
      key,
      title: key,
      dataIndex: key,
      sorter: sorter,
    };
  });

  const columns: any = [
    ...generatedColumns.map(columnMapper).sort(columnSorter),
    {
      title: "Updated At",
      key: "updatedAt",
      dataIndex: "updatedAtAsDate",
      render: (text, record, index) => text,
    },
    {
      title: "Actions",
      key: "actions",
      width: 150,
      fixed: "right",
      render: (text, record, index) => (
        <div style={{ display: "flex" }}>
          {actions.view && (
            <Button type="link" size="small" onClick={() => (onView ? onView(record) : goToPage(record.id))}>
              View
            </Button>
          )}
          {actions.edit && (
            <Button type="link" size="small" onClick={() => openUpdateModal(record)}>
              Edit
            </Button>
          )}
          {actions.delete && (
            <Button type="link" size="small" onClick={() => openDeleteModal(record)}>
              Delete
            </Button>
          )}
        </div>
      ),
    },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: ItemType[]) => {
    console.log("selectedRowKeys changed: ", selectedRows);
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRows,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRows.length > 0;
  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    for (const row of selectedRows) {
      await loadMessage("Deleting " + name + "...", deleteItem(row));
    }
    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };
  const items: MenuProps["items"] = [
    {
      label: <span onClick={showPopconfirm}>Delete selected ({selectedRows.length})</span>,
      key: "1",
    },
  ];

  const LeftButton = leftButton || (
    <Button type="primary" size="middle" onClick={() => openCreateModal()}>
      Add {name}
    </Button>
  );
  return (
    <div>
      {displayTitle && <Typography.Title level={3}>{name}s</Typography.Title>}
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <Space size="small">
          {!hideButtons && (
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a>
                <Space>
                  Actions
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          )}
          <Popconfirm
            title={"You are about to delete " + selectedRows.length + ` ${name}!`}
            open={open}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          ></Popconfirm>

          {search && (
            <Input.Search size="middle" placeholder="... search on all columns" style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} />
          )}
          {actions.select && (
            <span style={{ marginLeft: 8 }}>({hasSelected ? `Selected ${selectedRows.length} ${name}` : `Selected ${0} items`})</span>
          )}
        </Space>
        {/* <Input.Search size="middle" placeholder="Search general items..." style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} /> */}
        {/* <div style={{ display: "flex", justifyContent: "space-evenly", marginLeft: "calc(150px + 20%)", width: 400 }}>
          <Button type="ghost" size="small" onClick={() => testLoadMessageSuccess()}>
            Test successful call
          </Button>
          <Button type="ghost" size="small" onClick={() => testLoadMessageFail()}>
            Test failure
          </Button>
          <Button type="ghost" size="small" onClick={() => showMessage("Done", "success")}>
            Test message
          </Button>
        </div> */}
        {!hideButtons && LeftButton}
      </div>
      <Table
        bordered
        rowKey="id"
        rowSelection={actions.select ? rowSelection : undefined}
        columns={columns.filter(columnFilter)}
        dataSource={filterTable(data.filter(dataFilter))}
        loading={loading}
        pagination={{ total: data.length, defaultPageSize: defaultPageSize, showSizeChanger: true }}
        // expandable={{ expandedRowRender }}
        scroll={{ x: true }}
        {...rest}
      />
      <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>
        Total of {data.length} {name}s
      </Typography.Text>
    </div>
  );
}

export default GenericItemsTable;
function generateColumns<ItemType extends BaseModel = BaseModel>(data: ItemType[]): any {
  return Object.entries(data[0] || {}).filter(underScoreFilter);
}
