import React, { createContext, useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import LoginService from "../services/crud/LoginService";
import User from "../models/User";
import { Modes } from "../Modes";

const history = createBrowserHistory();

interface NavigationContextState {
  user?: User;
  history: any;
  currentPage?: string;
  gotoPage: (page: string) => void;
  logout: () => void;
  login?: (username: string, password: string) => Promise<User | boolean>;
  forgotPassword?: (username: string) => Promise<User | boolean>;
  mode: Modes;
  setMode: (mode: Modes) => void;
}

export const NavigationContext = createContext<NavigationContextState>({
  user: undefined,
  history: null,
  currentPage: "",
  // setCurrentPage: (page: any) => {},
  gotoPage: (page: string) => {},
  login: async (username: string, password: string) => undefined,
  logout: () => {},
  mode: Modes.DEVELOPER,
  setMode: (mode) => {},
});

export function NavigationProvider({ children }) {
  const [currentPage, setCurrentPage] = useState(history.location.pathname.split("/")[1]);
  const [user, setUser] = useState(undefined);
  const [mode, setMode] = useState<Modes>(Modes.DEVELOPER);
  const gotoPage = (page: string) => {
    setCurrentPage(page);
    history.push("/" + page);
  };
  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, []);
  useEffect(() => {
    if (user)
      switch (user.role) {
        case "admin":
          setMode(Modes.SYSTEM_ADMIN);
          break;
        case "user":
          setMode(Modes.COMPANY_ADMIN);
          break;
        // case "user":
        //   setMode(Modes.REAL_ESTATE_ADMIN);
        //   break;
        default:
          setMode(undefined);
          break;
      }
  }, [user]);
  const logout = () => {
    LoginService.logout();
    window.location.href = "/";
  };
  const login = (username: string, password: string) => {
    return LoginService.login(username, password).then((user) => {
      setUser(user);
      window.location.href = "/";
      return user;
    });
  };
  const forgotPassword = (username: string) => LoginService.forgotPassword(username);
  history.listen(({ location, action }) => {
    const parts = location.pathname.split("/");
    setCurrentPage(parts[1]);
  });

  return (
    <NavigationContext.Provider value={{ history, currentPage, gotoPage, logout, login, forgotPassword, user, mode, setMode }}>
      {children}
    </NavigationContext.Provider>
  );
}
