import React, { createContext, useState, ReactChildren } from "react";
import { Modal, Button } from "antd";

export const OverlayContext = createContext({
  openModal: (data = {}, children = null) => {},
  closeModal: () => {},
});

export function OverlayProvider({ children }) {
  const [modalData, setModalData] = useState({
    visible: false,
    title: "Not set",
    content: null,
    okText: "Save",
    hasCancel: true,
    width: "60%",
    style: { maxWidth: 800 },
    danger: false,
    onOk: (close) => closeModal(),
    onCancel: (close) => closeModal(),
  });

  const closeModal = () => {
    setModalData({
      ...modalData,
      visible: false,
    });
  };

  const openModal = (data = {}, children: ReactChildren = null) => {
    setModalData({
      ...modalData,
      ...data,
      visible: true,
    });
  };

  return (
    <OverlayContext.Provider value={{ openModal, closeModal }}>
      <Modal
        destroyOnClose={true}
        open={modalData.visible}
        title={modalData.title}
        okText={modalData.okText}
        width={modalData.width}
        style={modalData.style}
        onOk={modalData.onOk}
        onCancel={modalData.onCancel}
        footer={[
          modalData.hasCancel && (
            <Button key="back" onClick={modalData.onCancel}>
              Cancel
            </Button>
          ),
          <Button
            key="submit"
            // htmlType="button"
            danger={modalData.danger}
            type={modalData.hasCancel ? "primary" : "link"}
            loading={false}
            onClick={modalData.onOk}
          >
            {modalData.okText}
          </Button>,
        ]}
      >
        {modalData.content}
      </Modal>
      {children}
    </OverlayContext.Provider>
  );
}
