import { Button, Checkbox, DatePicker, Form } from "antd";
import React, { useContext, useState } from "react";
import { EntitySelector } from "../../../../components/forms/selects/EntitySelector";
import ReportService from "../../../../services/crud/ReportService";
import * as XLSX from "xlsx";
import { ToastContext } from "../../../../context/ToastContext";
import InfoBox from "../../../../components/InfoBox";
import { capitalizeFirstLetter } from "../../../../helpers/string";
import TspService from "../../../../services/crud/TspService";
import { dateStartOfLocalDay, formatDate } from "../../../../utils/dates";
import BookingExternalService from "../../../../services/crud/BookingExternalService";

interface GetExcelSheetFormPayload {
  dates: Date[];
  tspId: number;
  includeService: boolean;
  includeAborted: boolean;
  includeUserName: boolean
}

async function buildLegendWorksheet(): Promise<XLSX.WorkSheet> {
  const statusDescMap = await BookingExternalService.getStatusDescriptionMap();
  const statJson = [...statusDescMap.keys()].map((status, index) => {
      return {
        'Status': status,
        'Description': statusDescMap.get(status),
      };
    });
  return XLSX.utils.json_to_sheet(statJson)
}

async function downloadJsonAsExcel(jsonData: any[], fileName = "data.xlsx") {
  // Make columns more human readable: replace _ with ' '
  jsonData.forEach((row) => {
    Object.keys(row).forEach((key) => {
      const new_key = capitalizeFirstLetter(key.replaceAll('_', ' '));
      row[new_key] = row[key];
      delete row[key];
    });
  });

  const worksheet = XLSX.utils.json_to_sheet(jsonData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Booking payments");
  XLSX.utils.book_append_sheet(workbook, await buildLegendWorksheet(), "Legend");
  XLSX.writeFile(workbook, fileName);
}

async function getExportFileName(tspId: number, startDate: Date, endDate: Date): Promise<string> {
  const tsp = await TspService.getTsp(tspId);
  const startDateStr = formatDate(startDate);
  const endDateStr = formatDate(endDate);
  return `${tsp.name} booking payments ${startDateStr} - ${endDateStr}.xlsx`;
}

const BookingPaymentsForm: React.FC = () => {
  const { loadMessage, loading } = useContext(ToastContext);
  const onFinish = async (values: GetExcelSheetFormPayload) => {
    console.log("Success:", values);
    const startDate = dateStartOfLocalDay(values.dates[0]);
    const endDate = dateStartOfLocalDay(values.dates[1]);
    if (startDate === null || endDate === null || values.tspId === null) {
      return;
    }
    const payload = {
      startDate: startDate,
      endDate: endDate,
      tspId: values.tspId,
      includeService: values.includeService,
      includeAborted: values.includeAborted,
      includeUserName: values.includeUserName,
    };
    loadMessage("Downloading excel sheet", () => ReportService.getPaymentExcelSheet(payload));
    const data = await ReportService.getPaymentExcelSheet(payload);
    const fileName = await getExportFileName(values.tspId, startDate, endDate);
    downloadJsonAsExcel(data?.payment_sheet, fileName);
  };

  return (
    <>
      <h2>Bookings payment report</h2>
      <InfoBox>
        <p>Exporterar bokningar inkl. info om initialt och retur-köp och transaktions-idn från SVEA</p>
      </InfoBox>
      <Form<GetExcelSheetFormPayload>
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ includeService: false, includeAborted: false, includeUserName: false }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="dates"
          label="Start and end date"
          rules={[
            {
              required: true,
              message: "Please input ${label}!",
            },
          ]}
        >
          <DatePicker.RangePicker></DatePicker.RangePicker>
        </Form.Item>
        <Form.Item
          name="tspId"
          label="TSP"
          rules={[
            {
              required: true,
              message: "Please input ${label}!",
            },
          ]}
        >
          <EntitySelector.Tsps />
        </Form.Item>
        <Form.Item
          name="includeAborted"
          label="Include aborted/refunded bookings"
          valuePropName="checked"
        >
          <Checkbox/>
        </Form.Item>
        <Form.Item
          name="includeService"
          label="Include service/test bookings"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="includeUserName"
          label="Include username for each booking"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button loading={loading} type="primary" htmlType="submit">
            Download Excel sheet
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default BookingPaymentsForm;
