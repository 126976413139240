import React, { useState, useEffect, useContext } from "react";
import { Table, Typography, Input, Form, Tooltip, Tag } from "antd";
import BookingService from "../../services/crud/BookingService";
import { OverlayContext } from "../../context/OverlayContext";
import { ToastContext } from "../../context/ToastContext";
import BookingForm, { AdminGeneralBookingForm, BookingModalViewData } from "../forms/BookingForm";
import Booking, { ExternalBookingStatus } from "../../models/Booking";
import ResourceService from "../../services/crud/ResourceService";
import UserService from "../../services/crud/UserService";
import HubService from "../../services/crud/HubService";
import { listToMap } from "../../helpers/array-to-map";
import { resourceLabel } from "../../models/Resource";
import { TableProps } from "./types";
import GenericItemsTable from "./GenericItemsTable";
import BookingExternalService, { BookingExternal } from "../../services/crud/BookingExternalService";
import User from "../../models/User";
import { ColumnType } from "antd/lib/table";
import Account from "../../models/Account";
import moment from "moment";
import { formatDate, formatDateTime } from "../../utils/dates";
import { TspKeyType } from "../../models/tspKeyEnum";
import { capitalizeFirstLetter, capitalizeFirstLetterLowerRest } from "../../helpers/string";
import { TimeLineItem } from "../../models/TimeLine";

/**
 * This table is used to represent a table in database.
 * @param {props} - dataFilter to choose which data to keep. ColumnFilter which columns to display. Default is no filter.
 * @returns table populated with bookings data
 */
export interface BookingTableData {
  id: number; // User facing EC2B booking ID
  tspBookingId: number|null; // TSPs internal booking id
  resource: string;
  hub: string;
  totalPrice: string;
  initialPrice: string|null; // initial booking price in kr
  initialPriceFromWallet: string|null; // amount of the initialPrice that was paid from wallet
  returnPrice: string|null; // return price in kr
  returnPriceFromWallet: string|null; // amount of the returnPrice that was paid from wallet
  status: string;
  bookedFrom: string;
  createdAt: string;
  refundCreatedAt: string;
  bookingAbortedAt: string;
  leaseStartAt: string;
  returnedAt: string;
  returnedPaymentAt: string;
  timeLine: Array<TimeLineItem>;
  user: User;
  bookedTo: string;
  isLate: boolean;
  resourceId?: number;
  hubId?: number;
  userId?: number;
  account: Account;
  referenceId?: string;
  tspLogo?: string;
  accountName?: string;
  tspKey: TspKeyType;
  tspId: number;
  bookingType: "external" | "internal";
}

const mapBookingToTableData = (data: Booking | BookingExternal): BookingTableData => {
  if (data instanceof BookingExternal) {
    return {
      resource: data.bookingLabel,
      hub: data.pickupHub?.name,
      totalPrice: data.totalPriceInKr + " kr",
      initialPrice: data.initialPriceInKr + " kr",
      initialPriceFromWallet: data.initialPriceFromWalletInKr + " kr",
      returnPrice: data.returnPriceInKr + " kr",
      returnPriceFromWallet: data.returnPriceFromWalletInKr + " kr",
      bookedFrom: formatDateTime(data.bookFromDateTime),
      createdAt: formatDateTime(data.createdAt),
      refundCreatedAt: formatDateTime(data.refundCreatedAt),
      bookingAbortedAt: formatDateTime(data.bookingAbortedAt),
      leaseStartAt: formatDateTime(data.leaseStartAt),
      returnedAt: formatDateTime(data.leaseStartAt),
      returnedPaymentAt: formatDateTime(data.returnedPaymentAt),
      timeLine: BookingExternalService.getBookingTimeLine(data),
      status: data.status,
      user: data.user,
      bookedTo: formatDateTime(data.bookToDateTime),
      isLate: [ExternalBookingStatus.LEASE_STARTED.toUpperCase(), ExternalBookingStatus.LEASE_EXTENDED.toUpperCase()].indexOf(data.status.toUpperCase()) !== -1 &&
        data.bookToDateTime < (new Date()),
      userId: data.userId,
      hubId: data.pickupHub?.id,
      resourceId: data.itemId,
      account: data.account,
      referenceId: data.referenceId,
      accountName: data.account?.name,
      id: data.id,
      tspBookingId: data.bookingId,
      tspKey: data.tspKey,
      bookingType: "external",
      tspId: data.tspId,
    };
  } else {
    const startDate = data["bookingStartDatetime"];
    const createdAt = data["createdAt"];
    const endDate = data["bookingEndDatetime"];
    return {
      resource: data.resource?.name,
      hub: data.resource.hub.name,
      totalPrice: `${data.price} ${data.currency}`,
      initialPrice: null, // Not available for bikes
      initialPriceFromWallet: null, // Not available for bikes
      returnPrice: null, // Not available for bikes
      returnPriceFromWallet: null, // Not available for bikes
      status: data.status,
      bookedFrom: formatDateTime(startDate),
      createdAt: formatDateTime(createdAt),
      refundCreatedAt: null, // Not available for bikes
      bookingAbortedAt: null, // Not available for bikes
      leaseStartAt: null, // Not available for bikes
      returnedAt: null, // Not available for bikes
      returnedPaymentAt: null, // Not available for bikes
      timeLine: [
        {
          date: createdAt,
          label: 'Booked',
          description: undefined,
          color: 'blue',
        },
        {
          date: startDate,
          label: 'Booked from',
          description: undefined,
          color: 'green',
        },
        {
          date: endDate,
          label: 'Booked to',
          description: undefined,
          color: 'green',
        },
      ],
      user: data.user,
      bookedTo: formatDateTime(endDate),
      isLate: false, // non-external bookings are deprecated and will be removed.
      userId: data.userId,
      resourceId: data.resourceId,
      hubId: data.resource.hubId,
      account: data.account,
      referenceId: data.referenceId,
      accountName: data.account?.name,
      id:data.id,
      tspBookingId:null,
      tspKey: data?.resource?.tsp?.tspKey,
      bookingType: "internal",
      tspId: data.resource?.tsp?.id,
    };
  }
};

function mapBookingTableDataToBookingModalViewData(booking: BookingTableData): BookingModalViewData {
  return {
    id: booking.id,
    tspBookingId: booking.tspBookingId,
    bookingLabel: booking.resource,
    user: booking.user,
    accountName: booking.accountName,
    totalPrice: booking.totalPrice,
    initialPrice: booking.initialPrice,
    initialPriceFromWallet: booking.initialPriceFromWallet,
    returnPrice: booking.returnPrice,
    returnPriceFromWallet: booking.returnPriceFromWallet,
    status: booking.status,
    referenceId: booking.referenceId,
    createdAt: booking.createdAt,
    timeLine: booking.timeLine,
    bookedFrom: booking.bookedFrom,
    bookedTo: booking.bookedTo,
    isLate: booking.isLate,
    tspKey: booking.tspKey,
    bookingType: booking.bookingType,
    tspId: booking.tspId,
  };
}

export function mapAnyBookingDataToBookingModalViewData(booking: Booking | BookingExternal): BookingModalViewData {
  return mapBookingTableDataToBookingModalViewData(mapBookingToTableData(booking));
}

function Bookings({ dataFilter = (data: BookingTableData) => true, columnFilter = (col) => true }: TableProps<BookingTableData>) {
  const [bookings, setBookings] = useState<Array<BookingTableData>>([]);
  const [resources, setResources] = useState({});
  const [hubs, setHubs] = useState({});
  const [filter, setFilter] = useState("");
  const { openModal } = useContext(OverlayContext);
  const { loading, loadMessage } = useContext(ToastContext);
  const [form] = Form.useForm();

  useEffect(() => {
    loadMessage("Loading Bookings...", () =>
      Promise.all([BookingService.list(), UserService.list(), ResourceService.list(), HubService.list(), BookingExternalService.list()]).then(
        (data) => {
          const bookings = data[0].map(mapBookingToTableData).filter(dataFilter);
          const externalBookings = data[4].map(mapBookingToTableData).filter(dataFilter);
          const allBookings = [...bookings, ...externalBookings];
          allBookings.sort((a: any, b: any) => -1 * `${a.bookedFrom} ${a.bookedTo}`.localeCompare(`${b.bookedFrom} ${b.bookedTo}`));
          setBookings(allBookings);
          setResources(listToMap(data[2]));
          setHubs(listToMap(data[3]));
        }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const openCreateModal = () => {
  //   openModal({
  //     title: "Add new booking",
  //     content: <BookingForm form={form} />,
  //     onOk: () => {
  //       form
  //         .validateFields()
  //         .then(async (values) => {
  //           closeModal();
  //           loadMessage("Creating booking...", () => BookingService.addBooking(values).then(booking => {
  //             setBookings([booking, ...bookings]);
  //             return "Booking added";
  //           }).catch(res => {
  //             console.log(res);
  //             throw Error("Could not create booking");
  //           }));
  //         })
  //     },
  //   });
  // };

  // const openUpdateModal = (booking = null) => {
  //   openModal({
  //     title: "Update booking",
  //     content: <BookingForm form={form} update={true} />,
  //     onOk: () => {
  //       form
  //         .validateFields()
  //         .then(async (values) => {
  //           closeModal();
  //           loadMessage("Updating booking...", () => BookingService.editBooking(values.id, values).then(booking => {
  //             const i = bookings.findIndex(item => item.id === booking.id)
  //             bookings[i] = booking;
  //             setBookings(bookings);
  //             return "Booking updated";
  //           }).catch(res => {
  //             console.log(res);
  //             throw Error("Could not update booking");
  //           }));
  //         })
  //     },
  //   });
  // };

  const openViewModal = (booking: BookingTableData) => {
    const bookingModalData: BookingModalViewData = mapBookingTableDataToBookingModalViewData(booking);
    openModal({
      title: "View booking",
      content: <BookingForm setBookings={setBookings} booking={bookingModalData} />,
      okText: "Close",
      hasCancel: false,
    });
  };

  // const openDeleteModal = (booking = null) => {
  //   openModal({
  //     title: "Cancel booking",
  //     content: <Typography.Text>Are you sure?</Typography.Text>,
  //     onOk: () => {
  //       closeModal();
  //       loadMessage("Deleting booking...", () => BookingService.deleteBooking(booking.id).then(success => {
  //         const i = bookings.findIndex(item => item.id === booking.id)
  //         bookings.splice(i, 1);
  //         setBookings(bookings);
  //         return "Booking canceled";
  //       }).catch(res => {
  //         console.log(res);
  //         throw Error("Could not cancel booking");
  //       }));
  //     },
  //   });
  // };

  const columns: ColumnType<BookingTableData>[] = [
    {
      title: "ID",
      key: "id", // Used by UserProfile via columnFilter prop
      dataIndex: ["id"],
      render: (text, record, index) => {
        return (
          <div>
          {record.id}
          </div>
        );
      },
      sorter: (a, b) => b.id - a.id,
    },
    {
      title: "User",
      key: "userId", // Used by UserProfile via columnFilter prop
      dataIndex: ["user"],
      render: (text, record, index) => {
        return (
          <Tooltip title={`${record?.user?.username ?? ""} ${record?.user?.lastName ?? ""}`.trim()}>
            {record?.user?.username ?? `user id: ${record?.user?.id}`}
          </Tooltip>
        );
      },
      sorter: (a, b) =>
        (a.user?.username?.toLowerCase() ?? a.user?.id.toString()).localeCompare(b.user?.username?.toLowerCase() ?? b.user?.id.toString()),
    },
    {
      title: "Resource",
      key: "resourceId", // Used by ResourceView via columnFilter prop
      dataIndex: ["resource"],
      render: (text, record, index) => {
        return <Tooltip title={record?.resource}>{record?.resource}</Tooltip>;
      },
      sorter: (a, b) => (a.resource?.toLowerCase() ?? "").localeCompare(b.resource?.toLowerCase()),
    },
    {
      title: "Hub",
      key: "hubId", // Used by HubView via columnFilter prop
      dataIndex: ["hub"],
      render: (text, record, index) => {
        return text ?? "";
      },
      sorter: (a, b) => (a.hub?.toLowerCase() ?? "").localeCompare(b.hub?.toLowerCase() ?? ""),
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (text, record, index) => {
        return `${record.totalPrice}`;
      },
      sorter: (a, b) => a.totalPrice.localeCompare(b.totalPrice),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record, index) => {
        return (
          <>
            <Tag color={Booking.STATUS_COLORS[record.status.toLowerCase()]}>
              {capitalizeFirstLetterLowerRest(record.status)}
            </Tag>
            {record.isLate && <Tag color="red">Late</Tag>}
          </>
        );
      },
      sorter: (a, b) => a.status.toLowerCase().localeCompare(b.status.toLowerCase()),
    },
    {
      title: "Bokad tid",
      dataIndex: "bookedFrom",
      render: (text, record, index) => {
        return (
          <>
            <span aria-label="from">{record.bookedFrom}</span>
            <span aria-hidden="true"> - </span>
            <span
              aria-label="to"
              style={{color: record.isLate ? 'red': undefined}}
            >{record.bookedTo}</span>
          </>
        )
      },
      sorter: (a, b) => `${a.bookedFrom} ${a.bookedTo}`.localeCompare(`${b.bookedFrom} ${b.bookedTo}`),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (text, record, index) => {
        return `${record.createdAt}`;
      },
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
  ];
  // Filters
  const filterColumns = (columns = []) => columns.filter(columnFilter);
  const filterTable = (data) => data.filter((item) => {
    if (filter.trim() === '') return true;
    const itemData = [
      item.id.toString(),
      (item.tspBookingId ?? '').toString(),
      item.hub ?? '',
      (item.user?.firstName ?? '') + ' ' + (item.user?.lastName ?? ''),
      (item.user?.username ?? ''),
    ]
    return itemData
      .map((itemPart) => (itemPart as String).toLowerCase().indexOf(filter.toLowerCase()) !== -1)
      .reduce((any, value) => any || value, false);
  });

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <Input.Search size="middle" placeholder="Search bookings..." style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} />
        {/* <Button type="primary" size="middle" onClick={() => openCreateModal()}>
          Create new booking
        </Button> */}
      </div>
      <Table
        rowKey="id"
        columns={filterColumns(columns)}
        dataSource={filterTable(bookings)}
        onRow={(record) => ({ onClick: () => openViewModal(record) })}
        pagination={{ total: bookings.length, defaultPageSize: 30, showSizeChanger: true }}
        loading={loading}
      />
      <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>Total of {bookings.length} bookings</Typography.Text>
    </div>
  );
}
export const GenericBookingsTable = ({ ...rest }: TableProps<Booking>) => {
  return (
    <GenericItemsTable model={Booking} formCreate={AdminGeneralBookingForm} form={BookingForm} service={BookingService} {...rest}></GenericItemsTable>
  );
};

export default Bookings;
