import React from "react";
import BookingsTable from "../../../components/tables/BookingsTable";

function Bookings() {
  return (
    <div>
      <BookingsTable />
    </div>
  );
}

export default Bookings;
