import BookingPaymentsForm from "./components/BookingPaymentsForm";
import Tabs, { TabsProps } from "antd/lib/tabs";
import { Typography } from "antd";
import AccountsKpiTab from "./components/AccountsKpiTab";

export default function ReportsPage() {
  const tabItems: TabsProps["items"] = [
    {
      key: "bookingPayments",
      label: `Booking Payments`,
      children: (
        <BookingPaymentsForm />
      ),
    },
    {
      key: "accountKpis",
      label: `Account booking KPIs`,
      children: (
        <AccountsKpiTab />
      ),
    },
  ];

  return (
    <div>
      <Typography.Title level={3}>
        {'Reports'}
      </Typography.Title>
      <Tabs type="line" style={{ minHeight: "100vh" }} defaultActiveKey="bookingPayments" items={tabItems} />
    </div>
  );
}