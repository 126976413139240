import { Button, Tooltip } from "antd";
import React, { useContext } from "react";
import { ToastContext } from "../../../context/ToastContext";
import AccountService from "../../../services/crud/AccountService";
import { useForm } from "antd/lib/form/Form";
import { OverlayContext } from "../../../context/OverlayContext";
import { PrivateAccountForm } from "./PrivateAccountForm";

export const PrivateAccountFormApplication = ({ id, title = "Edit balance", onSuccess = () => {} }) => {
  const [form] = useForm<{ id: string | number; balance: number }>();
  const { loading, loadMessage } = useContext(ToastContext);
  const { openModal, closeModal } = useContext(OverlayContext);

  const openCreateModal = () => {
    form.resetFields();
    openModal({
      title,
      content: <PrivateAccountForm form={form} id={id}></PrivateAccountForm>,
      onOk: () => {
        form.validateFields().then(async (values) => {
          loadMessage("Updating", async () => {
            await AccountService.editAccount(values.id, { balance: values.balance });
            onSuccess();
            return "Updated";
          });
          closeModal();
        });
      },
    });
  };

  return (
    <Tooltip title="Edit balance for private account">
      <Button disabled={loading} type="primary" onClick={openCreateModal}>
        Edit balance
      </Button>
    </Tooltip>
  );
};
