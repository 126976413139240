import React, { useState, useEffect, useContext } from "react";
import { Table, Typography, Input, Form, Tooltip, Tag, Button, Popconfirm } from "antd";
import AccountService from "../../services/crud/AccountService";
import { OverlayContext } from "../../context/OverlayContext";
import { ToastContext } from "../../context/ToastContext";
// TODO: Implement AccountForm
// import AccountForm from "../AccountForm";
import Account from "../../models/Account";
import AccountForm from "../forms/AccountForm";
import { useNavigate } from "react-router";
import { TableProps } from "./types";
import GenericItemsTable from "./GenericItemsTable";

/**
 * This table loads data from backend and filters based on dataFilter and columnFilter.
 * @param {number} userId - default undefined. If defined loads alla accounts related to user
 * @param {function} dataFilter - filters data
 * @param {function} columnFilter - filter columns based on key
 * @returns table populated with accounts
 */
function AccountsTable({ userId = undefined, dataFilter = (data: Account) => true, columnFilter = (col) => true }: TableProps<Account>) {
  /// A function (account) => boolean, that is passed to filter() function
  /// to filter out which accounts to keep.
  // Similarly an optional function to filter which columns to keep

  const [accounts, setAccounts] = useState<Array<Account>>([]);
  const [filter, setFilter] = useState("");
  const { openModal, closeModal } = useContext(OverlayContext);
  const { loading, loadMessage } = useContext(ToastContext);
  const [selectedRows, setSelectedRows] = useState<Array<Account>>([]);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate("/accounts/" + route);
  };
  function loadTable() {
    if (userId) {
      // Load all accounts for user
      loadMessage("Loading Accounts for user...", () =>
        Promise.all([AccountService.listAccountsOnUser(userId)]).then((data) => {
          const accounts = data[0].filter(dataFilter);
          accounts.sort((a, b) => a.name.localeCompare(b.name));
          setAccounts(accounts);
        })
      );
    } else {
      // Load all accounts
      loadMessage("Loading Accounts...", () =>
        Promise.all([AccountService.list()]).then((data) => {
          const accounts = data[0].filter(dataFilter);
          setAccounts(accounts);
        })
      );
    }
  }
  useEffect(() => {
    loadTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateModal = () => {
    openModal({
      title: "Add new account",
      content: <AccountForm form={form} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          closeModal();
          loadMessage("Creating account...", () =>
            AccountService.addAccount(values)
              .then((account) => {
                setAccounts([account, ...accounts]);
                return "Account added";
              })
              .catch((res) => {
                console.log(res);
                throw Error("Could not create account");
              })
          );
        });
      },
    });
  };

  // const openUpdateModal = (account = null) => {
  //   openModal({
  //     title: "Update account",
  //     content: <AccountForm form={form} update={true} />,
  //     onOk: () => {
  //       form
  //         .validateFields()
  //         .then(async (values) => {
  //           closeModal();
  //           loadMessage("Updating account...", () => AccountService.editAccount(values.id, values).then(account => {
  //             const i = accounts.findIndex(item => item.id === account.id)
  //             accounts[i] = account;
  //             setAccounts(accounts);
  //             return "Account updated";
  //           }).catch(res => {
  //             console.log(res);
  //             throw Error("Could not update account");
  //           }));
  //         })
  //     },
  //   });
  // };

  // TODO: Implement AccountForm
  const openViewModal = (account = null) => {
    // openModal({
    //   title: "View account",
    //   content: <AccountForm id={account.id} form={form} update={false} />,
    //   okText: "Close",
    //   hasCancel: false,
    // });
  };

  // const openDeleteModal = (account = null) => {
  //   openModal({
  //     title: "Cancel account",
  //     content: <Typography.Text>Are you sure?</Typography.Text>,
  //     onOk: () => {
  //       closeModal();
  //       loadMessage("Deleting account...", () => AccountService.deleteAccount(account.id).then(success => {
  //         const i = accounts.findIndex(item => item.id === account.id)
  //         accounts.splice(i, 1);
  //         setAccounts(accounts);
  //         return "Account canceled";
  //       }).catch(res => {
  //         console.log(res);
  //         throw Error("Could not cancel account");
  //       }));
  //     },
  //   });
  // };

  const filterTable = (data) => data.filter((item) => new RegExp(filter.toLowerCase()).test(item.toString().toLowerCase()));
  // Create columns for each property in data

  var columns: any = [
    {
      title: "Name",
      key: "name", // Used by UserProfile via columnFilter prop
      dataIndex: ["name"],
      render: (text, record, index) => {
        return <Tooltip title={`${record?.name ?? ""}`.trim()}>{record?.name ?? ""}</Tooltip>;
      },
      sorter: (a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
    },
    {
      title: "Type",
      key: "type",
      dataIndex: ["type"],
      render: (text, record, index) => {
        return <Tooltip title={`${record?.type ?? ""}`.trim()}>{record?.type ?? ""}</Tooltip>;
      },
      sorter: (a, b) => a?.type?.toLowerCase().localeCompare(b?.type?.toLowerCase()),
    },

    {
      title: "Balance",
      key: "balance",
      dataIndex: ["balance"],
      render: (text, record, index) => {
        return <Tooltip title={`${record?.balance ?? ""}`.trim()}>{record?.balance ?? ""}</Tooltip>;
      },
      sorter: (a, b) => a.balance - b.balance,
    },

    {
      title: "Created",
      dataIndex: "createdAt",
      render: (text, record, index) => {
        return `${record.createdAtAsDate}`;
      },
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
  ];
  // Filters
  const filterColumns = (columns = []) => columns.filter(columnFilter);

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: Account[]) => {
    console.log("selectedRowKeys changed: ", selectedRows);
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRows,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRows.length > 0;
  const deleteAccounts = async (accounts) => {
    loadMessage("Deleting accounts...", () =>
      Promise.all([accounts.map((account) => AccountService.deleteAccount(account.id))]).then((data) => {
        // Refresh table
        loadTable();
        return true;
      })
    );
  };
  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      await deleteAccounts(selectedRows);
    } catch (error) {
      console.log(error);
    }
    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <div>
          <Popconfirm
            title={"You are about to delete " + selectedRows.length + " accounts!"}
            open={open}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          >
            <Button type="primary" onClick={showPopconfirm}>
              Delete Accounts
            </Button>
          </Popconfirm>
          <Input.Search size="middle" placeholder="Search accounts..." style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} />
          <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedRows.length} items` : ""}</span>
        </div>
        <Button type="primary" size="middle" onClick={() => openCreateModal()}>
          Create new account
        </Button>
      </div>
      <Table
        rowKey="id"
        rowSelection={rowSelection}
        columns={filterColumns(columns)}
        dataSource={filterTable(accounts)}
        pagination={{ total: accounts.length, defaultPageSize: 30, showSizeChanger: true }}
        onRow={(record) => ({ onClick: () => gotoPage(record.id) })}
        loading={loading}
      />
      <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>Total of {accounts.length} accounts</Typography.Text>
    </div>
  );
}
export const GenericAccountsTable = ({ ...rest }: TableProps<Account>) => {
  return <GenericItemsTable
    model={Account}
    form={AccountForm}
    service={AccountService}
    defaultRowSorter={(a, b) => a?.name?.localeCompare(b?.name)}
    defaultPageSize={50}
    {...rest}
  ></GenericItemsTable>;
};
export default AccountsTable;
