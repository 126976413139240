import Subscription from "../../models/Subscription";
import HttpClient from "../../clients/HttpClient";
import AccountService from "./AccountService";

export default class SubscriptionService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async list(params = {}): Promise<Array<Subscription>> {
    return this.httpClient.get("/subscriptions", params).then((res) => {
      return res.data.map((row) => new Subscription(row));
    });
  }

  public static async listUserSubscriptions(userId): Promise<Array<Subscription>> {
    return this.list(userId);
  }

  public static async getSubscription(id): Promise<Subscription> {
    return this.httpClient.get(`/subscriptions/${id}`).then((res) => {
      return new Subscription(res.data);
    });
  }

  public static async addSubscription(data: { planId: any; accountId: any }): Promise<Subscription> {
    return this.httpClient.post(`/subscriptions`, data).then((res) => {
      return new Subscription(res.data);
    });
  }

  public static async editSubscription(id, data): Promise<Subscription> {
    if (data.products) {
      data.products = data.products.map((x) => ({ ...x, balance: parseFloat(x.balance) }));
    }
    return this.httpClient.put(`/subscriptions/${id}`, data).then((res) => {
      return new Subscription(res.data);
    });
  }

  public static async deleteSubscription(id): Promise<boolean> {
    return this.httpClient.delete(`/subscriptions/${id}`).then((res) => {
      return true;
    });
  }

  public static async unsubscribeFromSubscription(id, data: { accountId: number | string }): Promise<boolean> {
    return this.httpClient.delete(`/subscriptions/${id}`, data).then((res) => {
      return true;
    });
  }

  public static async pauseSubscription(id): Promise<Subscription> {
    return this.httpClient.put(`/subscriptions/${id}/pause`, null).then((res) => {
      return new Subscription(res.data);
    });
  }

  public static async unpauseSubscription(id): Promise<Subscription> {
    return this.httpClient.put(`/subscriptions/${id}/unpause`, null).then((res) => {
      return new Subscription(res.data);
    });
  }

  public static async shareSubscription(id, username): Promise<Subscription> {
    return this.httpClient.put(`/subscriptions/${id}/share`, { username: username }).then((res) => {
      return new Subscription(res.data);
    });
  }

  public static async unshareSubscription(id, username): Promise<Subscription> {
    return this.httpClient.put(`/subscriptions/${id}/unshare`, { username: username }).then((res) => {
      return new Subscription(res.data);
    });
  }
}
