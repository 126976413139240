import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

export const GoBackButton = () => {
  let navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate(route);
  };
  return (
    <Button size="small" icon={<ArrowLeftOutlined />} type="link" onClick={() => gotoPage(-1)}>
      Go back
    </Button>
  );
};
