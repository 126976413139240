import HttpClient from "../../clients/HttpClient";
export default class AuthService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async validateJWT(): Promise<boolean> {
    try {
      const res = await this.httpClient.get("/auth/valid_token");
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}
