import React from "react";
import AccountsTable from "../../../components/tables/AccountsTable";

type Props = {};
/**
 * This page lists all accounts.
 * @param param0
 * @returns
 */
function Accounts({}: Props) {
  return (
    <div>
      <AccountsTable></AccountsTable>
    </div>
  );
}

export default Accounts;
