import BaseModel from "./BaseModel";
import Hub from "./Hub";
import Location from "./Location";
import Tsp from "./Tsp";

class Resource extends BaseModel {
  name: string;
  type: string;
  size: string;
  other: string;
  macAddress: string;
  modelId: string;
  location?: Location | any;
  hubId: number;
  id: number;
  blocked?: boolean;
  productId?: number | string;
  hub?: Hub;
  tspId?: number;
  tsp: Tsp;
  priceIncVatCents: number;
  constructor(data) {
    super(data, []);
    this.name = data.name;
    this.type = data.type;
    this.size = data.size;
    this.other = data.other;
    this.macAddress = data.macAddress;
    this.modelId = data.modelId;
    this.location = data.location ? new Location(data.location) : ({} as Location);
    this.hubId = data.hubId;
    this.id = data.id;
    this.blocked = data.blocked;
    this.productId = data.productId;
    this.hub = data?.hub;
    this.tspId = data?.tspId;
    this.tsp = data?.tsp;
    this.priceIncVatCents = data.priceIncVatCents;
  }
}

export default Resource;

/**
 * Get main name/label of a resource
 *
 * Note: I wanted to put this on Resource model, but the BaseModel seems
 * to do magic that makes it not possible to add a member function the normal
 * ES6 way.
 */
export function resourceLabel(resource: Resource | undefined): string {
  if (resource === undefined) return "";
  return `${resource.name ?? ""}`.trim();
}

/** Get the subtitle of a resource - models behaviour in frontend-app */
export function resourceSubtitle(resource: Resource | undefined): string {
  if (resource === undefined) return "";
  return [resource.modelId ?? "", resource.size ?? "", resource.other ?? ""].filter((s) => s.trim() !== "").join(", ");
}
