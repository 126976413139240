import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Space, Table, Tooltip, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationContext } from "../../../context/NavigationContext";
import { ToastContext } from "../../../context/ToastContext";
import { useForm } from "antd/lib/form/Form";
import { OverlayContext } from "../../../context/OverlayContext";
import Subscription from "../../../models/Subscription";
import SubscriptionService from "../../../services/crud/SubscriptionService";
import { ColumnType } from "antd/lib/table/interface";
import { getPriceForPlan } from "../../../utils/common";
import DeleteForm from "../../../components/forms/DeleteForm";
import PlanService from "../../../services/crud/PlanService";
import { useRecoilState, useSetRecoilState } from "recoil";
import { plansState, subscriptionsState } from "../../../recoil/atoms";
import { Props } from "./CompanyAccountsView";

export const SubscriptionsTable = ({ dataSource = undefined, id = undefined, onDeleteSelected = (key) => {} }) => {
  const [subscriptions, setSubscriptions] = useRecoilState(subscriptionsState);
  const setPlans = useSetRecoilState(plansState);
  const [form] = useForm();
  const { openModal, closeModal } = useContext(OverlayContext);
  const { user } = useContext(NavigationContext);
  const [filter, setFilter] = useState("");
  const { loading, loadMessage, showMessage } = useContext(ToastContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate("/companies/" + route);
  };
  const openDeleteModal = (subscription: Subscription) => {
    openModal({
      title: "Terminate item",
      danger: true,
      okText: "Terminate",
      width: "40%",
      content: <DeleteForm form={form} warningText={"Terminating a subscription is permanent"} easy></DeleteForm>,
      onOk: () => {
        closeModal();
        form.validateFields().then(async (values) => {
          loadMessage("Terminate item...", () =>
            SubscriptionService.unsubscribeFromSubscription(subscription.id, { accountId: subscription.accountId })
              .then((success) => {
                setSubscriptions((prev) => [...prev.filter((item) => item.id !== subscription.id)]);
                PlanService.getPlan(subscription.planId).then((plan) => setPlans((curr) => [plan, ...curr]));
              })
              .then(() => "Item terminated")
              .catch((res) => {
                console.log(res);
                throw Error("Could not terminate subscription");
              })
          );
        });
      },
    });
  };
  function loadTable(id) {
    if (id) {
      // Load all accounts for user
      loadMessage("Loading users...", () =>
        SubscriptionService.list({ accountId: id }).then((subscriptions) => {
          setSubscriptions(subscriptions);
        })
      );
    }
  }
  useEffect(() => {
    if (dataSource) setSubscriptions(dataSource);
  }, [dataSource]);
  useEffect(() => {
    if (id) loadTable(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);

  const items: MenuProps["items"] = [
    {
      label: "Terminate selected",
      key: "UNSUBSCRIBE_SELECTED",
    },
  ];
  const onClick = async ({ key }) => {
    switch (key) {
      case "UNSUBSCRIBE_SELECTED":
        await SubscriptionService.deleteSubscription(key.id);
        loadTable(id);
        break;
    }
  };

  const ActionsDropdown: React.FC<Props> = ({}) => (
    <Dropdown disabled menu={{ items, onClick }} trigger={["click"]}>
      <a>
        <Space>
          Actions
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );

  const columnns: ColumnType<Subscription>[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: ["name"],
      render: (text, record, index) => {
        return <Typography.Text>{record?.plan?.name ?? "Missing name"}</Typography.Text>;
      },
      // sorter: (a, b) => a?.type?.toLowerCase().localeCompare(b?.type?.toLowerCase()),
    },
    {
      title: "Price",
      key: "price",
      dataIndex: ["priceInclVat"],
      render: (text, record, index) => {
        return <Typography.Text>{getPriceForPlan(record?.plan) ?? "Missing price"}</Typography.Text>;
      },
      // sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: "Created at",
      key: "createdAt",
      dataIndex: ["createdAtAsDate"],
      render: (text, record, index) => {
        return <Typography.Text>{text}</Typography.Text>;
      },
      // sorter: (a, b) => a.balance - b.balance,
    },
    // {
    //   title: "Bookings",
    //   key: "bookings",
    //   dataIndex: ["bookings"],
    //   render: (text, record, index) => {
    //     return <Typography.Text>{text?.length}</Typography.Text>;
    //   },
    //   // sorter: (a, b) => a.balance - b.balance,
    // },
    {
      title: "Actions",
      key: "actions",
      width: 150,
      fixed: "right",
      render: (text, record, index) => (
        <div style={{ display: "flex" }}>
          <Tooltip title="Avslutar abonnemanget">
            <Button danger type="link" size="small" onClick={() => openDeleteModal(record)}>
              Terminate
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  // Row selection
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  console.log(subscriptions);
  return (
    <div>
      <Typography.Title level={3}>Subscriptions</Typography.Title>
      <Space style={{ marginBottom: 10, justifyContent: "space-between", width: "100%" }}>
        <Space>
          <ActionsDropdown></ActionsDropdown>
          <span style={{ marginLeft: 8 }}> {`(Selected ${selectedRowKeys.length} items)`} </span>
        </Space>
        {/* <SubscriptionFromApplication onSuccess={() => loadTable(id)} accountId={id} /> */}
      </Space>

      <Table
        loading={loading}
        bordered
        rowKey="id"
        dataSource={subscriptions}
        rowSelection={rowSelection}
        columns={columnns}
        pagination={{ total: subscriptions.length, defaultPageSize: 30, showSizeChanger: true }}
      />
    </div>
  );
};
