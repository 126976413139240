import User from "../../models/User";
import HttpClient from "../../clients/HttpClient";
export default class LoginService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async login(username, password): Promise<User | boolean> {
    return this.httpClient.post("/auth", { username: username, password: password }).then((res) => {
      console.log(res);
      const jwtToken = res.data.jwt;
      this.httpClient.authenticate(jwtToken);
      localStorage.setItem("user", JSON.stringify(res.data));

      return new User(res.data);
    });
  }

  public static async logout(): Promise<boolean> {
    // return this.httpClient.delete("/auth", {}).then((res) => {
    this.httpClient.invalidate();
    localStorage.removeItem("user");
    return true;
    // });
  }

  public static async forgotPassword(username): Promise<User | boolean> {
    return this.httpClient.post("/password_resets", { username: username }).then((res) => {
      return res.statusText === "OK";
    });
  }

  public static isLoggedIn(): boolean {
    return this.httpClient.isAuthenticated();
  }
}
