import React, { useState, useEffect, useContext } from "react";
import { Table, Typography, Input, Tooltip, Form } from "antd";
import { ToastContext } from "../../../context/ToastContext";

import { useNavigate } from "react-router-dom";
import UsersTable from "../../../components/tables/UsersTable";
import { OverlayContext } from "../../../context/OverlayContext";

function Users() {
  let navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate(route);
  };

  const { openModal, closeModal } = useContext(OverlayContext);
  const [form] = Form.useForm();
  const { loadMessage } = useContext(ToastContext);

  const columns: any = [
    {
      title: "User",
      dataIndex: "username",
      render: (text, record, index) => (
        <Tooltip title="Go to users profile">
          <Typography.Text style={{ cursor: "pointer" }}> {record.username}</Typography.Text>
        </Tooltip>
      ),
      sorter: (a, b) => a.username.toLowerCase().localeCompare(b.username.toLowerCase()),
    },
    {
      title: "Name",
      key: "name",
      render: (text, record, index) => `${record.firstName} ${record.lastName}`,
      sorter: (a, b) => `${a.firstName} ${a.lastName}`.toLowerCase().localeCompare(`${b.firstName} ${a.lastName}`.toLowerCase()),
    },
    // {
    //   title: "Personal ID",
    //   dataIndex: "personalId",
    //   sorter: (a, b) => `${a.personalId}`.toLowerCase().localeCompare(`${b.personalId}`.toLowerCase()),
    // },
    // {
    //   title: "Phone number",
    //   dataIndex: "phoneNumber",
    //   render: (text, record, index) => <Typography.Text>{record.phoneNumber}</Typography.Text>,
    //   sorter: (a, b) => `${a.phoneNumber}`.toLowerCase().localeCompare(`${b.phoneNumber}`.toLowerCase()),
    // },
    // {
    //   title: "Active services",
    //   dataIndex: "subscriptions",
    //   render: (text, record, index) => {
    //     if (record.subscriptions.length > 0) {
    //       const services = new Set(
    //         record.subscriptions.map((s) => {
    //           return { name: s.plan?.tsp.name, id: s.plan?.tsp.id, uid: s.id };
    //         })
    //       );
    //       return Array.from(services).map((s) => (
    //         <Tag key={`${s["uid"]}-${record["id"]}`} color="blue">
    //           {s["name"]}
    //         </Tag>
    //       ));
    //     } else {
    //       return [];
    //     }
    //   },
    //   sorter: (a, b) => `${a.phoneNumber}`.toLowerCase().localeCompare(`${b.phoneNumber}`.toLowerCase()),
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   render: (text, record, index) => <Typography.Text>{record.addressLine1 ?? ""} {record.zipCode ?? ""} {record.city ?? ""}</Typography.Text>,
    //   sorter: (a, b) => `${a.addressLine1}`.toLowerCase().localeCompare(`${b.addressLine1}`.toLowerCase()),
    // },
    {
      title: "Last login",
      dataIndex: "lastLoginAt",
      render: (text, record, index) => {
        return `${record.lastLoginAtAsDate}`;
      },
      sorter: (a, b) => a.lastLoginAt.localeCompare(b.lastLoginAt),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      render: (text, record, index) => {
        return `${record.createdAtAsDate}`;
      },
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    // {
    //   title: "Additional info",
    //   dataIndex: "driverLicenseNumber",
    //   key: "id",
    //   render: (text, record, index) => {
    //     return <Tooltip placement="top" title={
    //       <Typography.Paragraph>
    //         Driver license:<br></br>
    //         - {record.driverLicenseNumber} /  {record.driverLicenseExpiresAt}<br></br>
    //         <br></br>
    //         Credit card: <br></br>
    //         - No <br></br>
    //         <br></br>
    //         Other: <br></br>
    //         --
    //       </Typography.Paragraph>
    //     }>
    //       <Typography.Text><IdcardFilled /> {record.driverLicenseNumber}</Typography.Text>
    //     </Tooltip>
    //   },
    //   sorter: (a, b) => a.driverLicenseNumber.toLowerCase().localeCompare(b.driverLicenseNumber.toLowerCase()),
    // },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   width: 150,
    //   render: (text, record, index) => (
    //     <div style={{ display: "flex" }}>
    //       {/* <Button type="link" size="small" onClick={() => gotoPage(`users/${record.id}`)}>
    //         View profile
    //       </Button> */}
    //       <Button type="link" size="small" onClick={() => openUpdateModal(record)}>
    //         Edit
    //       </Button>
    //       <Button type="link" size="small" onClick={() => openDeleteModal(record)}>
    //         Delete
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        {/* <Button type="primary" size="middle" onClick={() => openCreateModal()}>
          Create new user
        </Button>   <Button disabled onClick={openPreAuthorizeModal}>
          Pre-authorize a user
        </Button>
        <Button disabled onClick={openUserAccountModal}>
          Add user to account
        </Button>*/}
      </div>
      <UsersTable></UsersTable>
    </div>
  );
}

export default Users;
