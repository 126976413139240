import React from "react";
import Plan from "../../models/Plan";
import PlanService from "../../services/crud/PlanService";
import PlanForm from "../forms/PlanForm";
import GenericItemsTable from "./GenericItemsTable";
import { TableProps } from "./types";
import { PlanFormCreate } from "../forms/PlanForm";

function PlansTable({ ...rest }: TableProps<Plan>) {
  return <GenericItemsTable model={Plan} service={PlanService} form={PlanForm} {...rest}></GenericItemsTable>;
}
export function GenericPlansTable({ ...rest }: TableProps<Plan>) {
  return <GenericItemsTable model={Plan} service={PlanService} form={PlanForm} formCreate={PlanFormCreate} {...rest}></GenericItemsTable>;
}

export default PlansTable;
