import { Typography, Button, List, Form } from "antd";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AccountForm from "../../../components/forms/AccountForm";
import DescriptionListItems from "../../../components/DescriptionListItems";
import UsersTable, { GenericUsersTable } from "../../../components/tables/UsersTable";
import { OverlayContext } from "../../../context/OverlayContext";
import { ToastContext } from "../../../context/ToastContext";
import Account from "../../../models/Account";
import User from "../../../models/User";
import AccountService from "../../../services/crud/AccountService";
import DescriptionDataList from "../../../components/DescriptionDataList";
import { GenericSubscriptionsTable } from "../../../components/tables/SubscriptionsTable";
import { GenericHubsTable } from "../../../components/tables/HubsTable";
import UserAccountForm, { EmployeeForm } from "../../../components/forms/UserAccountForm";
import AuthorizedService from "../../../services/crud/AuthorizedService";
import { GoBackButton } from "../../../components/GoBackButton";

const includeColumnsUsers = ["username", "firstname", "lastname", "createdAt", "lastLoggedIn"];
const includeColumnsSubscriptions = ["plan", "priceInclVat", "ends"];
const includeColumnsHubs = ["name", "address"];
type Props = {};
/**
 * This page lists all information related to account. Admin should be able to edit and delete account.
 * @param param0
 * @returns
 */
function AccountView({}: Props) {
  const { id } = useParams<any>();
  const accountId = parseInt(id);
  const [account, setAccount] = useState<Account>({} as Account);
  const { loading, loadMessage } = useContext(ToastContext);
  const { openModal, closeModal } = useContext(OverlayContext);
  const [form] = Form.useForm();

  useEffect(() => {
    loadMessage("Loading Account...", () =>
      Promise.all([AccountService.getAccount(id)]).then((data) => {
        setAccount(data[0]);
      })
    );
  }, [id]);
  const openAddModal = () => {
    openModal({
      title: "Add user to account",
      content: <EmployeeForm company={account} form={form} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          console.log(values);
          closeModal();
          // loadMessage("Adding account", () =>
          //   AccountService.addUserToAccount(values)
          //     .then((user: User) => {
          //       setAccount(new Account({ ...account, users: [...account?.users, new User(values)] }));
          //       return "Account added";
          //     })
          //     .catch((res) => {
          //       throw Error("Could not add account");
          //     })
          // );
        });
      },
    });
  };
  const openEditModal = (account = null) => {
    openModal({
      title: "Update account",
      content: <AccountForm id={account.id} form={form} update={true} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          closeModal();
          loadMessage("Updating account", () =>
            AccountService.editAccount(
              values.id,
              _.pickBy(values, (x) => x !== null)
            )
              .then((account) => {
                setAccount(account);
                return "Account updated";
              })
              .catch((res) => {
                throw Error("Could not update account");
              })
          );
        });
      },
    });
  };
  const handleEdit = () => {
    openEditModal(account);
  };

  return (
    <div>
      <GoBackButton></GoBackButton>
      <div>
        <Typography.Title level={3} style={{ marginTop: 40, marginLeft: 10 }}>
          {account?.name}
        </Typography.Title>
        <DescriptionDataList
          footer={
            <Button size="small" type="link" onClick={handleEdit}>
              Edit Account
            </Button>
          }
          data={account}
        ></DescriptionDataList>
      </div>
      <GenericUsersTable
        actions={{ delete: true, select: true }}
        leftButton={
          <Button type="primary" onClick={openAddModal}>
            Add user
          </Button>
        }
        onAdd={async (data) => {
          console.log(data);
          loadMessage("Adding user to account", () =>
            AuthorizedService.addAuthorizedAccount({ email: data?.email, accountId })
              .then(() => {
                return "User added to account";
              })
              .catch((res) => {
                throw Error("Could not add user");
              })
          );
        }}
        onDelete={async (data) => {
          loadMessage("Deleting user", () =>
            AuthorizedService.deleteAuthorizedAccount({ email: data?.username, accountId })
              .then(() => {
                return "Removed user from account";
              })
              .catch((res) => {
                throw Error("Operation failed");
              })
          );
        }}
        params={{ accountId }}
        includeColumns={includeColumnsUsers}
      ></GenericUsersTable>
      <GenericSubscriptionsTable params={{ accountId }} includeColumns={includeColumnsSubscriptions}></GenericSubscriptionsTable>
      <GenericHubsTable params={{ accountId }} includeColumns={includeColumnsHubs}></GenericHubsTable>
    </div>
  );
}

export default AccountView;
