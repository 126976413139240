import Authorized from "./Authorized";
import BaseModel from "./BaseModel";
import Booking from "./Booking";
import Hub from "./Hub";
import Subscription from "./Subscription";
import User from "./User";
import WhiteLabel from "./WhiteLabels";

/**
 * Account model with no relations
 */
class BaseAccount extends BaseModel {
  type: string;
  name: string;
  balance: number;
  whiteLabelId: number;
  hasBikeShare: boolean;
  hasMabiCarShare: boolean;
  static __dates = [];
  static types = { PRIVATE: "private", COMPANY: "company", REAL_ESTATE: "real_estate" };
  constructor(data) {
    super(data, Account.__dates);
    this.type = data.type;
    this.name = data.name;
    this.balance = data.balance;
    this.whiteLabelId = data.whiteLabelId;
    this.hasBikeShare = data.hasBikeShare;
    this.hasMabiCarShare = data.hasMabiCarShare;
  }
}
/**
 * Account model with all relations
 */
class Account extends BaseAccount {
  authorized?: Authorized[];
  users?: User[];
  hubs?: Hub[];
  subscriptions?: Subscription[];
  bookings?: Booking[];
  whiteLabel?: WhiteLabel;
  constructor(data) {
    super(data);
    this.users = data.users?.map((user) => new User(user));
    this.hubs = data.hubs;
    this.subscriptions = data.subscriptions;
    this.bookings = data.bookings;
    this.authorized = data.authorized?.map((authorized) => new Authorized(authorized));
    this.whiteLabel = data.whiteLabel ? new WhiteLabel(data.whiteLabel) : null;
  }

  /** Get an emoji that represents the account type. */
  static typeEmoji(type: String): string {
    switch (type) {
      case 'private': return '🧑';
      case 'company': return '🏷️';
      case 'real_estate': return '🏠️';
    }
    return '';
  }
}

export default Account;
