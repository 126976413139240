import User from "../../models/User";
import HttpClient from "../../clients/HttpClient";

export default class UserService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async list(params = {}): Promise<Array<User>> {
    return this.httpClient.get("/users", params).then((res) => {
      return res.data.map((row) => new User(row));
    });
  }

  public static async getUser(id): Promise<User> {
    return this.httpClient.get(`/users/${id}`).then((res) => {
      return new User(res.data);
    });
  }

  public static async addUser(data): Promise<User> {
    data.driverLicenseExpiresAt = data.driverLicenseExpiresAt.format("YYYY-MM-DD");
    return this.httpClient.post(`/users`, data).then((res) => {
      return new User(res.data);
    });
  }

  public static async editUser(id, data): Promise<User> {
    return this.httpClient.put(`/users/${id}`, data).then((res) => {
      return new User(res.data);
    });
  }

  public static async deleteUser(id): Promise<boolean> {
    return this.httpClient.delete(`/users/${id}`, null).then((res) => {
      return true;
    });
  }

  public static async listUsersOnAccount(accountId): Promise<Array<User>> {
    return this.httpClient.get("/accounts/" + accountId + "/users").then((res) => {
      return res.data.map((row) => new User(row));
    });
  }
}
