import React from "react";
import InfoBox from "../../../components/InfoBox";
import DescriptionDataList from "../../../components/DescriptionDataList";
import Typography from "antd/lib/typography/Typography";
import Booking from "../../../models/Booking";
import BookingForm from "../../../components/forms/BookingForm";
import { useForm } from "antd/lib/form/Form";
import { BookingModalViewData } from "../../../components/forms/BookingForm";
import { BookingExternal } from "../../../services/crud/BookingExternalService";
import { mapAnyBookingDataToBookingModalViewData } from "../../../components/tables/BookingsTable";
type props = {
  booking: Booking|BookingExternal
};


export const DetailedBookingView = ({ booking }: props) => {
const data = mapAnyBookingDataToBookingModalViewData(booking)
  const [form] = useForm();
  return <BookingForm booking={data}></BookingForm>;
};
