import UserFeedback from "../../models/UserFeedback";
import HttpClient from "../../clients/HttpClient";

export default class UserFeedbackService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  /// Get UserFeedback for a booking.
  public static async getUserFeedback(bookingExternalId: number): Promise<UserFeedback> {
    return this.httpClient.get(`/feedback/read/${bookingExternalId}`).then((res) => {
      return new UserFeedback(res.data);
    });
  }
}

