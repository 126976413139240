import Booking from "../../models/Booking";
import HttpClient from "../../clients/HttpClient";
// import ProductService from "./ProductService";
// import TspService from "./TspService";
// import SubscriptionService from "./SubscriptionService";
// import UserService from "./UserService";

// const mockBookings = [
//   {
//     id: 1,
//     userId: 1,
//     tspId: 1,
//     referenceId: 1,
//     provider: "<TSP>",
//     price: 1,
//     pickupLocationLatitude: 1,
//     pickupLocationLongitude: 1,
//     destinationLocationLatitude: 1,
//     destinationLocationLongitude: 1,
//     canCancel: true,
//     comment: "comment",
//     status: "STATUS",
//     extraInfo: "extra info",
//     productIdentification: "product#id#1234",
//     createdAt: "2020-08-05 00:00:00",
//     updatedAt: "2020-08-05 00:00:00",
//     deletedAt: null,
//     product: null,
//     tsp: null,
//     subscription: null,
//     user: null,
//   }
// ]

export default class BookingService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async list(params = {}): Promise<Array<Booking>> {
    return this.httpClient.get("/bookings/read", params).then(async (res) => {
      // return UserService.getUser(1).then(u => {
      //   return ProductService.getProduct(1).then(p => {
      //     return TspService.getTsp(1).then(t => {
      //       return SubscriptionService.getSubscription(1).then(s => {
      //         return mockBookings.map(row => {
      //           row.user = u;
      //           row.product = p;
      //           row.subscription = s;
      //           row.tsp = t;
      //           return new Booking(row);
      //         });
      //       });
      //     });
      //   });
      // });
      return res.data.map((row) => new Booking(row));
    });
  }

  public static async getBooking(id): Promise<Booking> {
    return this.httpClient.get(`/bookings/read_booking/${id}`).then((res) => {
      return new Booking(res.data);
    });
  }

  public static async addBooking(data): Promise<Booking> {
    return this.httpClient.post(`/bookings/create`, data).then((res) => {
      return new Booking(res.data);
    });
  }

  public static async editBooking(id, data): Promise<Booking> {
    return this.httpClient.put(`/bookings/update/`, data).then((res) => {
      return new Booking(res.data);
    });
  }

  public static async deleteBooking(id): Promise<boolean> {
    return this.httpClient.delete(`/bookings/${id}`, null).then((res) => {
      return true;
    });
  }
  public static async returnBooking(bookingId: string | number) {
    const response = await this.httpClient.get("/bookings/finalize/" + bookingId);
    const data = response.data;
    return data;
  }
}
