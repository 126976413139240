import React, { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../context/ToastContext";
import { Form, Input, Tooltip, Spin, Select } from "antd";
import { LoadingOutlined, QuestionCircleFilled } from "@ant-design/icons";
import UserService from "../../services/crud/UserService";
import User from "../../models/User";

const UserForm = (props) => {
  const id = props.id;
  const form = props.form;
  const update: boolean = props.update ?? false;
  const { loading, loadMessage } = useContext(ToastContext);
  const [user, setUser] = useState<User>({} as User);

  useEffect(() => {
    form.resetFields();
    loadMessage("Loading user item...", () =>
      UserService.getUser(id).then((data) => {
        setUser(data.serialize());
        if (data["driverLicenseExpiresAt"] !== null) {
          form.setFieldsValue({ driverLicenseExpiresAt: data["driverLicenseExpiresAtAsDate"] });
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, form]);

  const content = (
    <Form initialValues={user} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>

      <Form.Item name="username" label="Username" rules={[{ required: true }]}>
        <Input type="text" disabled={update} />
      </Form.Item>
      <Tooltip title="The username is used as credentials at login.">
        <QuestionCircleFilled />
      </Tooltip>
      {!update && (
        <React.Fragment key="password">
          <Form.Item name="password" label="Password" rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
          <Tooltip title="Password for the user.">
            <QuestionCircleFilled />
          </Tooltip>
        </React.Fragment>
      )}

      {/* First and last name */}
      <Form.Item label="Name">
        <Input.Group compact>
          <Form.Item name="firstName" noStyle rules={[{ required: true }]}>
            <Input type="text" />
          </Form.Item>
          <Form.Item name="lastName" noStyle rules={[{ required: true }]}>
            <Input type="text" />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Tooltip title="The users real first and last name.">
        <QuestionCircleFilled />
      </Tooltip>

      <Form.Item name="personalId" label="Personal ID">
        <Input disabled type="text" />
      </Form.Item>
      <Tooltip title="The users swedish Personal ID (a.k.a. 'personnummer'). This is used to set up and connect to user associated data within third party transport services where it is required.">
        <QuestionCircleFilled />
      </Tooltip>
      <Form.Item name="phoneNumber" label="Phone number" rules={[{ required: true }]}>
        <Input type="tel" />
      </Form.Item>
      <Tooltip title="The users phone number. This is used to set up and connect to user associated data within third party transport services where it is required.">
        <QuestionCircleFilled />
      </Tooltip>

      {/* Address, Zip code and City */}
      <Form.Item name="addressLine1" label="Address">
        <Input type="text" />
      </Form.Item>
      <Tooltip title="The users street address. It might be used to send promotions, invoices, etc. This information is not shared with third party providers.">
        <QuestionCircleFilled />
      </Tooltip>

      <Form.Item label="Zip code / City">
        <Input.Group compact>
          <Form.Item name="zipCode" noStyle>
            <Input type="text" />
          </Form.Item>
          <Form.Item name="city" noStyle>
            <Input type="text" />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Tooltip title="The users zip code and city address. It might be used to send promotions, invoices, etc. This information is not shared with third party providers.">
        <QuestionCircleFilled />
      </Tooltip>
      {/* <Form.Item name="role" label="Role" rules={[{ required: true }]}>
        <Select
          options={[
            { label: "admin", value: "admin" },
            { label: "user", value: "user" },
          ]}
        />
      </Form.Item> */}
      {/* Driver licnese */}
      <Form.Item label="Driver license / Exp">
        <Input.Group compact>
          <Form.Item name="driverLicenseNumber" noStyle>
            <Input type="text" disabled />
          </Form.Item>
          {/* <Form.Item name="driverLicenseExpiresAt" noStyle rules={[{required: true}]} initialValue={moment(form.getFieldValue("_driverLicenseExpiresAt"))}> */}
          <Form.Item name="driverLicenseExpiresAt" noStyle>
            {/* <DatePicker format="YYYY-MM-DD" disabled /> */}
            <Input type="text" disabled />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Tooltip title="The users driver license information. This is used with third party transport services where it is required.">
        <QuestionCircleFilled />
      </Tooltip>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};
export default UserForm;
