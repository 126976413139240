import { useState, useContext, useEffect } from "react";
import { ToastContext } from "../../../../context/ToastContext";
import Account from "../../../../models/Account";
import AccountService from "../../../../services/crud/AccountService";
import ReportService, { AccountsKpiRequest } from "../../../../services/crud/ReportService";
import AccountsKpiConfigurationView from "./AccountsKpiConfigurationView";
import AccountsKpiReportForm from "./AccountsKpiReportForm";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function nameSort(a: Account, b: Account) {
  return a.name.localeCompare(b.name);
}

const AccountsKpiTab: React.FC = () => {
  const [ accounts, setAccounts ] = useState([]);
  const { loadMessage, loading } = useContext(ToastContext);

  useEffect(() => {
    loadMessage('Loading accounts', async () => {
      const [company_accounts, real_estate_accounts] = await Promise.all([
        AccountService.list({type: Account.types.COMPANY}),
        AccountService.list({type: Account.types.REAL_ESTATE}),
      ])
      const nonPrivateAccounts = [
        ...company_accounts.sort(nameSort),
        ...real_estate_accounts.sort(nameSort),
      ];
      setAccounts(nonPrivateAccounts);
    });
  }, []);

  const content = (
    <>
      <AccountsKpiReportForm nonPrivateAccounts={accounts} />
      <div style={{height: '30px'}}></div>
      <AccountsKpiConfigurationView nonPrivateAccounts={accounts} />
    </>
  );

  if (loading || accounts.length === 0) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};

export default AccountsKpiTab;


