import BaseModel from "./BaseModel";

class AccountsKpiConfiguration extends BaseModel {
  id: number;
  reportName: string;
  accountIds: string; // comma separated list
  emailAddresses: string; // comma separated list

  constructor(data) {
    super(data, []);
    this.id = data.id;
    this.reportName = data.reportName;
    this.accountIds = data.accountIds;
    this.emailAddresses = data.emailAddresses;
  }

  getAccountIdsList(): Array<number> {
    return this.accountIds.split(',').map((a) => Number.parseInt(a.trim()));
  }

  setAccountIdsList(list: Array<number>) {
    this.accountIds = list.join(',');
  }

  getEmailAddressesList(): Array<string> {
    return this.emailAddresses.split(',').map((a) => a.trim());
  }

  setEmailAddressesList(list: Array<string>) {
    this.emailAddresses = list.join(',');
  }
}

export default AccountsKpiConfiguration;

