import React, { useState, useEffect, useContext } from "react";
import { Table, Typography, Button, Input, Form, Tooltip, Tag } from "antd";
import PlanService from "../../../services/crud/PlanService";
import { OverlayContext } from "../../../context/OverlayContext";
import { ToastContext } from "../../../context/ToastContext";
import PlanForm from "../../../components/forms/PlanForm";
import { CheckOutlined, StopOutlined } from "@ant-design/icons";
import InfoBox from "../../../components/InfoBox";
import { GenericPlansTable } from "../../../components/tables/PlansTable";
// import DeleteForm from "../components/DeleteForm";

function Plans() {
  const [plans, setPlans] = useState([]);
  const [filter, setFilter] = useState("");
  const [tspFilter, setTspFilter] = useState([]);
  // const [upsertModalVisible, setUpsertModalVisible] = useState(false);
  // const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const { openModal, closeModal } = useContext(OverlayContext);
  const { loading, loadMessage } = useContext(ToastContext);
  const [form] = Form.useForm();

  useEffect(() => {
    loadMessage("Loading plans...", () =>
      PlanService.list().then((plans) => {
        setPlans(plans);
        const tspFilterArray = [];
        for (let i in plans) {
          tspFilterArray.push({ text: plans[i].tsp.name, value: plans[i].tsp.name });
        }
        setTspFilter(tspFilterArray.filter((value, index, self) => self.map((x) => x.text).indexOf(value.text) === index));
        return true;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const openCreateModal = () => {
  //   openModal({
  //     title: "Add new plan",
  //     content: <PlanForm form={form} />,
  //     onOk: () => {
  //       form.validateFields().then(async (values) => {
  //         closeModal();
  //         values = {...values, priceVatFraction: values.priceVatFraction/100};
  //         loadMessage("Creating plan...", () => PlanService.addPlan(values).then(plan => {
  //           setPlans([plan, ...plans]);
  //           return "Plan added";
  //         }).catch(res => {
  //           console.log(res);
  //           throw Error("Could not create plan");
  //         }));
  //       })
  //     },
  //   });
  // };

  const openUpdateModal = (plan = null) => {
    form.resetFields();
    openModal({
      title: "Update plan",
      content: <PlanForm id={plan.id} form={form} update={true} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          closeModal();
          loadMessage("Updating plan...", () =>
            PlanService.editPlan(plan.id, values)
              .then((plan) => {
                const i = plans.findIndex((item) => item.id === plan.id);
                plans[i] = plan;
                setPlans(plans);
                return "Plan updated";
              })
              .catch((res) => {
                console.log(res);
                throw Error("Could not update plan");
              })
          );
        });
      },
    });
  };

  // const openViewModal = (plan = null) => {
  //   form.resetFields();
  //   openModal({
  //     title: "View plan",
  //     content: <PlanForm id={plan.id} form={form} update={false} />,
  //     okText: "Close",
  //     hasCancel: false
  //   });
  // };

  // const openDeleteModal = (plan = null) => {
  //   form.resetFields();
  //   openModal({
  //     title: "Delete plan",
  //     danger: true,
  //     okText: "Delete",
  //     width: "40%",
  //     content: <DeleteForm
  //       form={form}
  //       warningText={"Deleting this plan can have serious consequences for active travelers, and needs to be planned ahead of time with operations and perhaps coordinated with associated transport provider."}
  //       item="plan"
  //       itemName={plan.name}
  //       assocItemName={plan.tsp.name}
  //       // description="If you want to delete... (replaced item, itemName and assocItemName)"
  //       ></DeleteForm>,
  //     onOk: () => {
  //       closeModal();
  //       form.validateFields().then(async (values) => {
  //         if (values.itemNameForDeletion.replace(/^\s+|\s+$/g, '') === plan.name.replace(/^\s+|\s+$/g, '')) {
  //           loadMessage("Deleting plan...", () => PlanService.deletePlan(plan.id).then(success => {
  //             const i = plans.findIndex(item => item.id === plan.id)
  //             plans.splice(i, 1);
  //             setPlans(plans);
  //             return "Plan deleted";
  //           }).catch(res => {
  //             console.log(res);
  //             throw Error("Could not delete plan");
  //           }));
  //         } else {
  //           showMessage("Name does not match. Plan not deleted.", "warning");
  //         }
  //       });
  //     },
  //   });
  // };

  const filterTable = (data) => data.filter((item) => new RegExp(filter.toLowerCase()).test(item.toString().toLowerCase()));

  const columns: any = [
    {
      title: "TSP",
      dataIndex: ["tsp", "name"],
      key: "tspName",
      sorter: (a, b) => a.tsp.name.toLowerCase().localeCompare(b.tsp.name.toLowerCase()),
      filters: tspFilter,
      onFilter: (value, record) => record.tsp.name.indexOf(value) === 0,
    },
    {
      title: "Visible",
      dataIndex: "active",
      render: (text, record, index) => {
        return record.active ? (
          <Tag icon={<CheckOutlined />} color="green">
            Visible
          </Tag>
        ) : (
          <Tag icon={<StopOutlined />} color="red">
            Hidden
          </Tag>
        );
      },
      sorter: (a, b) => a.active - b.active,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   render: (text, record, index) => {
    //     return <Tooltip title={text}>
    //       <Typography.Text style={{display: "block", width: 120, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>{ text }</Typography.Text>
    //     </Tooltip>
    //   },
    //   sorter: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()),
    // },
    {
      title: "Price",
      dataIndex: "priceInclVat",
      render: (text, record, index) => {
        return (
          <Tooltip title={`${text} SEK every ${record.debitCycle} month${record.debitCycle > 1 ? "s" : ""}`}>
            <Typography.Text>{`${text} SEK / ${record.debitCycle === 1 ? "month" : record.debitCycle + " months"}`}</Typography.Text>
          </Tooltip>
        );
      },
      sorter: (a, b) => a.priceInclVat - b.priceInclVat,
    },
    {
      title: "Top ups cycle",
      dataIndex: "topUpCycle",
      render: (text, record, index) => `${text} month${record.topUpCycle > 1 ? "s" : ""}`,
      sorter: (a, b) => a.topUpCycle - b.topUpCycle,
    },
    // {
    //   title: "Debit cycle",
    //   dataIndex: "debitCycle",
    //   render: (text, record, index) => `${text} month${record.debitCycle > 1 ? "s" : ""}`,
    //   sorter: (a, b) => a.debitCycle - b.debitCycle,
    // },
    {
      title: "Pause limit",
      dataIndex: "pauseMaxCountPerYear",
      sorter: (a, b) => a.pauseMaxCountPerYear - b.pauseMaxCountPerYear,
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (text, record, index) => {
        return `${record.createdAtAsDate}`;
      },
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: "Actions",
      width: 150,
      render: (text, record, index) => (
        <div style={{ display: "flex" }}>
          {/* <Button type="link" size="small" onClick={() => openViewModal(record)}>
            View
          </Button> */}
          <Button type="link" size="small" onClick={() => openUpdateModal(record)}>
            Edit
          </Button>
          {/* <Button type="link" size="small" onClick={() => openDeleteModal(record)}>
            Delete
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <div>
      <InfoBox>
        <p>Används inte ännu.</p>
      </InfoBox>
      {/* <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <Input.Search size="middle" placeholder={`Search plans...`} style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} /> */}
      {/* <Button type="primary" size="middle" onClick={() => openCreateModal()}>
          Create new plan
        </Button> */}
      {/* </div> */}
      {/*       <Table
        rowKey="id"
        columns={columns}
        dataSource={filterTable(plans)}
        pagination={{total: plans.length, defaultPageSize: 30, showSizeChanger: true}}
        loading={loading}
      /> */}
      <GenericPlansTable search></GenericPlansTable>
      <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>Total of {plans.length} plans</Typography.Text>
    </div>
  );
}

export default Plans;
