import HttpClient from "../../clients/HttpClient";
import Account from "../../models/Account";
import { ExternalBookingStatus } from "../../models/Booking";
import { EC2BFormConfig, Ec2bFormResponse } from "../../models/EC2BForm";
import Hub from "../../models/Hub";
import { LangKeyType } from "../../models/LangKey";
import { TimeLineItem } from "../../models/TimeLine";
import User from "../../models/User";
import UserFeedback from "../../models/UserFeedback";
import { TspKeyType } from "../../models/tspKeyEnum";
/**
 *     "user_id",
        "tsp_id",
        "tsp_key",
        "booking_id",
        "price_cents",
        "new_price_inc_vat_cents",
        "device_id",
        "status",
        "returned_at",
        "initial_payment_id",
        "return_payment_id",
        "payment_reference",
        "payment_type",
        "payment_created_at",
        "lease_start_at",
        "payment_currency",
        "booking_label",
        "payment_status",
        "book_from_datetime",
        "book_to_datetime",
        "origin_location",
        "origin_coordinates",
        "destination_location",
        "destination_coordinates",
        "service_type",
        "amount_from_wallet",
        "account_id",
        "payment_process_type",
        "refund_created_at",
        "refund_initiated_at",
        "booking_aborted_at",
        "new_amount_from_wallet",
        "returned_payment_at",
        "refund_initiated_at",
        "price_inc_vat_cents",
        "price_vat_cents",
        "pickup_hub_id",
        "return_hub_id",
        "vat_percent",
        "item_id",
 */
export class BookingExternal {
  userId: number;
  tspId: number;
  tspKey: string;
  bookingId: number;
  id: number;
  paymentReference: string;
  referenceId: string;
  bookFromDateTime: Date;
  bookToDateTime: Date;
  accountId: number;
  bookingLabel: string;
  priceIncVatCents: number;
  newPriceIncVatCents: number;
  amountFromWallet: number;
  newAmountFromWallet: number;
  user: User;
  pickupHub?: Hub;
  returnHub?: Hub;
  status: string;
  createdAt: Date;
  refundCreatedAt: Date|null;
  bookingAbortedAt: Date|null;
  leaseStartAt: Date|null;
  returnedAt: Date|null;
  returnedPaymentAt: Date|null;
  itemId?: number;
  account: Account;
  destination?: string;
  milageInKm?: string;
  /// User feedback loaded via list of bookings will have feedback_text set to null regardless if user has written anything.
  /// Use UserFeedbackService to obtain the full UserFeedback text
  userFeedback: UserFeedback|null;

  constructor(data: any) {
    this.userId = data.userId;
    this.tspKey = data.tspKey;
    this.bookingId = data.bookingId;
    this.id = data.id;
    this.paymentReference = data.paymentReference;
    this.bookFromDateTime = new Date(data.bookFromDatetime);
    this.bookToDateTime = new Date(data.bookToDatetime);
    this.accountId = data.accountId;
    this.bookingLabel = data.bookingLabel;
    this.priceIncVatCents = data.priceIncVatCents;
    this.newPriceIncVatCents = data.newPriceIncVatCents;
    this.amountFromWallet = data.amountFromWallet;
    this.newAmountFromWallet = data.newAmountFromWallet;
    this.user = data.user;
    this.referenceId = data.referenceId;
    this.returnHub = data.returnHub;
    this.pickupHub = data.pickupHub;
    this.status = data.status;
    this.createdAt = new Date(data.createdAt);
    this.refundCreatedAt = data.refundCreatedAt !== null ? new Date(data.refundCreatedAt) : null;
    this.bookingAbortedAt = data.bookingAbortedAt !== null ? new Date(data.bookingAbortedAt) : null;
    this.leaseStartAt = data.leaseStartAt !== null ? new Date(data.leaseStartAt) : null;
    this.returnedAt = data.returnedAt !== null ? new Date(data.returnedAt) : null;
    this.returnedPaymentAt = data.returnedPaymentAt !== null ? new Date(data.returnedPaymentAt) : null;

    this.itemId = data.itemId;
    this.account = data.account;
    this.tspId = data.tspId;
    this.destination = data.destination;
    this.milageInKm = data.milageInKm;

    this.userFeedback = data.userFeedback !== null && data.userFeedback !== undefined ? new UserFeedback(data.userFeedback) : null;
  }

  get hubId(): number {
    return this.pickupHub?.id || this.returnHub?.id || null;
  }

  /// Get the total price paid for the booking in Kr, including VAT
  get totalPriceInKr(): number {
    // Convert from cents to kr
    if(!this.newPriceIncVatCents){
      return this.priceIncVatCents / 100;
    }
    return (this.newPriceIncVatCents) / 100;
  }

  /// Get the amount paid to book the booking
  get initialPriceInKr(): number {
    // Convert from cents to kr
    return this.priceIncVatCents / 100;
  }

  /// Get the amount paid from wallet to book the booking
  get initialPriceFromWalletInKr(): number {
    // Convert from cents to kr
    return this.amountFromWallet / 100;
  }

  /// Get the amount paid to return the booking
  get returnPriceInKr(): number {
    return this.totalPriceInKr - this.initialPriceInKr;
  }

  /// Get the amount paid from wallet to return the booking
  get returnPriceFromWalletInKr(): number {
    // Convert from cents to kr
    return this.newAmountFromWallet / 100;
  }

  get totalDurationInMinutes(): number {
    return (this.bookToDateTime.getTime() - this.bookFromDateTime.getTime()) / 1000 / 60;
  }
  get totalDurationInHours(): number {
    return this.totalDurationInMinutes / 60;
  }
  get totalDurationInDays(): number {
    return this.totalDurationInHours / 24;
  }
}
export type ReturnBookingPayload = {
  tspKey: TspKeyType;
  bookingId: number;
  formResponse?: Ec2bFormResponse;
  langKey: LangKeyType;
};
export type ReturnBookingConfigRequestPayload = {
  tspKey: TspKeyType;
  langKey: LangKeyType;
  bookingId?: number;
};
export type ReturnBookingConfigResponse = { form?: EC2BFormConfig; skipForm?: boolean };

export default class BookingExternalService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async list(): Promise<Array<BookingExternal>> {
    return this.httpClient.get("/bookings_externals/list").then(async (res) => {
      return res.data.map((row) => new BookingExternal(row));
    });
  }

  public static async listByAccountId(id): Promise<Array<BookingExternal>> {
    return this.httpClient.get("/bookings_externals/list_by_account_id/" + id).then(async (res) => {
      console.log(res.data);

      return res.data.map((row) => new BookingExternal(row));
    });
  }

  /**
   *
   * @param bookingId - primary key of external booking
   * @returns
   */
  public static async read(bookingId) {
    return this.httpClient.get("/bookings_externals/read/" + bookingId).then(async (res) => {
      return new BookingExternal(res.data);
    });
  }
  // Returns a booking manually
  public static async returnBooking(payload: ReturnBookingPayload): Promise<ReturnBookingConfigRequestPayload> {
    const response = await this.httpClient.post("/tsps/external/return_booking", payload);
    return response.data;
  }

  public static async returnBookingConfig(bookingId: number, tspKey: TspKeyType): Promise<ReturnBookingConfigResponse> {
    const payload: ReturnBookingConfigRequestPayload = {
      tspKey: tspKey,
      langKey: "en",
      bookingId: bookingId,
    };

    const response = await this.httpClient.post("/tsps/external/get_pre_return_booking_config", payload);
    const data = response.data;
    return data;
  }

  /**
   * Get a map from ExternalBookingStatus to human description string to show to end users.
   */
  public static async getStatusDescriptionMap(): Promise<Map<ExternalBookingStatus, string>> {
    const statuses = Object.values(ExternalBookingStatus).filter((v) => isNaN(Number(v)));
    const r = new Map<ExternalBookingStatus, string>();
    statuses.forEach((status) => {
      r.set(status, BookingExternalService._getStatusDescription(status));
    });
    return r;
  }

  public static getBookingTimeLine(booking: BookingExternal): Array<TimeLineItem> {
    const r: Array<TimeLineItem> = [];
    r.push({
      date: booking.createdAt,
      label: 'Booked',
      description: undefined,
      color: 'blue',
    });
    r.push({
      date: booking.bookFromDateTime,
      label: 'Booked from',
      description: undefined,
      color: 'grey',
    });
    r.push({
      date: booking.bookToDateTime,
      label: 'Booked to',
      description: undefined,
      color: 'grey',
    });
    if (booking.bookingAbortedAt != null) {
      r.push({
        date: booking.bookingAbortedAt,
        label: 'Aborted',
        description: undefined,
        color: 'orange',
      });
    }
    if (booking.refundCreatedAt != null) {
      r.push({
        date: booking.refundCreatedAt,
        label: 'Refunded',
        description: undefined,
        color: 'orange',
      });
    }
    if (booking.leaseStartAt != null) {
      r.push({
        date: booking.leaseStartAt,
        label: 'Lease start',
        description: undefined,
        color: 'green',
      });
    }
    if (booking.returnedAt != null) {
      r.push({
        date: booking.returnedAt,
        label: 'Returned',
        description: undefined,
        color: booking.returnedAt <= booking.bookToDateTime ? 'green' : 'orange',
      });
    }
    if (booking.returnedPaymentAt != null) {
      r.push({
        date: booking.returnedPaymentAt,
        label: 'Returned payment',
        description: undefined,
        color: 'blue',
      });
    }
    r.sort((a, b) => a.date.getTime() - b.date.getTime());
    return r;
  }

  private static _getStatusDescription(s: ExternalBookingStatus): string {
    switch (s) {
      case ExternalBookingStatus.ABORTED: return 'User aborted booking before it started';
      case ExternalBookingStatus.CANCELLED: return 'Unused status';
      case ExternalBookingStatus.EXPIRED: return '???';
      case ExternalBookingStatus.BOOKED: return 'Booked, but not yet activated booking. Use startDate to determine if it is yet to start, or is ongoing, but not yet activated.';
      case ExternalBookingStatus.COMPLETED: return 'A booking that was never activated and than after endDate was passed, the system updated the booking to COMPLETED. Occurs for tickets and for vehicle bookings that never was activated.';
      case ExternalBookingStatus.FAILED: return 'An error status';
      case ExternalBookingStatus.LEASE_EXTENDED: return 'Unused status';
      case ExternalBookingStatus.LEASE_STARTED: return 'A booking that has been activated (typically by accepting the damage report)';
      case ExternalBookingStatus.PENDING: return 'Unused status' ;
      case ExternalBookingStatus.PENDING_EXTRA_PAYMENT: return 'Booking was returned, final price has been obtained, but awaits final payment by user';
      case ExternalBookingStatus.PENDING_RETURN_INVOICE: return 'Unused status';
      case ExternalBookingStatus.REFUNDED: return 'Aborted booking that has been refunded';
      case ExternalBookingStatus.REFUND_INPROGRESS: return 'Refunding of booking is in progress';
      case ExternalBookingStatus.REFUND_INITIATION_FAILED: return 'Failed to start refunding process';
      case ExternalBookingStatus.RETURNED: return 'A booking that was activated, and then returned enters RETERNED status after final price have been obtained and any extra costs have been paid by the user or will be added to next invoice';
    }
  }
}
