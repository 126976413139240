import Plan from "../models/Plan";

export const getDebitCycleString = (debitCycle: number): string => {
  switch (debitCycle) {
    case 1:
      return "month";
    case 12:
      return "year";
    default:
      return "every " + debitCycle + " months";
  }
};
export const underScoreFilter = (entry: [string, any]): boolean => entry[0].charAt(0) !== "_";

export const getPriceForPlan = (plan: Plan) => {
  const price = plan?.priceInclVat;
  const debitCycle = plan?.debitCycle;
  return price + " kr" + " / " + getDebitCycleString(debitCycle);
};

export const formatPriceInCents = (priceInCents: number, perUnit: null|undefined|string): string => {
  const p = priceInCents / 100;
  const s = Intl.NumberFormat('SV-se', { style: 'currency', currency: 'SEK' }).format(p);
  return perUnit !== null && perUnit !== undefined ? `${s}/${perUnit}` : s;
}