import React from "react";
import { Typography } from "antd";

export function ViewTitle({ title = "" }) {
  return (
    <Typography.Title level={3} style={{ marginTop: 40, marginLeft: 10 }}>
      {title}
    </Typography.Title>
  );
}
