import Hub from "../../models/Hub";
import HttpClient from "../../clients/HttpClient";

export default class HubService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async list(params = {}): Promise<Array<Hub>> {
    return this.httpClient.get("/hubs", params).then((res) => {
      return res.data.map((row) => new Hub(row));
    });
  }

  public static async getHub(id): Promise<Hub> {
    return this.httpClient.get(`/hubs/${id}`).then((res) => {
      return new Hub(res.data);
    });
  }

  public static async addHub(data): Promise<Hub> {
    return this.httpClient.post(`/hubs`, data).then((res) => {
      return new Hub(res.data);
    });
  }

  public static async editHub(id, data): Promise<Hub> {
    return this.httpClient.put(`/hubs/${id}`, data).then((res) => {
      return new Hub(res.data);
    });
  }

  public static async deleteHub(id): Promise<boolean> {
    return this.httpClient.delete(`/hubs/${id}`, null).then((res) => {
      return true;
    });
  }
}
