import { IPlanProductsFormData, PlanProductsResponse } from "../../models/PlanProduct";
import HttpClient from "../../clients/HttpClient";
export interface PlanProductDataCreate {
  name: string;
  planId: number;
  productId: number;
  topUpYield: number;
  pricePerPcInclVat: number;
  pricePerPcVatFraction: number;
  pricePerExcessPcInclVat: number;
  pricePerExcessPcVatFraction: number;
}
export class ProductUpdateData {
  "plans_products.name": string;
  topUpYield: number;
  pricePerPcInclVat: number;
  pricePerPcVatFraction: number;
  pricePerExcessPcInclVat: number;
  pricePerExcessPcVatFraction: number;

  constructor(data: {
    name: string;
    topUpYield: number;
    pricePerPcInclVat: number;
    pricePerPcVatFraction: number;
    pricePerExcessPcInclVat: number;
    pricePerExcessPcVatFraction: number;
  }) {
    const { name, topUpYield, pricePerPcInclVat, pricePerPcVatFraction, pricePerExcessPcInclVat, pricePerExcessPcVatFraction } = data;
    this["plans_products.name"] = name;
    this.topUpYield = topUpYield;
    this.pricePerPcInclVat = pricePerPcInclVat;
    this.pricePerPcVatFraction = pricePerPcVatFraction;
    this.pricePerExcessPcInclVat = pricePerExcessPcInclVat;
    this.pricePerExcessPcVatFraction = pricePerExcessPcVatFraction;
  }
}

export interface PlanProductUpdate {
  planId: number;
  productId: number;
  plansProducts: ProductUpdateData[];
}
interface PlanProductId {
  planId: number;
  productId: number;
}

export default class PlanProductService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async list(params = {}): Promise<Array<PlanProductsResponse>> {
    return this.httpClient.get("/plans_products/index").then((res) => {
      return res.data.map((row: PlanProductsResponse) => new PlanProductsResponse(row));
    });
  }

  public static async getPlanProduct(id): Promise<PlanProductsResponse> {
    return this.httpClient.get(`/plans_products/read/` + id).then((res) => {
      return new PlanProductsResponse(res.data);
    });
  }

  public static async addPlanProduct(data: PlanProductDataCreate): Promise<PlanProductsResponse> {
    return this.httpClient.post(`/plans_products/create`, data).then((res) => {
      return new PlanProductsResponse(res.data);
    });
  }

  public static async editPlanProduct(id, data: PlanProductUpdate): Promise<PlanProductsResponse> {
    return this.httpClient.put(`/plans_products/update/` + id, data).then((res) => {
      return new PlanProductsResponse(res.data);
    });
  }

  public static async deletePlanProduct(id): Promise<boolean> {
    return this.httpClient.delete(`/plans_products/delete/` + id, null).then((res) => {
      return true;
    });
  }
}
