
/**
 * Make first character in string upper case.
 * @param string Input string
 */
export function capitalizeFirstLetter(string: string | undefined | null, lowerRest: boolean = false): string|null {
  if (string === null || string === undefined) {
    return null;
  }
  if (string === '') {
    return '';
  }

  var rest = string.slice(1);
  if (lowerRest) {
    rest = rest.toLowerCase();
  }
  return string.charAt(0).toUpperCase() + rest;
}

/**
 * Make first character in string upper case, lower rest of string.
 * @param string Input string
 */
export function capitalizeFirstLetterLowerRest(string: string | undefined | null): string|null {
  return capitalizeFirstLetter(string, true);
}