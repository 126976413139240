import React, { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../context/ToastContext";
import { Form, Input, Radio, Select, Spin, Switch, Typography, Tooltip, InputNumber } from "antd";
import { LoadingOutlined, QuestionCircleFilled } from "@ant-design/icons";
import ProductService from "../../services/crud/ProductService";
import PlanService from "../../services/crud/PlanService";
import Subform from "../Global/Subform";
import Plan, { planTemplate } from "../../models/Plan";
import Product from "../../models/Product";
import TspService from "../../services/crud/TspService";
import { FormInstance } from "antd/es/form";
import PlanProduct from "../../models/PlanProduct";
import Tsp from "../../models/Tsp";

export default function PlanForm(props) {
  const id = props.id;
  const form = props.form;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const update: boolean = props.update ?? false;
  const { loading, loadMessage } = useContext(ToastContext);
  const [plan, setPlan] = useState<Plan>({} as Plan);
  const [products, setProducts] = useState([]);

  // const [, updateState] = React.useState();
  // const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    form.resetFields();
    loadMessage("Loading plan...", () => {
      if (id) {
        return Promise.all([PlanService.getPlan(id), ProductService.list()]).then((data) => {
          setPlan(data[0].serialize());
          setProducts(data[1]);
        });
      } else {
        return ProductService.list().then((data) => setProducts(data));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, id]);

  // const addPlanProduct = () => {
  //   // const values = plan.products
  //   // form.setFieldsValue({ products: [...values, {}] });
  //   forceUpdate();
  // };

  // const removePlanProduct = (i) => {
  //   const values = plan.products;
  //   values.splice(i, 1);
  //   plan.products = values;
  //   forceUpdate();
  // };

  // const changeProduct = (i) => {
  //   const values = plan.products;
  //   const product = products.find((item) => item["id"] === values[i]["id"]);
  //   values[i]["pricePerPcInclVat"] = 0;
  //   values[i]["pricePerExcessPcInclVat"] = product["basePricePerPcInclVat"];
  //   plan.products = values;
  //   forceUpdate();
  // }

  const content = (
    <Form initialValues={{ ...plan }} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item noStyle name="active">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item noStyle name="priceVatFraction">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item noStyle name="priceStartVatFraction">
        <Input type="hidden" />
      </Form.Item>

      <Form.Item label="Visible" rules={[{ required: true }]}>
        <Switch defaultChecked={plan.active} onChange={(c) => form.setFieldsValue({ active: c })} />
      </Form.Item>
      <Tooltip title="Users can not subscribe to a invisible plan. Setting plan as invisible does not affect users already subscribed to it.">
        <QuestionCircleFilled />
      </Tooltip>

      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="Payment freq / Skip top-up">
        <Input.Group compact>
          <Form.Item noStyle name="debitCycle" rules={[{ required: true }]}>
            <InputNumber disabled addonAfter={<Typography.Text> months </Typography.Text>} type="number" />
            {/* <Typography.Text>{plan.debitCycle}</Typography.Text> */}
          </Form.Item>
          <Form.Item noStyle name="pauseMaxCountPerYear" rules={[{ required: true }]}>
            <InputNumber disabled addonAfter={<Typography.Text>times per year</Typography.Text>} type="number" />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Tooltip title="Payment frequency - how often subscribers will be charged.">
        <QuestionCircleFilled />
      </Tooltip>

      <Form.Item label="Binding and termination">
        <Input.Group compact>
          <Form.Item noStyle name="bindingPeriod" rules={[{ required: true }]}>
            <InputNumber disabled addonAfter={<Typography.Text>months</Typography.Text>} type="number" />
          </Form.Item>
          <Form.Item noStyle name="terminationNoticePeriod" rules={[{ required: true }]}>
            <InputNumber disabled addonAfter={<Typography.Text>months</Typography.Text>} type="number" />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Tooltip title="Binding: period before a subscriber can unsubscribe.">
        <QuestionCircleFilled />
      </Tooltip>

      <Form.Item label="Plan cost / Start-fee">
        <Input.Group compact>
          <Form.Item noStyle name="priceInclVat" rules={[{ required: true }]}>
            <InputNumber disabled type="number" addonAfter={<Typography.Text>SEK</Typography.Text>} />
            {/* <Input addonAfter={<Typography.Text>{`${form.getFieldValue("priceVatFraction") * 100}% VAT`}</Typography.Text>} type="number" /> */}
          </Form.Item>
          <Form.Item noStyle name="priceStartInclVat" rules={[{ required: true }]}>
            <InputNumber disabled type="number" addonAfter={<Typography.Text>SEK</Typography.Text>} />
            {/* <Input addonAfter={<Typography.Text>{`${form.getFieldValue("priceVatFraction") * 100}% VAT`}</Typography.Text>} type="number" /> */}
          </Form.Item>
          {/* <Form.Item noStyle name="priceVatFraction" rules={[{ required: true }]}>
            <InputNumber disabled addonAfter={<Typography.Text>% VAT</Typography.Text>} type="number" />
          </Form.Item> */}
        </Input.Group>
      </Form.Item>
      <Tooltip title="Plan cost: fee for plan besides top-up cost.">
        <QuestionCircleFilled />
      </Tooltip>

      <Form.Item name="carryOver" label="Balance carry over" rules={[{ required: true }]}>
        <Radio.Group disabled buttonStyle="outline">
          <Radio.Button value={true}>Carry over</Radio.Button>
          <Radio.Button className="danger" value={false}>
            No carry over
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Tooltip title="Sets if subscribers will carry over balance between payment periods.">
        <QuestionCircleFilled />
      </Tooltip>

      {/* Plan products */}
      {plan.products !== undefined &&
        (plan.products as Array<Product>).map((product, i: number) => {
          return (
            <Subform
              key={i}
              content={
                <>
                  <Form.Item name={["products", i, "id"]} label="Product">
                    <Select disabled onChange={() => /*changeProduct(i)*/ null}>
                      {products.map((p, j) => (
                        <Select.Option key={`plan-product-select-${j}`} value={p.id}>
                          {p.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item label="Top up / Max / Warn">
                    <Input.Group compact>
                      <Form.Item noStyle name={["products", i, "topUpYield"]}>
                        <InputNumber disabled type="number" addonAfter={<Typography.Text>credits</Typography.Text>} />
                      </Form.Item>
                      <Form.Item noStyle name={["products", i, "topUpMaxAccumulatedCount"]}>
                        <InputNumber disabled type="number" addonAfter={<Typography.Text>credits</Typography.Text>} />
                      </Form.Item>
                      <Form.Item noStyle name={["products", i, "topUpWarnAt"]}>
                        <InputNumber disabled type="number" addonAfter={<Typography.Text>credits</Typography.Text>} />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Tooltip title="Max: balance limit. Warn: notify user when balance reaches level (not implemented)">
                    <QuestionCircleFilled />
                  </Tooltip>

                  <Form.Item label="Product- / Additional-price">
                    <Input.Group compact>
                      <Form.Item noStyle name={["products", i, "pricePerPcInclVat"]} rules={[{ required: true, message: "price is required" }]}>
                        <InputNumber
                          disabled
                          type="number"
                          addonAfter={<Typography.Text>{`${(product.pricePerPcVatFraction ?? 0) * 100}% vat`}</Typography.Text>}
                        />
                      </Form.Item>
                      <Form.Item
                        noStyle
                        name={["products", i, "pricePerExcessPcInclVat"]}
                        rules={[{ required: true, message: "price for excessive items are required" }]}
                      >
                        <InputNumber
                          disabled
                          type="number"
                          addonAfter={<Typography.Text>{`${(product.pricePerExcessPcVatFraction ?? 0) * 100}% vat`}</Typography.Text>}
                        />
                      </Form.Item>
                    </Input.Group>
                    <Form.Item noStyle name={["products", i, "pricePerPcVatFraction"]}>
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item noStyle name={["products", i, "pricePerExcessPcVatFraction"]}>
                      <Input type="hidden" />
                    </Form.Item>
                  </Form.Item>
                  <Tooltip title="Product price x top-up amount = subscription payment (besides plan cost). Additional price: price for usage when balance = 0">
                    <QuestionCircleFilled />
                  </Tooltip>
                  {/* <div style={{display: update ? "none" : "flex", width: "100%", flexDirection: "column"}}>
                <Button style={{alignSelf: "flex-end"}} type="primary" danger ghost size="small" onClick={() => removePlanProduct(i)}>Remove product</Button>
              </div> */}
                </>
              }
            />
          );
        })}
      {/*
        <div style={{display: update ? "none" : "flex", width: "98%", flexDirection: "column"}}>
          <Button style={{alignSelf: "flex-end"}} type="primary" ghost onClick={addPlanProduct }>Add product</Button>
        </div>
        */}
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
}

export function PlanFormCreate(props) {
  const form: FormInstance = props.form;
  const { loading, loadMessage } = useContext(ToastContext);
  const [tsps, setTsps] = useState<Tsp[]>([]);
  useEffect(() => {
    form.resetFields();
    loadMessage("Loading plan...", async () => {
      const tsps = await TspService.list();
      setTsps(tsps);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const main = (
    <Form name="plan-create" initialValues={new Plan(planTemplate)} form={form}>
      <Form.Item label="TSP" name="tspId" rules={[{ required: true }]}>
        <Select options={tsps.map((tsp) => Object.assign({}, { value: tsp.id, label: tsp.name }))}></Select>
      </Form.Item>
      <Tooltip title="Transport Service Provider">
        <QuestionCircleFilled></QuestionCircleFilled>
      </Tooltip>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input type="text"></Input>
      </Form.Item>
      <Form.Item label="description" name="description" rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Active" name="active" rules={[{ required: true }]}>
        <Switch defaultChecked={true} onChange={(c) => form.setFieldsValue({ active: c })} />
      </Form.Item>
      <Tooltip title="Users are able subscribe to this plan if active">
        <QuestionCircleFilled></QuestionCircleFilled>
      </Tooltip>
      <Form.Item label="Activation needed" name="activationNeeded" rules={[{ required: true }]}>
        <Switch defaultChecked={true} onChange={(c) => form.setFieldsValue({ activationNeeded: c })} />
      </Form.Item>
      <Form.Item label="Balance carry over" name="carryOver" rules={[{ required: true }]}>
        <Radio.Group disabled buttonStyle="outline" defaultValue={true}>
          <Radio.Button value={true}>Carry over</Radio.Button>
          <Radio.Button className="danger" value={false}>
            No carry over
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Top cycle" name="topUpCycle" rules={[{ required: true }]}>
        <InputNumber disabled type="number" defaultValue={0}></InputNumber>
      </Form.Item>

      <Form.Item label="Binding and termination">
        <Input.Group compact>
          <Form.Item noStyle name="bindingPeriod" rules={[{ required: true }]}>
            <InputNumber disabled addonAfter={<Typography.Text>months</Typography.Text>} type="number" />
          </Form.Item>
          <Form.Item noStyle name="terminationNoticePeriod" rules={[{ required: true }]}>
            <InputNumber disabled addonAfter={<Typography.Text>months</Typography.Text>} type="number" />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Tooltip title="Binding: period before a subscriber can unsubscribe.">
        <QuestionCircleFilled />
      </Tooltip>

      <Form.Item label="Start-fee">
        <Input.Group compact>
          <Form.Item name="priceStartInclVat" rules={[{ required: true }]}>
            <InputNumber defaultValue={0} type="number" min={0} addonAfter={<Typography.Text>,</Typography.Text>} />
          </Form.Item>
          <Form.Item name="priceStartVatFraction" rules={[{ required: true }]}>
            <InputNumber defaultValue={0} type="number" min={0} addonAfter={<Typography.Text>SEK</Typography.Text>} />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item label="Price per debit cycle" shouldUpdate={(prevValues, curValues) => prevValues.payAsYouGo !== curValues.payAsYouGo}>
        {() => {
          return (
            <Input.Group compact>
              <Form.Item name="priceInclVat" rules={[{ required: true }]}>
                <InputNumber
                  disabled={form.getFieldValue("payAsYouGo")}
                  defaultValue={0}
                  type="number"
                  min={0}
                  addonAfter={<Typography.Text>,</Typography.Text>}
                />
              </Form.Item>
              <Form.Item name="priceVatFraction" rules={[{ required: true }]}>
                <InputNumber
                  disabled={form.getFieldValue("payAsYouGo")}
                  defaultValue={0}
                  type="number"
                  min={0}
                  addonAfter={<Typography.Text>SEK</Typography.Text>}
                />
              </Form.Item>
            </Input.Group>
          );
        }}
      </Form.Item>
      <Form.Item label="Pay as you go" name="payAsYouGo" rules={[{ required: true }]}>
        <Switch defaultChecked={true} onChange={(c) => form.setFieldsValue({ payAsYouGo: c, debitCycle: 1, priceInclVat: 0, priceVatFraction: 0 })} />
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, curValues) => prevValues.payAsYouGo !== curValues.payAsYouGo}
        label="Debit Cycle"
        rules={[{ required: true }]}
      >
        {() => {
          return (
            <Form.Item name="debitCycle">
              <Select
                disabled={form.getFieldValue("payAsYouGo")}
                options={[
                  { label: "Every month", value: 1 },
                  { label: "Every year", value: 12 },
                ]}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Tooltip title="Disabled if pay as you go is selected">
        <QuestionCircleFilled></QuestionCircleFilled>
      </Tooltip>
      <Form.Item label="Termination Notice Period" name="terminationNoticePeriod" rules={[{ required: true }]}>
        <InputNumber disabled type="number"></InputNumber>
      </Form.Item>
      <Form.Item label="Binding period" name="bindingPeriod" rules={[{ required: true }]}>
        <InputNumber disabled type="number"></InputNumber>
      </Form.Item>
      <Form.Item label="Top Yield" name="topUpYeild" rules={[{ required: true }]}>
        <InputNumber disabled type="number"></InputNumber>
      </Form.Item>
      <Form.List name="products">
        {(fields) => {
          console.log(fields.map((field) => field.key));
          return fields.map((field) =>
            Object.keys(new PlanProduct({} as PlanProduct)).map((key) => {
              // Does not add any new product to plan
              return (
                <Form.Item hidden key={field.key + key} label={key} name={[field.name, key]}>
                  <Input></Input>
                </Form.Item>
              );
            })
          );
        }}
      </Form.List>
    </Form>
  );
  // Subform: PlanProduct optional

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {main}
      </Spin>
    );
  }
  return main;
}
