import { Typography } from "antd";
import React from "react";

type Props = {};
/**
 * Used for displaying support information for example: email, phone number, etc.
 * @param param0
 * @returns
 */
function Support({}: Props) {
  return (
    <div>
      EC2B support: <Typography.Text>support@ec2b.se</Typography.Text>
    </div>
  );
}

export default Support;
