import BaseModel from "./BaseModel";

export interface IPlanProductsFormData {
  id: number;
  topUpYield: number;
  pricePerPcInclVat: number;
  pricePerPcVatFraction: number;
  pricePerExcessPcInclVat: number;
  pricePerExcessPcVatFraction: number;
}
/** Response object from backend */
export interface IPlanProductsResponse {
  id: number;
  topUpYield: number;
  pricePerPcInclVat: number;
  pricePerPcVatFraction: number;
  pricePerExcessPcInclVat: number;
  pricePerExcessPcVatFraction: number;
  productId: number;
  topUpMaxAccumulatedCount: number;
  topUpWarnAt: number;
  updatedAt: string;
  name: string;
  planId: number;
  createdAt: string;
  creditsPerPc: number;
  deletedAt: null;
  dynamicPrice: number;
}
export class PlanProductsResponse extends BaseModel implements IPlanProductsResponse {
  id: number;
  topUpYield: number;
  pricePerPcInclVat: number;
  pricePerPcVatFraction: number;
  pricePerExcessPcInclVat: number;
  pricePerExcessPcVatFraction: number;
  productId: number;
  topUpMaxAccumulatedCount: number;
  topUpWarnAt: number;
  updatedAt: string;
  name: string;
  planId: number;
  createdAt: string;
  creditsPerPc: number;
  deletedAt: null;
  dynamicPrice: number;

  constructor({
    id,
    topUpYield,
    pricePerPcInclVat,
    pricePerPcVatFraction,
    pricePerExcessPcInclVat,
    pricePerExcessPcVatFraction,
    productId,
    topUpMaxAccumulatedCount,
    topUpWarnAt,
    updatedAt,
    name,
    planId,
    createdAt,
    creditsPerPc,
    deletedAt,
    dynamicPrice,
  }: IPlanProductsResponse) {
    const data = {
      id,
      topUpYield,
      pricePerPcInclVat,
      pricePerPcVatFraction,
      pricePerExcessPcInclVat,
      pricePerExcessPcVatFraction,
      productId,
      topUpMaxAccumulatedCount,
      topUpWarnAt,
      updatedAt,
      name,
      planId,
      createdAt,
      creditsPerPc,
      deletedAt,
      dynamicPrice,
    };
    super(data, []);

    this.id = id;
    this.topUpYield = topUpYield;
    this.pricePerPcInclVat = pricePerPcInclVat;
    this.pricePerPcVatFraction = pricePerPcVatFraction;
    this.pricePerExcessPcInclVat = pricePerExcessPcInclVat;
    this.pricePerExcessPcVatFraction = pricePerExcessPcVatFraction;
    this.productId = productId;
    this.topUpMaxAccumulatedCount = topUpMaxAccumulatedCount;
    this.topUpWarnAt = topUpWarnAt;
    this.updatedAt = updatedAt;
    this.name = name;
    this.planId = planId;
    this.createdAt = createdAt;
    this.creditsPerPc = creditsPerPc;
    this.deletedAt = deletedAt;
    this.dynamicPrice = dynamicPrice;
  }
}

export default class PlanProduct implements IPlanProductsFormData {
  id: number;
  topUpYield: number;
  pricePerPcInclVat: number;
  pricePerPcVatFraction: number;
  pricePerExcessPcInclVat: number;
  pricePerExcessPcVatFraction: number;
  constructor(data: IPlanProductsFormData) {
    this.id = data.id;
    this.pricePerExcessPcInclVat = data.pricePerExcessPcInclVat;
    this.pricePerExcessPcVatFraction = data.pricePerExcessPcVatFraction;
    this.pricePerPcInclVat = data.pricePerPcInclVat;
    this.pricePerPcVatFraction = data.pricePerPcVatFraction;
  }
}
