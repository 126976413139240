import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Space, Table, Tooltip, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationContext } from "../../../context/NavigationContext";
import { ToastContext } from "../../../context/ToastContext";
import { useForm } from "antd/lib/form/Form";
import { OverlayContext } from "../../../context/OverlayContext";
import SubscriptionService from "../../../services/crud/SubscriptionService";
import { ColumnType } from "antd/lib/table/interface";
import { getPriceForPlan } from "../../../utils/common";
import Plan from "../../../models/Plan";
import PlanService from "../../../services/crud/PlanService";
import { useRecoilState, useSetRecoilState } from "recoil";
import { plansState, subscriptionsState } from "../../../recoil/atoms";
import { Props } from "./CompanyAccountsView";

export const PlansTable = ({ dataSource = undefined, id = undefined, onDeleteSelected = (key) => {} }) => {
  const setSubscriptions = useSetRecoilState(subscriptionsState);
  const [plans, setPlans] = useRecoilState(plansState);
  const [form] = useForm();
  const { openModal, closeModal } = useContext(OverlayContext);
  const { user } = useContext(NavigationContext);
  const [filter, setFilter] = useState("");
  const { loading, loadMessage, showMessage } = useContext(ToastContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate("/companies/" + route);
  };

  const handleOpenModal = (plan: Plan) => {
    openModal({
      title: "Confirm subscription",
      width: "40%",
      okText: "Confirm",
      content: (
        <div>
          <Typography.Paragraph>
            <b>{plan.name}</b>
          </Typography.Paragraph>
          <Typography.Paragraph>{getPriceForPlan(plan)}</Typography.Paragraph>
        </div>
      ),
      onOk: () => {
        closeModal();
        form.validateFields().then(async (values) => {
          loadMessage("Subscribe", () =>
            SubscriptionService.addSubscription({ accountId: id, planId: plan.id })
              .then((data) => {
                setPlans((curr) => curr.filter((p) => p.id !== plan.id));
                setSubscriptions((curr) => [data, ...curr]);
              })
              .then(() => "Subscribed to plan")
          );
        });
      },
    });
  };

  function loadTable(id) {
    if (id) {
      // Load all accounts for user
      loadMessage("Loading users...", () =>
        PlanService.list({ accountId: id }).then((plans) => {
          setPlans(plans);
        })
      );
    }
  }
  useEffect(() => {
    if (dataSource) setPlans(dataSource);
  }, [dataSource]);
  useEffect(() => {
    if (id) loadTable(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);

  const items: MenuProps["items"] = [
    // {
    //   label: "Terminate selected",
    //   key: "UNSUBSCRIBE_SELECTED",
    // },
  ];
  const onClick = async ({ key }) => {
    switch (
      key
      // case "UNSUBSCRIBE_SELECTED":
      // TODO: Add method for unsubscribing
      // await PlanService.deletePlan(key.id);
      // loadTable(id);
      // break;
    ) {
    }
  };

  const ActionsDropdown: React.FC<Props> = ({}) => (
    <Dropdown disabled menu={{ items, onClick }} trigger={["click"]}>
      <a>
        <Space>
          Actions
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );

  const columnns: ColumnType<Plan>[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: ["name"],
      render: (text, record, index) => {
        return <Typography.Text>{text ?? "Missing name"}</Typography.Text>;
      },
      // sorter: (a, b) => a?.type?.toLowerCase().localeCompare(b?.type?.toLowerCase()),
    },

    {
      title: "Price",
      key: "price",
      dataIndex: ["priceInclVat"],
      render: (text, record, index) => {
        return <Typography.Text>{getPriceForPlan(record) ?? "Missing price"}</Typography.Text>;
      },
      // sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: "Created at",
      key: "createdAt",
      dataIndex: ["createdAtAsDate"],
      render: (text, record, index) => {
        return <Typography.Text>{text}</Typography.Text>;
      },
      // sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: "Actions",
      key: "actions",
      width: 150,
      fixed: "right",
      render: (text, record, index) => (
        <div style={{ display: "flex" }}>
          <Tooltip title="Startar en prenumeration">
            <Button
              type="link"
              onClick={() => {
                handleOpenModal(record);
              }}
            >
              Subscribe
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  // Row selection
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Typography.Title level={3}>Plans</Typography.Title>
      <Space style={{ marginBottom: 10, justifyContent: "space-between", width: "100%" }}>
        <Space>
          <ActionsDropdown></ActionsDropdown>
          <span style={{ marginLeft: 8 }}> {`(Selected ${selectedRowKeys.length} items)`} </span>
        </Space>
        {/* <PlanFromApplication onSuccess={() => loadTable(id)} accountId={id} /> */}
      </Space>

      <Table
        loading={loading}
        bordered
        rowKey="id"
        dataSource={plans}
        rowSelection={rowSelection}
        columns={columnns}
        pagination={{ total: plans.length, defaultPageSize: 30, showSizeChanger: true }}
      />
    </div>
  );
};
