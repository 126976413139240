import { Menu } from "antd";
import {
  CalendarOutlined,
  CarOutlined,
  CreditCardOutlined,
  FormatPainterOutlined,
  LineChartOutlined,
  MailOutlined,
  MessageOutlined,
  RocketOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import { Modes } from "../Modes";

const Menus = { Developer, SystemAdmin };
export default Menus;

export function SideBarMenu({ variant, handleClick, ...props }): { mode: Modes } & any {
  function SideBarMenuItems({ variant, handleClick }: { variant: any; handleClick: any } & any) {
    switch (variant) {
      case Modes.DEVELOPER:
        return <Developer handleClick={handleClick}></Developer>;
      case Modes.SYSTEM_ADMIN:
        return <SystemAdmin handleClick={handleClick}></SystemAdmin>;
      case Modes.COMPANY_ADMIN:
        return <CompanyAdmin handleClick={handleClick}></CompanyAdmin>;
      case Modes.REAL_ESTATE_ADMIN:
        return <RealEstateAdmin handleClick={handleClick}></RealEstateAdmin>;
      default:
        return <></>;
    }
  }

  return (
    <Menu {...props}>
      <SideBarMenuItems variant={variant} handleClick={handleClick}></SideBarMenuItems>
    </Menu>
  );
}
function Developer({ handleClick }) {
  return (
    <>
      <Menu.ItemGroup title="Developer">
        <Menu.Item key="components" icon={<CalendarOutlined />} onClick={() => handleClick("components")}>
          Components
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.ItemGroup title="Usage">
        <Menu.Item key="users" icon={<UserOutlined />} onClick={() => handleClick("users")}>
          Users
        </Menu.Item>
        <Menu.Item key="subscriptions" icon={<MailOutlined />} onClick={() => handleClick("subscriptions")}>
          Subscriptions
        </Menu.Item>
        <Menu.Item key="bookings" icon={<CreditCardOutlined />} onClick={() => handleClick("bookings")}>
          Bookings
        </Menu.Item>
        <Menu.Item key="userFeedbacks" icon={<MessageOutlined />} onClick={() => handleClick("userFeedbacks")}>
          User feedback
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="Accounts">
        <Menu.Item key="private" icon={<UserOutlined />} onClick={() => handleClick("privates")}>
          Private
        </Menu.Item>
        <Menu.Item key="company" icon={<UserOutlined />} onClick={() => handleClick("companies")}>
          Company
        </Menu.Item>
        <Menu.Item key="realEstate" icon={<UserOutlined />} onClick={() => handleClick("realEstates")}>
          Real Estate
        </Menu.Item>
      </Menu.ItemGroup>
      {/*
        // @ts-ignore */}
      <Menu.ItemGroup title="Supply">
        <Menu.Item key="tsps" icon={<CarOutlined />} onClick={() => handleClick("tsps")}>
          TSPs
        </Menu.Item>
        <Menu.Item key="hubs" icon={<ShopOutlined />} onClick={() => handleClick("hubs")}>
          Hubs
        </Menu.Item>
        <Menu.Item key="resources" icon={<RocketOutlined />} onClick={() => handleClick("resources")}>
          Resources
        </Menu.Item>
        <Menu.Item key="products" icon={<ShoppingCartOutlined />} onClick={() => handleClick("products")}>
          Products
        </Menu.Item>
        <Menu.Item key="plans" icon={<CalendarOutlined />} onClick={() => handleClick("plans")}>
          Plans
        </Menu.Item>
      </Menu.ItemGroup>
    </>
  );
}

function SystemAdmin({ handleClick }) {
  return (
    <>
      <Menu.ItemGroup title="Usage">
        <Menu.Item key="users" icon={<UserOutlined />} onClick={() => handleClick("users")}>
          Users
        </Menu.Item>
        <Menu.Item key="subscriptions" icon={<MailOutlined />} onClick={() => handleClick("subscriptions")}>
          Subscriptions
        </Menu.Item>
        <Menu.Item key="bookings" icon={<CreditCardOutlined />} onClick={() => handleClick("bookings")}>
          Bookings
        </Menu.Item>
        <Menu.Item key="userFeedbacks" icon={<MessageOutlined />} onClick={() => handleClick("userFeedbacks")}>
          User Feedback
        </Menu.Item>
        <Menu.Item key="reports" icon={<LineChartOutlined />} onClick={() => handleClick("reports")}>
          Reports
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="Accounts">
        <Menu.Item key="company" icon={<UserOutlined />} onClick={() => handleClick("companies")}>
          Company
        </Menu.Item>
        <Menu.Item key="realEstate" icon={<UserOutlined />} onClick={() => handleClick("realEstates")}>
          Real Estate
        </Menu.Item>
      </Menu.ItemGroup>
      {/*
        // @ts-ignore */}
      <Menu.ItemGroup title="Supply">
        <Menu.Item key="tsps" icon={<CarOutlined />} onClick={() => handleClick("tsps")}>
          TSPs
        </Menu.Item>
        <Menu.Item key="hubs" icon={<ShopOutlined />} onClick={() => handleClick("hubs")}>
          Hubs
        </Menu.Item>
        <Menu.Item key="resources" icon={<RocketOutlined />} onClick={() => handleClick("resources")}>
          Resources
        </Menu.Item>
        <Menu.Item key="products" icon={<ShoppingCartOutlined />} onClick={() => handleClick("products")}>
          Products
        </Menu.Item>
        <Menu.Item key="plans" icon={<CalendarOutlined />} onClick={() => handleClick("plans")}>
          Plans
        </Menu.Item>
        <Menu.Item key="whiteLabels" icon={<FormatPainterOutlined />} onClick={() => handleClick("whiteLabels")}>
          White label
        </Menu.Item>
      </Menu.ItemGroup>
    </>
  );
}

function CompanyAdmin({ handleClick }) {
  // const menuSpecs = [
  //   {
  //     label: "Bookings",
  //     path: "bookings",
  //   },
  //   {
  //     label: "Real-Estate Accounts",
  //     path: "realEstateAccounts",
  //   },
  //   {
  //     label: "Mobility Services",
  //     path: "mobility-services",
  //   },
  //   {
  //     label: "Statistics",
  //     path: "statistics",
  //   },
  // ];

  return (
    <>
      {/* {menuSpecs.map(({ label, path }) => (
          <Menu.Item key={label} icon={<CarOutlined />} onClick={() => handleClick(path)}>
            {label}
          </Menu.Item>
        ))} */}
      <Menu.ItemGroup title="Accounts">
        <Menu.Item key="company" icon={<UserOutlined />} onClick={() => handleClick("companies")}>
          Company
        </Menu.Item>
        <Menu.Item key="realEstate" icon={<UserOutlined />} onClick={() => handleClick("realEstates")}>
          Real Estate
        </Menu.Item>
      </Menu.ItemGroup>
    </>
  );
}

function RealEstateAdmin({ handleClick }) {
  return (
    <>
      <Menu.ItemGroup title="Accounts">
        <Menu.Item key="company" icon={<UserOutlined />} onClick={() => handleClick("companies")}>
          Company
        </Menu.Item>
        <Menu.Item key="realEstate" icon={<UserOutlined />} onClick={() => handleClick("realEstates")}>
          Real Estate
        </Menu.Item>
      </Menu.ItemGroup>
    </>
  );
}
