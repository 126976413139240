/**
 * Enables component in developer mode
 */

import { QuestionCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { ReactNode } from "react";

type Props = {
  children?: ReactNode | String;
};

function DevContainer({ children }: Props) {
  if (process.env.NODE_ENV === "development")
    return (
      <div style={{ border: "2px solid orange" }}>
        <Tooltip title="Components in this yellow box are only availble in dev mode and might not work as aspected">
          <QuestionCircleFilled></QuestionCircleFilled>
        </Tooltip>
        {children}
      </div>
    );
  return <></>;
}

export default DevContainer;
