import { Button, Form, Input, Select } from "antd";
import React, { useContext } from "react";
import ReportService, { AccountsKpiReport, AccountsKpiRequest } from "../../../../services/crud/ReportService";
import { ToastContext } from "../../../../context/ToastContext";
import InfoBox from "../../../../components/InfoBox";
import Account from "../../../../models/Account";
import { downloadFile } from "../../../../helpers/download-file";

interface AccountsKpiFormData {
  reportName: string;
  accountIds: number[];
}

function accountToOption(account: Account) {
  return {
    'label': `${Account.typeEmoji(account.type)} ${account.name.trim()}`.trim(),
    'value': account.id,
  }
}

interface AccountsKpiReportFormProps {
  nonPrivateAccounts: Array<Account>;
}

const AccountsKpiReportForm: React.FC<AccountsKpiReportFormProps> = ({nonPrivateAccounts}) => {
  const { loadMessage, loading } = useContext(ToastContext);
  const onFinish = async (values: AccountsKpiFormData) => {
    const payload: AccountsKpiRequest = {
      reportName: values.reportName,
      accountIds: values.accountIds,
    };
    loadMessage("Downloading report", async () => {
      const data = await ReportService.getAccountsKpiReport(payload);
      downloadFile(data.file, data.filename);
    });
  };

  const accountOptions = nonPrivateAccounts.map(accountToOption);

  return (
    <>
      <h2>Account booking KPIs</h2>
      <h3>Manual report download</h3>
      <InfoBox>
        <p>Rapport över bokningar gjorda hos en eller flera fastigheter och företag.</p>
      </InfoBox>
      <Form<AccountsKpiFormData>
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ accountIds: [], reportName: (new Date()).toDateString() }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="accountIds"
          label="Konton (företag och/eller fastigheter)"
          rules={[
            {
              required: true,
              message: "Please input ${label}!",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{width: '100%'}}
            options={accountOptions}
          />
        </Form.Item>
        <Form.Item
          name="reportName"
          label="Rapportnamn"
          rules={[
            {
              required: true,
              message: "Please input ${label}!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button loading={loading && accountOptions.length > 0} type="primary" htmlType="submit" disabled={accountOptions.length === 0}>
            Download KPI report
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AccountsKpiReportForm;

