export default class WhiteLabel {
  id: number;
  name: string;
  description?: string;
  headerBgColor?: string;
  headerFgColor?: string;
  tabsBgColor?: string;
  tabsIconColor?: string;
  tabsTextColor?: string;
  tabsSelectedIconColor?: string;
  tabsSelectedTextColor?: string;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.headerBgColor = data.headerBgColor;
    this.headerFgColor = data.headerFgColor;
    this.tabsBgColor = data.tabsBgColor;
    this.tabsIconColor = data.tabsIconColor;
    this.tabsTextColor = data.tabsTextColor;
    this.tabsSelectedIconColor = data.tabsSelectedIconColor;
    this.tabsSelectedTextColor = data.tabsSelectedTextColor;
  }
}