import { Table, Typography, Input, Button, Form, Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../context/ToastContext";
import { listToMap } from "../../helpers/array-to-map";
import Hub from "../../models/Hub";
import HubService from "../../services/crud/HubService";
import { OverlayContext } from "../../context/OverlayContext";
import HubForm from "../forms/HubForm";
import ResourceService from "../../services/crud/ResourceService";
import { TableProps } from "./types";
import GenericItemsTable from "./GenericItemsTable";

function HubsTable({ dataFilter = (data: Hub) => true, columnFilter = (col) => true }: TableProps<Hub>) {
  const [hubs, setHubs] = useState([]);
  const [resources, setResources] = useState([]);
  const [filter, setFilter] = useState("");
  const { loading, loadMessage } = useContext(ToastContext);
  const { openModal, closeModal } = useContext(OverlayContext);
  const [selectedRows, setSelectedRows] = useState<Hub[]>([]);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      await deleteHubs(selectedRows);
    } catch (error) {
      console.log(error);
    }
    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  let navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate("/hubs/" + route);
  };
  const loadTable = async () => {
    Promise.all([HubService.list(), ResourceService.list()]).then((data) => {
      // Filter data
      const hubs = data[0].filter(dataFilter);
      hubs.sort((a, b) => (a.name??'').toLowerCase().localeCompare(b.name??''));
      setHubs(hubs);
      setResources(data[1]);
      return true;
    });
  };
  useEffect(() => {
    loadMessage("Loading hubs...", () => loadTable());
  }, []);

  const normalizedAddress = (record) => [record.address, record.zipCode, record.city, record.country].join(",").toLowerCase();
  const columns: any = [
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => `${record.id}`,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Name",
      key: "Name",
      render: (text, record, index) => `${record.name}`,
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    },
    {
      title: "Address",
      dataIndex: ["address", "zipCode", "city", "country"],
      render: (text, record, index) =>
        [record.address, `${record.zipCode} ${record.city}`.trim(), record.country].filter((s) => s.trim() !== "").join("\n"),
      sorter: (a, b) => normalizedAddress(a).localeCompare(normalizedAddress(b)),
    },
    {
      title: "Access",
      key: "access",
      render: (text, record, index) => `${record.access}`,
      sorter: (a, b) => a.access.toLowerCase().localeCompare(b.access.toLowerCase()),
    },
    {
      title: "Number of resources",
      key: "numberOfResources",
      render: (text, record, index) => `${getResourceCount(record)}`,
      sorter: (a, b) => getResourceCount(a) - getResourceCount(b),
    },
    /*
    {
      title: "Number of blocked resources",
      key: "numberOfBlockedResources",
      render: (text, record, index) => `${getBlockedResourceCount(record)}`,
      sorter: (a, b) => getBlockedResourceCount(a) - getBlockedResourceCount(b),
    },
    */
    {
      title: "Timezone",
      key: "timezone",
      render: (text, record, index) => `${record.timezone}`,
      sorter: (a, b) => a.timezone.toLowerCase().localeCompare(b.timezone.toLowerCase()),
    },
    {
      title: "Updated at",
      key: "name",
      render: (text, record, index) => `${record.updatedAt}`,
      sorter: (a, b) => null, //a.lastLoginAt.localeCompare(b.lastLoginAt),
    },
  ];
  // Filters
  const filterColumns = (columns = []) => columns.filter(columnFilter);
  const filterTable = (data) => data.filter((item) => new RegExp(filter.toLowerCase()).test(item.toString().toLowerCase()));
  const handleOpenCreateModal = () => {
    openAddModal();
  };
  const openAddModal = (hub = null) => {
    form.resetFields();
    openModal({
      title: "Add hub",
      content: <HubForm form={form} update={true} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          const newHub = new Hub(values);
          closeModal();
          loadMessage("Adding hub...", () =>
            HubService.addHub(newHub)
              .then((hub) => {
                setHubs((prev) => [...prev, hub]);
                return "Hub add";
              })
              .catch((res) => {
                console.log(res);
                throw Error("Could not add hub");
              })
          );
        });
      },
    });
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: Hub[]) => {
    console.log("selectedRowKeys changed: ", selectedRows);
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRows,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRows.length > 0;
  const deleteHubs = async (hubs) => {
    loadMessage("Deleting hubs...", () =>
      Promise.all([hubs.map((hub) => HubService.deleteHub(hub.id))]).then((data) => {
        // Refresh table
        loadTable();
        return true;
      })
    );
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <div>
          <Popconfirm
            title={"You are about to delete " + selectedRows.length + " hubs!"}
            open={open}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          >
            <Button type="primary" onClick={showPopconfirm}>
              Delete Hubs
            </Button>
          </Popconfirm>
          <Input.Search size="middle" placeholder="Search hubs..." style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} />
          <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedRows.length} items` : ""}</span>
        </div>

        <Button type="primary" size="middle" onClick={handleOpenCreateModal}>
          Add hub
        </Button>
      </div>
      <Table
        rowKey="id"
        rowSelection={rowSelection}
        columns={filterColumns(columns)}
        dataSource={filterTable(hubs)}
        pagination={{ total: hubs.length, defaultPageSize: 30, showSizeChanger: true }}
        onRow={(record) => ({ onClick: () => gotoPage(`${record.id}`) })}
        loading={loading}
      />
      <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>Total of {hubs.length} hubs</Typography.Text>
    </div>
  );

  function getResourceCount(record: any) {
    return resources.filter((r) => r.hubId == record.id)?.length ?? 0;
  }
  function getBlockedResourceCount(record: any) {
    return resources.filter((r) => r.hubId == record.id).filter((r) => r?.blocked)?.length ?? 0;
  }
}

export const GenericHubsTable = ({ ...rest }: TableProps<Hub>) => {
  return <GenericItemsTable model={Hub} form={HubForm} service={HubService} {...rest}></GenericItemsTable>;
};

export default HubsTable;
