import Authorized from "../../models/Authorized";
import HttpClient from "../../clients/HttpClient";
import { NOT_IMPLEMENTED_EXCEPTION } from "../Exceptions";

export default class AuthorizedService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async list(params = {}): Promise<Array<Authorized>> {
    return this.httpClient.get("/authorized", params).then(async (res) => {
      return res.data.map((row) => new Authorized(row));
    });
  }

  public static async getAuthorized(id): Promise<Authorized> {
    return this.httpClient.get(`/authorized/${id}`).then((res) => {
      return new Authorized(res.data);
    });
  }

  public static async addAuthorized(data): Promise<Authorized> {
    console.log(data);
    return this.httpClient.post(`/authorized`, data).then((res) => {
      return new Authorized(res.data);
    });
  }

  public static async editAuthorized(data: Authorized): Promise<Authorized> {
    return this.httpClient.post(`/authorized`, data).then((res) => {
      return new Authorized(res.data);
    });
  }

  public static async deleteAuthorized(id): Promise<Authorized> {
    return this.httpClient.delete(`/authorized/${id}`).then((res) => {
      return new Authorized(res.data);
    });
  }

  // PIVOT TABLE METHODS

  public static async addAuthorizedAccount(data): Promise<Authorized> {
    console.log(data);
    return this.httpClient.post(`/authorized/account`, data).then((res) => {
      return new Authorized(res.data);
    });
  }

  public static async editAuthorizedAccount(data: Authorized): Promise<Authorized> {
    return this.httpClient.post(`/authorized/account`, data).then((res) => {
      return new Authorized(res.data);
    });
  }

  public static async deleteAuthorizedAccount(data): Promise<Authorized> {
    return this.httpClient.delete(`/authorized/account`, data).then((res) => {
      return new Authorized(res.data);
    });
  }

  // public static async addAccountsAuthorized(data: Authorized): Promise<unknown> {
  //   return Promise.all(
  //     data.accounts.map((account) => this.addAccountAuthorized(new AuthorizedAccountData(data.email, account.pivot.accountId, account.pivot.admin)))
  //   );
  // }
  // public static async updateAccountsAuthorized(data: Authorized): Promise<unknown> {
  //   return Promise.all(
  //     data.accounts.map((account) =>
  //       this.updateAccountAuthorized(new AuthorizedAccountData(data.email, account.pivot.accountId, account.pivot.admin))
  //     )
  //   );
  // }
  // public static async addAccountAuthorized(data: AuthorizedAccountData): Promise<unknown> {
  //   return this.httpClient.post(`/authorized`, data).then((res) => {
  //     return new Authorized(res.data);
  //   });
  // }

  // public static async updateAccountAuthorized(data: AuthorizedAccountData): Promise<unknown> {
  //   return this.httpClient.put(`/authorized/update_account_association`, data).then((res) => {
  //     return new Authorized(res.data);
  //   });
  // }
  // public static async deleteAccountAuthorized(data: AuthorizedAccountData): Promise<unknown> {
  //   return this.httpClient.delete(`/authorized/delete_account_association`, data).then((res) => {
  //     return new Authorized(res.data);
  //   });
  // }
}
// class AuthorizedAccountData {
//   email: string;
//   accountId: number;
//   admin: boolean;
//   constructor(email: string, accountId: number, admin: boolean) {
//     this.email = email;
//     this.accountId = accountId;
//     this.admin = !!admin;
//   }
// }
