import React, { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../context/ToastContext";
import { Form, Input, Select, Spin, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ProductService from "../../services/crud/ProductService";
import Product from "../../models/Product";
import TspService from "../../services/crud/TspService";
import Tsp from "../../models/Tsp";

export default function ProductForm(props) {
  const id = props.id;
  const form = props.form;
  const [product, setProduct] = useState<Product>({} as Product);
  const [tsps, setTsps] = useState<Tsp[]>([]);
  // const update: boolean = props.update ?? false;
  const { loading, loadMessage } = useContext(ToastContext);

  useEffect(() => {
    form.resetFields();
    if (id) loadMessage("Loading product...", () => ProductService.getProduct(id).then((data) => setProduct(data.serialize())));
    loadMessage("Loading tsp...", () => TspService.list().then((data) => setTsps(data)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, form]);
  const tspsOptions = tsps.map((tsp) => {
    return { label: tsp.name + " ( id:" + tsp.id + " )", value: tsp.id };
  });
  const content = (
    <Form initialValues={product} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={"tspId"} label="tsp" rules={[{ required: true }]}>
        <Select options={tspsOptions} />
      </Form.Item>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea />
      </Form.Item>
      <Form.Item name="productCode" label="product code" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
}
