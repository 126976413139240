import { Button, Descriptions, Form, List, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DescriptionListItem } from "../../../components/DescriptionListItem";
import HubForm, { HubAddResourceForm } from "../../../components/forms/HubForm";
import ResourceForm from "../../../components/forms/ResourceForm";
import ResourceTable, { GenericResourceTable } from "../../../components/tables/ResourceTable";
import { OverlayContext } from "../../../context/OverlayContext";
import { ToastContext } from "../../../context/ToastContext";
import Hub from "../../../models/Hub";
import Resource from "../../../models/Resource";
import HubService from "../../../services/crud/HubService";
import ResourceService from "../../../services/crud/ResourceService";
import { ArrowLeftOutlined } from "@ant-design/icons";
import DescriptionList from "../../../components/DescriptionList";
import { ViewTitle } from "../../../components/ViewTitle";
import DescriptionDataList from "../../../components/DescriptionDataList";
import { AdminService } from "../../../services/AdminService";
import { GenericAccountsTable } from "../../../components/tables/AccountsTable";
import { GoBackButton } from "../../../components/GoBackButton";
import BookingsTable, { BookingTableData } from "../../../components/tables/BookingsTable";

type Props = {};

function HubView({}: Props) {
  const { id } = useParams<any>();
  const hubId = parseInt(id);
  const [hub, setHub] = useState<Hub | undefined>();
  const { loading, loadMessage, showMessage } = useContext(ToastContext);
  const { openModal, closeModal } = useContext(OverlayContext);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate(route);
  };

  useEffect(() => {
    loadMessage("Loading hub with id: " + id, () => {
      try {
        return HubService.getHub(id).then((data) => setHub(data));
      } catch (error) {
        console.log(error);
        showMessage("Unable to load hub");
      }
    });
  }, [id]);

  const openUpdateModal = (hub = null) => {
    form.resetFields();
    openModal({
      title: "Update hub",
      content: <HubForm id={id} form={form} update={true} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          const newHub = new Hub(values);
          closeModal();
          loadMessage("Updating hub...", () =>
            HubService.editHub(newHub.id, newHub)
              .then((hub) => {
                setHub(hub);
                return "Hub updated";
              })
              .catch((res) => {
                console.log(res);
                throw Error("Could not update hub");
              })
          );
        });
      },
    });
  };

  const openAddResourceModal = (hub = null) => {
    form.resetFields();
    openModal({
      title: "Add Resource",
      content: <HubAddResourceForm id={id} form={form} update={true} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          loadMessage("Adding resource...", () =>
            AdminService.moveResourceToHub(values.id, values.resourceId)
              .then(() => {
                closeModal();
                return "Added resource";
              })
              .catch((res) => {
                console.log(res);
                throw Error("Could not add resource to hub");
              })
          );
        });
      },
    });
  };

  const resourceDataFilter = (data: Resource) => {
    return data?.hubId + "" === id;
  };
  const bookingDataFilter = (data: BookingTableData) => {
    return data?.hubId === parseInt(id);
  };
  const handleOpenModal = () => {
    openUpdateModal(hub);
  };
  return (
    <div>
      <GoBackButton></GoBackButton>
      <div>
        <ViewTitle title={(hub?.name ?? hub?.id) + ""}></ViewTitle>
        <DescriptionDataList
          data={hub}
          footer={
            <div>
              <Button size="small" type="link" onClick={handleOpenModal}>
                Edit hub
              </Button>
              <Button size="small" type="link" onClick={() => openAddResourceModal(hub.id)}>
                Add resource
              </Button>
            </div>
          }
        ></DescriptionDataList>
      </div>
      {/* RESOURCES */}
      <GenericResourceTable displayTitle dataFilter={resourceDataFilter}></GenericResourceTable>
      {/* ACCOUNTS */}
      {hub?.access === "private" && (
        <GenericAccountsTable displayTitle hideButtons actions={{ edit: false, add: false, view: true }} params={{ hubId }}></GenericAccountsTable>
      )}
      {/* BOOKINGS */}
      <Typography.Title level={3} style={{ marginTop: 40, marginLeft: 10 }}>
        Bookings
      </Typography.Title>
      <BookingsTable dataFilter={bookingDataFilter} columnFilter={(col) => col.key !== "hubId"}></BookingsTable>
    </div>
  );
}

export default HubView;
