import React from "react";
import { PlanProductsResponse as PlanProduct } from "../../models/PlanProduct";
import PlanProductService from "../../services/crud/PlanProductService";
import PlanProductForm, { PlanProductFormEdit } from "../forms/PlanProductForm";
import GenericItemsTable from "./GenericItemsTable";
import { TableProps } from "./types";

function PlanProductProductsTable({ ...rest }: TableProps<PlanProduct>) {
  return <GenericItemsTable name="PlanProduct" model={PlanProduct} service={PlanProductService} form={PlanProductForm} {...rest}></GenericItemsTable>;
}
export function GenericPlanProductProductsTable({ ...rest }: TableProps<PlanProduct>) {
  return (
    <GenericItemsTable
      name="PlanProduct"
      model={PlanProduct}
      service={PlanProductService}
      form={PlanProductForm}
      formEdit={PlanProductFormEdit}
      {...rest}
    ></GenericItemsTable>
  );
}

export default PlanProductProductsTable;
