import React, { useContext, useEffect, useState } from "react";
import { Dropdown, MenuProps, Space, Table, Tooltip, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { ColumnType } from "antd/lib/table";
import Authorized from "../../../models/Authorized";
import User from "../../../models/User";
import AccountService from "../../../services/crud/AccountService";
import { Props } from "./CompanyAccountsView";
import { PrivateAccountFormApplication } from "./PrivateAccountFormApplication";
import AddUserButton from "./AddUserButton"

const UNDEFINED = "-";
/**
 * This is a data mapper that maps the data from the API to the data that the table needs.
 * It takes either a user or a authorized.
 * A invited user is a authorized
 * A registered user is a user
 */
class UserTableData extends User {
  status: string;
  name: string;
  role: string;
  isRegistered: boolean;
  constructor(data: User | Authorized | any) {
    super(data);
    // Differentiate between a user and an authorized
    if (data?.username) {
      const user = data as User;
      this.status = data?.isRegistered ? "registered" : "not registered";
      this.name = user.firstName + " " + user.lastName;
      this.role = user.pivot.admin ? "admin" : "user";
      this.isRegistered = true;
    }
    // Differentiate between a user and an authorized
    else if (data?.email) {
      const authorized = data as Authorized;
      this.status = "invited";
      this.username = authorized.email;
      this.role = authorized.pivot.admin ? "admin" : "user";
      this.isRegistered = false;
    }
  }
}

function UsersAccountsTable({ dataSource = [], accountId, onActionComplete = () => {}, actions, ...rest }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const dataMapper = (data) => {
    return new UserTableData(data);
  };

  const columns: ColumnType<UserTableData>[] = [
    {
      title: "Username",
      key: "username", // Used by UserProfile via columnFilter prop
      dataIndex: ["username"],
      render: (text, record, index) => {
        return <Typography.Text>{text}</Typography.Text>;
      },
      // sorter: (a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
    },

    {
      title: "Name",
      key: "name", // Used by UserProfile via columnFilter prop
      dataIndex: ["name"],
      render: (text, record, index) => {
        return <Typography.Text>{text ?? UNDEFINED}</Typography.Text>;
      },
      // sorter: (a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
    },
    {
      title: "Role",
      key: "role", // Used by UserProfile via columnFilter prop
      dataIndex: ["role"],
      render: (text, record, index) => {
        return <Typography.Text>{text}</Typography.Text>;
      },
      // sorter: (a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
    },
    {
      title: "Created at",
      key: "createdAt",
      dataIndex: ["createdAtAsDate"],
      render: (text, record, index) => {
        return <Typography.Text>{text}</Typography.Text>;
      },
      // sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: "Last logged in",
      dataIndex: ["lastLoginAtAsDate"],
      render: (text, record, index) => {
        return <Typography.Text>{text ?? UNDEFINED}</Typography.Text>;
      },
      // sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: "Status",
      key: "status", // Used by UserProfile via columnFilter prop
      dataIndex: ["status"],
      render: (text, record, index) => {
        return <Typography.Text>{text}</Typography.Text>;
      },
      // sorter: (a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record, index) => {
        return (
          <>
            {actions?.updateBalance && record?.isRegistered && (
              <PrivateAccountFormApplication title={record.username + "'s" + " private account"} id={record.id} />
            )}
          </>
        );
      },
    },
  ];

  const items: MenuProps["items"] = [
    { label: "Promote selected to administrator", key: "SET_SELECTED_TO_ADMIN" },
    { label: "Demote selected to user", key: "SET_SELECTED_TO_USER" },
    {
      label: "Remove selected from account",
      key: "REMOVE_SELECTED_FROM_ACCOUNT",
    },
  ];

  const onClick = async ({ key }) => {
    switch (key) {
      case "REMOVE_SELECTED_FROM_ACCOUNT":
        const removeSelectFromAccount = (email, accountId) => {
          return AccountService.removeEmailFromAccount(accountId, email);
        };
        await Promise.allSettled(selectedRowKeys.map((email) => removeSelectFromAccount(email, accountId)));
        break;
      case "SET_SELECTED_TO_ADMIN":
        const setUserToAdmin = (email, accountId) => {
          return AccountService.updateAccountUser(email, accountId, { admin: true });
        };
        await Promise.allSettled(selectedRowKeys.map((email) => setUserToAdmin(email, accountId)));
        break;
      case "SET_SELECTED_TO_USER":
        const setUserToUser = (email, accountId) => {
          return AccountService.updateAccountUser(email, accountId, { admin: false });
        };
        await Promise.allSettled(selectedRowKeys.map((email) => setUserToUser(email, accountId)));
        break;
    }
    // Reload table
    onActionComplete();
  };

  const ActionsDropdown: React.FC<Props> = ({}) => (
    <Dropdown menu={{ items, onClick }} trigger={["click"]}>
      <a>
        <Space>
          Actions
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Typography.Title level={3}>Users</Typography.Title>
      <Space style={{ marginBottom: 10, justifyContent: "space-between", width: "100%" }}>
        <Space>
          <ActionsDropdown />
          <span style={{ marginLeft: 8 }}> {`(Selected ${selectedRowKeys.length} items)`} </span>
        </Space>
        <AddUserButton
          accountId={accountId}
        ></AddUserButton>
      </Space>
      <Table
        bordered
        rowSelection={rowSelection}
        rowKey="username"
        dataSource={dataSource.map(dataMapper)}
        columns={columns}
        pagination={{ total: dataSource?.length, defaultPageSize: 30, showSizeChanger: true }}
        {...rest}
      ></Table>
    </>
  );
}

export default UsersAccountsTable;
