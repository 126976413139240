import { Form, Table, Tooltip, Typography, Input, Tag } from "antd";
import { ColumnsType } from "antd/es/table/interface";
import React, { useContext, useEffect, useState } from "react";
import { OverlayContext } from "../../context/OverlayContext";
import { ToastContext } from "../../context/ToastContext";
import Subscription from "../../models/Subscription";
import Plan from "../../models/Plan";
import SubscriptionService from "../../services/crud/SubscriptionService";
import PlanService from "../../services/crud/PlanService";
import SubscriptionForm from "../forms/SubscriptionForm";
import { getDebitCycleString } from "../../utils/common";
import { TableProps } from "./types";
import GenericItemsTable from "./GenericItemsTable";
import { StopOutlined } from "@ant-design/icons";

function SubscriptionsTable({ params, dataFilter = () => true, columnFilter = () => true }: TableProps<Subscription>) {
  const [subscriptions, setSubscriptions] = useState<Array<Subscription>>([]);
  const [filter, setFilter] = useState("");
  const { openModal } = useContext(OverlayContext);
  const { loading, loadMessage } = useContext(ToastContext);
  const [form] = Form.useForm();
  // Fetch Data
  useEffect(() => {
    loadMessage("Loading subscription...", () =>
      SubscriptionService.list(params).then((subscriptions) => {
        setSubscriptions(subscriptions);
        return "Loaded subscriptions";
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Default columns: Name, Price
  const columns: any = [
    {
      title: "id",
      dataIndex: ["id"],
      render: (text, record, index) => text,
      sorter: (a, b) => b - a,
    },
    {
      title: "Account",
      dataIndex: ["account", "name"],
      render: (text, record, index) => record?.account?.name ?? <em>Account deleted</em>,
      sorter: (a, b) => a?.account?.name.toLowerCase().localeCompare(b.account?.name.toLowerCase()),
    },
    {
      title: "Transport Service Provider (TSP)",
      dataIndex: ["plan", "products", [0], "tsp", "name"],
      render: (text, record, index) => {
        if (record.plan.products) {
          return record.plan.products.map(({ id, tsp }) => {
            return (
              <Tooltip key={id} title={<Typography.Paragraph>{tsp.name}</Typography.Paragraph>}>
                <Tag>{tsp.name}</Tag>
              </Tooltip>
            );
          });
        } else {
          return null;
        }
      },
      sorter: (a, b) => b.plan?.products?.length - a.plan?.products?.length,
    },
    {
      title: "Plan",
      dataIndex: ["plan", "name"],
      render: (text, record, index) => text ?? <em>Plan deleted</em>,
      sorter: (a, b) => a.plan?.name.toLowerCase().localeCompare(b.plan?.name.toLowerCase()),
    },
    // {
    //   title: "Products",
    //   dataIndex: ["plan", "products", [0], "name"],
    //   render: (text, record, index) => {
    //     if (record.plan.products) {
    //       return record.plan.products.map((product) => {
    //         return (
    //           <Tooltip
    //             key={product.id}
    //             title={
    //               <Typography.Paragraph>
    //                 Price:<br></br>
    //                 {product.pricePerPcInclVat} SEK for {product.topUpYield} products, then <br></br>
    //                 {product.pricePerExcessPcInclVat} SEK for excessive products. <br></br>
    //                 (prices incl VAT at {product.pricePerPcVatFraction * 100}%.)
    //               </Typography.Paragraph>
    //             }
    //           >
    //             <Tag>
    //               {product.topUpYield} x {text}
    //             </Tag>
    //           </Tooltip>
    //         );
    //       });
    //     } else {
    //       return null;
    //     }
    //   },
    //   sorter: (a, b) => a.plan.products[0].name.toLowerCase().localeCompare(b.plan.products[0].name.toLowerCase()),
    // },
    // {
    //   title: "Status",
    //   dataIndex: ["paused"],
    //   render: (text, record, index) => {
    //     if (record.deletedAt) {
    //       return (
    //         <Tag icon={<StopOutlined />} color="red">
    //           Removed
    //         </Tag>
    //       );
    //     }
    //     return !record.activated ? (
    //       <Tag color="grey">Inactivated</Tag>
    //     ) : record.paused ? (
    //       <Tag color="orange">Paused</Tag>
    //     ) : (
    //       <Tag color="blue">Active</Tag>
    //     );
    //     // : <Tag icon={<CheckOutlined />} color="green">Not paused</Tag>
    //   },
    //   sorter: (a, b) => (a.activated ? 1 : b.activated ? -1 : a.paused - b.paused),
    // },
    // {
    //   title: "Pauses",
    //   key: "paused",
    //   render: (text, record, index) => `${record.usedPauses} / ${record.plan.pauseMaxCountPerYear}`,
    //   sorter: (a, b) => a.paused - b.paused,
    // },
    {
      title: "Subscribed at",
      dataIndex: "subscribedAtAsDate",
      render: (text, record, index) => {
        return `${record.subscribedAtAsDate}`;
      },
      sorter: (a, b) => a.subscribedAt.localeCompare(b.subscribedAt),
    },
    // {
    //   title: "Actions",
    //   width: 150,
    //   render: (text, record, index) => (
    //     <div style={{ display: "flex" }}>
    //       <Button type="link" size="small" onClick={() => openUpdateModal(record)}>
    //         Edit
    //       </Button>
    //       <Button type="link" size="small" onClick={() => openViewHistoryModal(record)}>
    //         History
    //       </Button>
    //       <Button type="link" size="small" onClick={() => openDeleteModal(record)}>
    //         Unsubscribe
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  // Filters
  const filterTable = (data) => data.filter((item) => new RegExp(filter.toLowerCase()).test(item.toString().toLowerCase()));
  const filterColumns = (columns = []) => columns.filter(columnFilter);
  // Modals
  // const openViewModal = (booking = null) => {
  //   openModal({
  //     title: "View subscription",
  //     content: <SubscriptionForm id={booking.id} form={form} update={false} />,
  //     okText: "Close",
  //     hasCancel: false,
  //   });
  // };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <Input.Search size="middle" placeholder="Search accounts..." style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} />
      </div>
      <Table
        rowKey="id"
        columns={filterColumns(columns)}
        dataSource={filterTable(subscriptions)}
        pagination={{ total: subscriptions.length, defaultPageSize: 30, showSizeChanger: true }}
        // onRow={(record) => ({ onClick: () => openViewModal(record) })}
        loading={loading}
      />
      <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>
        Total of {subscriptions.length} subscriptions
      </Typography.Text>
    </div>
  );
}

export const GenericSubscriptionsTable = ({ ...rest }: TableProps<Subscription>) => {
  return <GenericItemsTable model={Subscription} form={SubscriptionForm} service={SubscriptionService} {...rest}></GenericItemsTable>;
};

export default SubscriptionsTable;
