import Plan from "../../models/Plan";
import HttpClient from "../../clients/HttpClient";

export default class PlanService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async list(params = {}): Promise<Array<Plan>> {
    return this.httpClient.get("/plans", params).then((res) => {
      return res.data.map((row) => new Plan(row));
    });
  }

  public static async getPlan(id): Promise<Plan> {
    return this.httpClient.get(`/plans/${id}`).then((res) => {
      return new Plan(res.data);
    });
  }

  public static async addPlan(data): Promise<Plan> {
    return this.httpClient.post(`/plans`, data).then((res) => {
      return new Plan(res.data);
    });
  }

  public static async editPlan(id, data): Promise<Plan> {
    return this.httpClient.put(`/plans/${id}`, data).then((res) => {
      return new Plan(res.data);
    });
  }

  public static async deletePlan(id): Promise<boolean> {
    return this.httpClient.delete(`/plans/${id}`, null).then((res) => {
      return true;
    });
  }
}

export const PLAN_MOCK_CREATE_RESPONSE = {
  activationNeeded: true,
  active: true,
  bindingPeriod: 0,
  carryOver: true,
  createdAt: "2022-12-20T11:12:00",
  debitCycle: 0,
  deletedAt: null,
  description: "Cykel är nyckeln till lycka!!",
  id: 20007,
  name: "EC2B Xtreme++",
  pauseMaxCountPerYear: 2,
  payAsYouGo: true,
  priceInclVat: 0,
  priceStartInclVat: 0,
  priceStartVatFraction: 25,
  priceVatFraction: 25,
  products: [
    {
      createdAt: "2022-12-06T13:56:00",
      deletedAt: null,
      description: "Bike with two wheels.",
      id: 1,
      name: "test",
      physical: 1,
      pivot: {
        creditsPerPc: 1,
        dynamicPrice: 0,
        name: "",
        planId: 20007,
        pricePerExcessPcInclVat: 1,
        pricePerExcessPcVatFraction: 1,
        pricePerPcInclVat: 1,
        pricePerPcVatFraction: 1,
        productId: 1,
        topUpMaxAccumulatedCount: 10000,
        topUpWarnAt: 10000,
        topUpYield: 1,
      },
      productCode: "123",
      tspId: 1,
      updatedAt: "2022-12-13T16:53:00",
    },
  ],
  terminationNoticePeriod: 0,
  topUpCycle: 0,
  tspId: 1,
  updatedAt: "2022-12-20T11:12:00",
};
