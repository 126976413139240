import React, { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../context/ToastContext";
import { Form, Input, Spin, Tooltip } from "antd";
import { LoadingOutlined, QuestionCircleFilled } from "@ant-design/icons";
import TspService from "../../services/crud/TspService";
import Tsp from "../../models/Tsp";

// const getBase64 = (img, callback) => {
//   const reader = new FileReader();
//   reader.addEventListener("load", () => callback(reader.result));
//   reader.readAsDataURL(img);
// };

export const TspForm = (props) => {
  const id = props.id;
  const form = props.form;
  const { loading, loadMessage } = useContext(ToastContext);
  const [tsp, setTsp] = useState({} as Tsp);
  // const [uploadingImage, setUploadingImage] = useState(false);
  // const [uploadedImageUrl, setUploadedImageUrl] = useState(null);

  useEffect(() => {
    form.resetFields();
    loadMessage("Loading TSP...", () => TspService.getTsp(id).then((data) => setTsp(data)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, id]);

  const content = (
    <Form initialValues={tsp} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>

      {/* <Form.Item name="image" label="Image" rules={[{ required: true }]}>
        <Input type="text" style={{ display: "none" }}/>
        <Upload
          accept="image/*"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={(file) => {
            setUploadingImage(true);
            getBase64(file, async (imageUrl) => {
              const parts = imageUrl.split("base64,");
              setUploadedImageUrl(imageUrl);
              setUploadingImage(false);
              form.setFieldsValue({ "image": parts[1] });
              tsp.logo = parts[1]
            });
            // Prevent upload
            return false;
        }}>
          {uploadedImageUrl ? (
            <img src={uploadedImageUrl} alt="hero" style={{ width: "100%" }} />
          ) : (
            <div>
              {uploadingImage ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">Upload</div>
            </div>
          )}
        </Upload>
      </Form.Item> */}

      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>

      <Form.Item name="tspKey" label="key" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <Input.TextArea />
      </Form.Item>

      <Form.Item name="phoneNumber" label="Phone number" rules={[{ required: true }]}>
        {/* <Input addonBefore={<Typography.Text>+46</Typography.Text>} type="text" /> */}
        <Input type="text" />
      </Form.Item>

      <Form.Item name="phoneNumberDescription" label="Phone number description">
        <Input type="text" />
      </Form.Item>
      <Tooltip title="is displayed in small text below the phone number in the app.">
        <QuestionCircleFilled />
      </Tooltip>

      <Form.Item name="supportEmailUrl" label="Support Email url">
        <Input type="text" placeholder="mailto:hello@example.com | https://..." />
      </Form.Item>
      <Tooltip title="If non-empty, a Email button is added to the help dialog that opens this URL.">
        <QuestionCircleFilled />
      </Tooltip>
      <Form.Item name="website" label="Website url" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="websiteFaqUrl" label="FAQ url">
        <Input type="text" />
      </Form.Item>
      <Tooltip title="If non-empty a FAQ button for this URL is added to the help dialog for this TSP">
        <QuestionCircleFilled />
      </Tooltip>
      <Form.Item name="websiteSupportUrl" label="Support url">
        <Input type="text" />
      </Form.Item>
      <Tooltip title="If non-empty a customer service button for this URL is added to the help dialog for this TSP. The older Support button in bookings will point to this URL if given, otherwise they point to the website url.">
        <QuestionCircleFilled />
      </Tooltip>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};
