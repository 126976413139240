import React from "react";
import { DescriptionListItem } from "./DescriptionListItem";
import { underScoreFilter } from "../utils/common";
import BaseModel from "../models/BaseModel";
import { isEmpty } from "lodash";
import { List, Space } from "antd";
import DescriptionList from "./DescriptionList";

type Props = { data?: BaseModel; dataFilter?: (key: string) => boolean };

/**
 * This component displays all props in a object that extends baseModel that does not begin with underscore.
 * @param param0
 * @returns an array of ListItems or nothing
 */
function DescriptionListItems({ data, dataFilter = (key) => true }: Props) {
  if (data) {
    const descriptionListItems = Object.entries(data)
      // remove props with underscore
      .filter(underScoreFilter)
      .filter(([key, value]) => dataFilter(key))
      // Convert object into array of ListItems
      .map(([key, value], index) => {
        if (typeof value === "object") {
          // Is of type array
          if (value?.length) return <DescriptionListItem key={key} label={key} value={value?.length}></DescriptionListItem>;
          // Is empty
          if (isEmpty(value)) return <></>;
          // Not array but contains name and id
          return <DescriptionListItem key={key} label={key} value={value?.name ?? value?.id}></DescriptionListItem>;
        }
        if (value) return <DescriptionListItem key={key} label={key} value={value + ""}></DescriptionListItem>;
        return <></>;
      });
    return <>{descriptionListItems}</> ?? <></>;
  }
  return <></>;
}

export default DescriptionListItems;
