import React from "react";
import AccountsTable, { GenericAccountsTable } from "../../../components/tables/AccountsTable";
import Account from "../../../models/Account";

type Props = {};
/**
 * This page lists all private accounts
 * @param param0
 * @returns
 */
function PrivateAccounts({}: Props) {
  return (
    <div>
      <GenericAccountsTable params={{ type: Account.types.PRIVATE }}></GenericAccountsTable>
    </div>
  );
}

export default PrivateAccounts;
