import Account from "./Account";
import BaseModel from "./BaseModel";
import User from "./User";

export class AccountAuthorized extends Account {
  pivot: { accountId: number; authorizedId: number; admin: boolean };
  static __dates = [];
  constructor(data) {
    super(data);
    this.pivot = data.pivot;
  }
}

class Authorized extends BaseModel {
  id: number;
  email: string;
  hubId?: number; // Oklart om denna ska vara med
  timestamp: number;
  admin: boolean;
  accountId: number;
  accounts?: Array<AccountAuthorized>;
  pivot?: any;
  constructor(data) {
    super(data, []);
    this.id = data.id;
    this.email = data.email;
    this.hubId = data.hubId;
    this.timestamp = data.timestamp;
    this.admin = data.admin;
    this.accountId = data.accountId;
    this.accounts = data.accounts;
    this.pivot = data.pivot;
  }
}

export default Authorized;

/// From a list of authorized and a list of users, obtain a list of authorized items with emails not in the users list.
export function getAuthorizedNotInUsersArray(authorized: Array<Authorized>, users: Array<User>): Array<Authorized> {
  const userEmails = users.map(u => u.username);
  return authorized.filter((item: Authorized) => userEmails.indexOf(item.email) === -1);
}