import { Button, Form, Input, Select } from "antd";
import { isEmpty } from "lodash";
import React, { useState } from "react";

interface ColorInputProps {
  id?: string;
  value?: string;
  onChange?: (value: string) => void;
}

const ColorInput: React.FC<ColorInputProps> = ({ id, value, onChange }) => {

  const triggerChange = (changedValue: string) => {
    onChange?.(changedValue);
  };

  const onColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    triggerChange(e.target.value);
  };

  const onHexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    triggerChange(e.target.value);
  };

  if (value === null || value === undefined)
    return (
      <span>
        <Button
          onClick={() => {
            triggerChange("#000000");
          }}
        >
          Choose color
        </Button>
      </span>
    );
  // Custom validation function for hex codes
  const validateHexCode = (_, value) => {
    if (!value || /^#([0-9A-F]{3}){1,2}$/i.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Invalid hex code!"));
  };
  return (
    <span>
      <Form.Item noStyle name={id} rules={[{ validator: validateHexCode, required: true }]}>
        <Input type="text" value={value} onChange={onHexChange} minLength={7} maxLength={7} />
      </Form.Item>
      <Input type="color" value={value} onChange={onColorChange} />
      <Button onClick={() => triggerChange(null)}>Reset</Button>
    </span>
  );
};

export default ColorInput;
