import BaseModel from "./BaseModel";

class SubscriptionHistory extends BaseModel {
  info: string;
  type: string;

  static TYPES = {
    activated: "Subscription started",
    additional_credits: "Additional credit cost",
    booking: "Booking",
    booking_canceled: "Booking canceled",
    created: "Plan activated",
    credit_top_up: "Top up",
    downgrade: "Plan changed to",
    got_credits_admin: "Balance adjustment by admin",
    lost_credits_admin: "Balance adjustment by admin",
    paused: "Paused",
    shared: "Plan shared with",
    skipped_top_up: "Top-Up skipped",
    subscribed: "Subscription request sent",
    billed: "Subscription cost",
    subscription_product_added: "Added by EC2B",
    subscription_product_removed: "Removed by EC2B",
    subscription_product_pickup_booked: "Pickup booked",
    subscription_product_delivery_booked: "Deliver booked",
    subscription_product_retrieval_booked: "Retrieval booked",
    subscription_product_return_booked: "Return booked",
    unpaused: "Subscription resumed",
    unshared: "Sharing removed from",
    unsubscribed: "Unsubscribed",
    upgrade: "Plan changed to",
  };

  static COLORS = {
    activated: "green",
    booking: "green",
    booking_canceled: "red",
    downgrade: "red",
    got_credits: "green",
    got_credits_admin: "green",
    lost_credits_admin: "red",
    paused: "red",
    shared: "blue",
    skipped_top_up: "grey",
    subscribed: "green",
    subscribed_billed: "blue",
    subscription_product_added: "green",
    subscription_product_removed: "red",
    unpaused: "green",
    unshared: "red",
    unsubscribed: "red",
    upgrade: "green",
  };

  constructor(data) {
    super(data, []);
    this.info = data.info;
    this.type = data.type;
  }
}

export default SubscriptionHistory;
