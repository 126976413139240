import Resource from "../../models/Resource";
import HttpClient from "../../clients/HttpClient";

export default class ResourceService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async list(params = {}): Promise<Array<Resource>> {
    return this.httpClient.get("/resources/index").then((res) => {
      return res.data.map((row) => new Resource(row));
    });
  }

  public static async addResource(data): Promise<Resource> {
    return this.httpClient.post(`/resources/create`, data).then((res) => {
      return new Resource(res.data);
    });
  }

  public static async getResource(id): Promise<Resource> {
    return this.httpClient.get(`/resources/get_resource/${id}`).then((res) => {
      return new Resource(res.data);
    });
  }

  public static async editResource(id, data): Promise<Resource> {
    return this.httpClient.put(`/resources/update/${id}`, data).then((res) => {
      return new Resource(res.data);
    });
  }

  public static async deleteResource(id): Promise<Resource> {
    return this.httpClient.delete(`/resources/delete/${id}`, {}).then((res) => {
      return new Resource(res.data);
    });
  }
}
