import BaseModel from "./BaseModel";
import Tsp from "./Tsp";
import Product from "./Product";

class Plan extends BaseModel {
  tspId: number;
  name: string;
  activationNeeded: boolean;
  description: string;
  active: boolean;
  carryOver: boolean;
  topUpCycle: number; // (in months)
  debitCycle: number; // (in months)
  terminationNoticePeriod: number; // (in months)
  bindingPeriod: number; // (in months)
  priceInclVat: number; // Price for each debit
  priceVatFraction: number;
  priceStartInclVat: number;
  priceStartVatFraction: number;
  pauseMaxCountPerYear: number;
  products: Array<Product> | Array<any>;
  payAsYouGo: boolean;
  tsp: Tsp | any;
  topUpYeild: number;
  constructor(data) {
    super(data, []);
    this.tspId = data.tspId;
    this.name = data.name;
    this.activationNeeded = data.activationNeeded;
    this.description = data.description;
    this.active = data.active;
    this.carryOver = data.carryOver;
    this.topUpCycle = data.topUpCycle;
    this.debitCycle = data.debitCycle;
    this.terminationNoticePeriod = data.terminationNoticePeriod;
    this.bindingPeriod = data.bindingPeriod;
    this.priceInclVat = data.priceInclVat;
    this.priceVatFraction = data.priceVatFraction;
    this.priceStartInclVat = data.priceStartInclVat;
    this.priceStartVatFraction = data.priceStartVatFraction;
    this.pauseMaxCountPerYear = data.pauseMaxCountPerYear;
    this.tsp = data.tsp ? new Tsp(data.tsp) : {};
    this.topUpYeild = data.topUpYeild;
    this.payAsYouGo = data.payAsYouGo;
    this.products = data.products
      ? data.products.map(
          (pdata) =>
            new Product({
              ...pdata,
              ...(pdata.pivot
                ? {
                    topUpYield: pdata.pivot.topUpYield ?? 0,
                    topUpMaxAccumulatedCount: pdata.pivot.topUpMaxAccumulatedCount ?? 0,
                    topUpWarnAt: pdata.pivot.topUpWarnAt ?? 0,
                    pricePerPcInclVat: pdata.pivot.pricePerPcInclVat ?? 0,
                    pricePerPcVatFraction: pdata.pivot.pricePerPcVatFraction ?? 0,
                    pricePerExcessPcInclVat: pdata.pivot.pricePerExcessPcInclVat ?? 0,
                    pricePerExcessPcVatFraction: pdata.pivot.pricePerExcessPcVatFraction ?? 0,
                  }
                : null),
            })
        )
      : [];
  }
}

export default Plan;
/**
 * Used for populating a form. Contains all required fields
 */
export const planTemplate = {
  name: "",
  description: "",
  active: true,
  payAsYouGo: true,
  activationNeeded: true,
  carryOver: true,
  topUpCycle: 0,
  debitCycle: 1,
  terminationNoticePeriod: 0,
  bindingPeriod: 0,
  topUpYeild: 0,
  priceInclVat: 0,
  priceVatFraction: 0,
  priceStartInclVat: 0,
  priceStartVatFraction: 0,
  // PlanProducts
  products: [],
};
