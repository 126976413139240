import React, { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../context/ToastContext";
import { Button, Form, Input, InputNumber, Select, Spin, Tooltip } from "antd";
import { LoadingOutlined, QuestionCircleFilled } from "@ant-design/icons";

import { OverlayContext } from "../../context/OverlayContext";
import { useForm } from "antd/lib/form/Form";
import { OrganizationInterface } from "../../models/temp/Organisation";
import AccountService from "../../services/crud/AccountService";

const OrganizationForm = (props) => {
  const [form] = useForm(props?.form);
  const { loading, loadMessage } = useContext(ToastContext);
  useEffect(() => {}, [form]);

  const content = (
    <Form form={form}>
      <Form.Item name="invoiceReference" label="Invoice reference" rules={[{ required: true }]}>
        <Input type="text" maxLength={32} />
      </Form.Item>
      <Tooltip title="The invoice recipient reference.">
        <QuestionCircleFilled />
      </Tooltip>
      <Form.Item
        name="invoiceOrganizationalNumber"
        label="Organization number"
        rules={[
          { required: true },
          {
            required: true,
            validator: (_, value) => {
              if (value?.length < 10 || value?.replace("-", "")?.length > 10) {
                return Promise.reject('must be atleast 10 symbols or 11 if you include "-"');
              } else return Promise.resolve();
            },
          },
        ]}
      >
        <Input style={{ width: "100%", maxWidth: "100%" }} minLength={10} maxLength={11} />
      </Form.Item>
      <Tooltip title="For example: 559116-8611 or 5591168611">
        <QuestionCircleFilled />
      </Tooltip>
      <Form.Item name="invoiceEmail" label="Email" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Tooltip title="The email that will recieve the invoice.">
        <QuestionCircleFilled />
      </Tooltip>
      <Form.Item name="invoiceAddress" label="Street Address" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Tooltip title="For example: Bangatan 15B">
        <QuestionCircleFilled />
      </Tooltip>
      <Form.Item name="invoiceCity" label="City" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Tooltip title="For example: Lund">
        <QuestionCircleFilled />
      </Tooltip>
      <Form.Item name="invoiceZipCode" label="Zip code" rules={[{ required: true }]}>
        <InputNumber style={{ width: "100%", maxWidth: "100%" }} maxLength={5} />
      </Form.Item>
      <Tooltip title="For example: 32532">
        <QuestionCircleFilled />
      </Tooltip>
      <Form.Item name="invoiceVatNr" label="VAT number" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Tooltip title="For example: SE559116861101">
        <QuestionCircleFilled />
      </Tooltip>
      <Form.Item name="invoiceCountry" label="Country" rules={[{ required: true }]}>
        <Select options={[{ value: "Sweden", label: "Sweden" }]}></Select>
      </Form.Item>
      <Form.Item name="invoiceCountryCode" label="Country Code" rules={[{ required: true }]}>
        <Select
          options={[
            {
              value: "SE",
              label: "Sweden (SE)",
            },
          ]}
        ></Select>
      </Form.Item>
      <Form.Item name="invoiceAttention" label="Attention">
        <Input type="text" maxLength={40} />
      </Form.Item>
      <Tooltip title="Attention (person that should receive the invocie)">
        <QuestionCircleFilled />
      </Tooltip>
      <Button
        onClick={() => {
          form.resetFields();
          form.setFieldsValue({ invoiceCountry: "Sweden", invoiceCountryCode: "SE" });
        }}
      >
        Reset
      </Button>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};

const transformValues = (values: OrganizationInterface): OrganizationInterface => {
  return {
    ...values,
    // Allow user to input dash
    invoiceOrganizationalNumber: values.invoiceOrganizationalNumber.replace("-", ""),
  };
};
/**
 * Opens a modal with the OrganizationForm
 * onSubmit is called when the form is submitted
 * @param param0
 * @returns
 */
export const OrganizationButtonForm = ({ accountId }: { accountId: number }) => {
  const { openModal, closeModal } = useContext(OverlayContext);
  const [loading, setLoading] = useState(false);
  const [form] = useForm<OrganizationInterface>();
  const { showMessage, loadMessage } = useContext(ToastContext);

  const handleClickOpenModal = async () => {
    let orgData = {};
    // Load org if account has org
    if (accountId) {
      setLoading(true);
      try {
        orgData = await AccountService.getInvoice({ accountId: accountId });
      } catch (e) {
        showMessage("unable to load org data");
      }
      setLoading(false);
      form.setFieldsValue(orgData);
      openModal({
        title: "Send invoice to",
        content: <OrganizationForm form={form} accountId={accountId} />,
        onOk: () => {
          form.validateFields().then(async (values) => {
            const updateInvoicePayload: OrganizationInterface = {
              ...values,
              accountId: accountId,
            };

            const success = await loadMessage("Updating invoice", () => {
              return AccountService.updateInvoice(transformValues(updateInvoicePayload));
            });
            if (success) {
              form.resetFields();
              closeModal();
            }
          });
        },
      });
    }
  };
  return (
    <Button loading={loading} type="primary" onClick={handleClickOpenModal}>
      Send invoice to
    </Button>
  );
};

export default OrganizationForm;
