import moment, { isMoment } from "moment";
import { magicMethods } from "../helpers/magic-methods";

@magicMethods
class BaseModel {
  id?: number;
  __defaultDates: Array<string> = ["createdAt", "updatedAt", "deletedAt"];
  __dates: Array<string> = [];
  __dateFormat: "YYYY-MM-DD";
  __storedDates: Object = {};

  constructor(data, dates) {
    // Convert id to number
    try {
      this.id = data?.id ? parseInt(data.id) : null;
    } catch (error) {
      console.log(error);
      this.id = null;
    }

    const allDates = [...dates, ...this.__defaultDates];
    for (let date of allDates) {
      this.__storedDates[date] = data[date] ? moment(data[date]) : null;
    }
  }

  __get(name) {
    let dateOnly = false;
    if (name.toString().indexOf("AsDate") > 0) {
      name = name.toString().split("AsDate")[0];
      dateOnly = true;
    }
    if (name in this.__storedDates) {
      const value = this.__storedDates[name];
      if (isMoment(value)) {
        return dateOnly ? value.format("YYYY-MM-DD") : value.format("YYYY-MM-DD HH:mm:ss");
      }
      return value;
    }
    return this[name];
  }

  __set(name, value) {
    if ([...this.__dates, ...this.__defaultDates].includes(name)) {
      this.__storedDates[name] = value ? moment(value) : null;
    } else {
      this[name] = value;
    }
    return true;
  }

  public toString() {
    const obj = this.serialize();
    return JSON.stringify(obj).replace(/"([^(")"]+)":/g, "$1:");
  }

  public serialize() {
    // const serializedObject = {}
    const serializedObject = Object.assign({}, this);
    // for (const prop in this) {
    //   if (serializedObject[`${prop}`] instanceof BaseModel) {
    //     serializedObject[`${prop}`] = serializedObject[`${prop}`].serialize();
    //   }
    //   if (
    //     serializedObject[`${prop}`] instanceof Array &&
    //     serializedObject[`${prop}`].length > 0 &&
    //     serializedObject[`${prop}`][0] instanceof BaseModel
    //   ) {
    //     serializedObject[`${prop}`].forEach((item, k) => {
    //       serializedObject[`${prop}`][k] = item.serialize();
    //     });
    //   }
    // }
    // delete serializedObject.__defaultDates;
    // delete serializedObject.__dates;
    // delete serializedObject.__dateFormat;
    // delete serializedObject.__storedDates;
    return serializedObject;
  }
}

export default BaseModel;
