import BaseModel from "./BaseModel";

class Tsp extends BaseModel {
  logo: string;
  description: string;
  name: string;
  phoneNumber: string;
  phoneNumberDescription: string;
  website: string;
  websiteFaqUrl: string;
  websiteSupportUrl: string;
  supportEmailUrl: string;
  tspKey: string;

  constructor(data) {
    super(data, []);
    this.logo = data.logo;
    this.description = data.description;
    this.name = data.name;
    this.phoneNumber = data.phoneNumber;
    this.phoneNumberDescription = data.phoneNumberDescription;
    this.supportEmailUrl = data.supportEmailUrl;
    this.website = data.website;
    this.websiteFaqUrl = data.websiteFaqUrl;
    this.websiteSupportUrl = data.websiteSupportUrl;
    this.tspKey = data.tspKey;
  }

  // get phoneNumber():string {
  //   return this.phoneNumber.length === 10 ? this.phoneNumber.substr(1, 9) : this.phoneNumber;
  // }

  // set phoneNumber(value:string) {
  //   this.phoneNumber = value;
  // }
}

export default Tsp;
