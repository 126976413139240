import React from "react";
import BaseModel from "../models/BaseModel";
import DescriptionList from "./DescriptionList";
import DescriptionListItems from "./DescriptionListItems";

function DescriptionDataList({ data, ...rest }) {
  return (
    <DescriptionList {...rest}>
      <DescriptionListItems data={data}></DescriptionListItems>
    </DescriptionList>
  );
}

export default DescriptionDataList;
