/**
 * Using a file downloaded by passing blob:true to HttpClient, offer it for
 * download to the client.
 * @param file The blob data from HttpClient
 * @param filename Filename of file to give as default download name to the browser.
 */
export async function downloadFile(file: Blob, filename: string) {
  // Create a temporary anchor element to trigger the download and then remove it
  const link = document.createElement('a');
  const url = window.URL.createObjectURL(file);
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}