import { useState, useEffect, useContext } from "react";
import WhiteLabel from "../../models/WhiteLabels";
import WhiteLabelsService from "../../services/crud/WhiteLabelsService";
import { Button, Form, FormInstance, Input, Spin } from "antd";
import { ToastContext } from "../../context/ToastContext";
import { LoadingOutlined } from "@ant-design/icons";
import Subform from "../Global/Subform";
import ColorInput from "./components/ColorPicker";

const WhiteLabelForm = (props) => {
  const id = props.id;
  const form = props.form;
  const update: boolean = props.update ?? false;
  const { loading, loadMessage } = useContext(ToastContext);

  useEffect(() => {
    if (id) {
      form.resetFields();
      loadMessage("Loading white label item...", () =>
        WhiteLabelsService.getWhiteLabel(id).then((data) => {
          form.setFieldsValue(data);
        })
      );
    }
  }, [id, form]);

  return (
    <Form<WhiteLabel> form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>

      <Form.Item label="Name" name="name" required>
        <Input />
      </Form.Item>

      <Form.Item label="Description" name="description">
        <Input />
      </Form.Item>

      <Form.Item label="Header Background Color" name="headerBgColor">
        <Input type="color" />
      </Form.Item>

      <Form.Item label="Header Foreground Color" name="headerFgColor">
        <Input type="color" />
      </Form.Item>

      <Form.Item label="Tabs Background Color" name="tabsBgColor">
        <Input type="color" />
      </Form.Item>

      <Form.Item label="Tabs Icon Color" name="tabsIconColor">
        <Input type="color" />
      </Form.Item>

      <Form.Item label="Tabs Text Color" name="tabsTextColor">
        <Input type="color" />
      </Form.Item>

      <Form.Item label="Tabs Selected Icon Color" name="tabsSelectedIconColor">
        <Input type="color" />
      </Form.Item>

      <Form.Item label="Tabs Selected Text Color" name="tabsSelectedTextColor">
        <Input type="color" />
      </Form.Item>

      <Form.Item label="Extra" name="extra">
        <Input />
      </Form.Item>
    </Form>
  );
};


export const WhiteLabelFormGenericForm = (props) => {
  const form: FormInstance = props.form;
  const id = props.id;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.resetFields();
    if (id) {
      setLoading(true);
      WhiteLabelsService.getWhiteLabel(id).then((data) => {
        setLoading(false);
        form.setFieldsValue(data);
      });
    }

    // Load data
  }, [form, id]);

  if (loading) {
    return <></>;
  }

  const content = (
    <Form form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Subform
        content={
          <>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Description" name="description">
              <Input />
            </Form.Item>

            <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.headerFgColor !== currentValues.headerFgColor ||
                prevValues.headerBgColor !== currentValues.headerBgColor
              }
            >
              {({ getFieldValue }) => {
                const headerFgColor = getFieldValue("headerFgColor");
                const headerBgColor = getFieldValue("headerBgColor");

                const isAnyColorsSelected = headerFgColor || headerBgColor;
                const isAllColorsSelected = headerFgColor && headerBgColor;
                const mixedInput = isAnyColorsSelected && !isAllColorsSelected;

                return (
                  <>
                    <h2>Header colors</h2>
                    <div style={{ border: mixedInput ? '1px solid orange' : ''}}>
                      <Form.Item
                        name="headerBgColor"
                        label="Header Background Color"
                      >
                        <ColorInput />
                      </Form.Item>

                      <Form.Item
                        name="headerFgColor"
                        label="Header Foreground Color"
                      >
                        <ColorInput />
                      </Form.Item>
                    </div>

                    { mixedInput ? ( <p style={{marginTop: 5}}><b>Warning: </b>If you select one color for <b>Header</b>, then set all to avoid text contrast issues if/when the defaults change in the future.</p> ) : ''}
                  </>
                );
              }}
            </Form.Item>

            <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.tabsBgColor !== currentValues.tabsBgColor ||
                prevValues.tabsIconColor !== currentValues.tabsIconColor ||
                prevValues.tabsTextColor !== currentValues.tabsTextColor ||
                prevValues.tabsSelectedIconColor !== currentValues.tabsSelectedIconColor ||
                prevValues.tabsSelectedTextColor !== currentValues.tabsSelectedTextColor
              }
            >
              {({ getFieldValue }) => {
                const tabsBgColor = getFieldValue("tabsBgColor");
                const tabsIconColor = getFieldValue("tabsIconColor");
                const tabsTextColor = getFieldValue("tabsTextColor");
                const tabsSelectedIconColor = getFieldValue("tabsSelectedIconColor");
                const tabsSelectedTextColor = getFieldValue("tabsSelectedTextColor");

                const isAnyColorsSelected = tabsBgColor || tabsIconColor || tabsTextColor || tabsSelectedIconColor || tabsSelectedTextColor;
                const isAllColorsSelected = tabsBgColor && tabsIconColor && tabsTextColor && tabsSelectedIconColor && tabsSelectedTextColor;
                const mixedInput = isAnyColorsSelected && !isAllColorsSelected;

                return (
                  <>
                    <h2>Tabs colors</h2>
                    <div style={{ border: mixedInput ? '1px solid orange' : ''}}>
                      <Form.Item
                        name="tabsBgColor"
                        label="Tabs Background Color"
                      >
                        <ColorInput />
                      </Form.Item>

                      <Form.Item
                        name="tabsIconColor"
                        label="Tabs Icon Color"
                      >
                        <ColorInput />
                      </Form.Item>

                      <Form.Item
                        name="tabsTextColor"
                        label="Tabs Text Color"
                      >
                        <ColorInput />
                      </Form.Item>

                      <Form.Item
                        name="tabsSelectedIconColor"
                        label="Tabs Selected Icon Color"
                      >
                        <ColorInput />
                      </Form.Item>

                      <Form.Item
                        name="tabsSelectedTextColor"
                        label="Tabs Selected Text Color"
                      >
                        <ColorInput />
                      </Form.Item>
                    </div>

                    { mixedInput ? ( <p style={{marginTop: 5}}><b>Warning: </b>If you select one color for <b>Tabs</b>, then set all to avoid text contrast issues if/when the defaults change in the future.</p> ) : ''}
                    <p></p>
                  </>
                );
              }}
            </Form.Item>
          </>
        }
      />
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};

export default WhiteLabelForm;
