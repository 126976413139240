import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Space, Table, Tooltip, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationContext } from "../../../context/NavigationContext";
import { ToastContext } from "../../../context/ToastContext";
import { useForm } from "antd/lib/form/Form";
import { OverlayContext } from "../../../context/OverlayContext";
import SubscriptionService from "../../../services/crud/SubscriptionService";
import { ColumnType } from "antd/lib/table/interface";
import UserFeedback from "../../../models/UserFeedback";
import UserFeedbackService from "../../../services/crud/UserFeedbackService";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { plansState, subscriptionsState } from "../../../recoil/atoms";
import { Props } from "./CompanyAccountsView";
import BookingExternalService, { BookingExternal } from "../../../services/crud/BookingExternalService";
import RatingStars from "../../../components/RatingStars";
import { formatDate, formatDateTime } from "../../../utils/dates";
import { DetailedBookingView } from "./DetailedBookingView";

interface UserFeedbackRow {
  userFeedback: UserFeedback;
  booking: BookingExternal;
}

const userFeedbacksState = atom<UserFeedbackRow[]>({
  key: "userFeedbacks", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

export const UserFeedbacksTable = ({ dataSource = undefined, onDeleteSelected = (key) => {} }) => {
  const [userFeedbacks, setUserFeedbacks] = useRecoilState(userFeedbacksState);
  const { user } = useContext(NavigationContext);
  const { loading, loadMessage, showMessage } = useContext(ToastContext);

  function loadTable() {
    // Load all user feedback
    loadMessage("Loading bookings feedback...", async () => {
      const promises: Array<Promise<any>> = [];
      const bookings = await BookingExternalService.list();
      const bMap = {};
      bookings.map((b) => {
        if (b.userFeedback !== null && b.userFeedback !== undefined) {
          promises.push(UserFeedbackService.getUserFeedback(b.id));
          bMap[b.id] = b;
        }
      });
      const feedback = await Promise.all(promises);
      const rows = feedback.map((f) => {
        return {
          userFeedback: f,
          booking: bMap[f.bookingsExternalId]
        }
      });
      rows.sort((a, b) => (new Date(a.userFeedback.createdAt)).getTime() - (new Date(b.userFeedback.createdAt)).getTime());
      setUserFeedbacks(rows);
    });
  }
  useEffect(() => {
    if (dataSource) setUserFeedbacks(dataSource);
  }, [dataSource]);
  useEffect(() => {
    loadTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const { openModal } = useContext(OverlayContext);
  const openViewModal = (booking:BookingExternal) => {
    openModal({
      title: "View booking",
      content: <DetailedBookingView booking={booking} />,
      okText: "Close",
      hasCancel: false,
    });
  };

  const columns: ColumnType<UserFeedbackRow>[] = [
    {
      title: "Booking id",
      key: "bookingId",
      dataIndex: ["userFeedback.bookingsExternalId"],
      render: (text, record, index) => {
        return <Typography.Text>{record.userFeedback.bookingsExternalId ?? "-"}</Typography.Text>;
      },
      sorter: (a, b) => a.userFeedback.bookingsExternalId - b.userFeedback.bookingsExternalId,
    },
    {
      title: "Booking details",
      key: "booking",
      dataIndex: ["userFeedback.booking.user.username"],
      render: (text, record, index) => {
        return <Typography.Text>
          {record.booking?.bookingLabel ?? "-"}<br/>
          Time: {formatDateTime(record.booking?.bookFromDateTime)} - {formatDateTime(record.booking?.bookToDateTime)}<br/>
        </Typography.Text>;
      },
    },
    {
      title: "Hub",
      key: "hub",
      dataIndex: ["userFeedback.booking.pickupHub.name"],
      render: (text, record, index) => {
        return <Typography.Text>{record.booking?.pickupHub?.name ?? "-"}</Typography.Text>;
      },
      sorter: (a, b) => a.booking?.pickupHub?.name?.toLocaleLowerCase().localeCompare(b.booking?.pickupHub?.name?.toLocaleLowerCase()),
    },
    {
      title: "Rating",
      key: "rating",
      dataIndex: ["userFeedback.rating"],
      render: (text, record, index) => {
        return <RatingStars rating={record.userFeedback.rating}></RatingStars>
      },
      sorter: (a, b) => a.userFeedback.rating - b.userFeedback.rating,
    },
    {
      title: "Feedback text",
      key: "feedbackText",
      dataIndex: ["userFeedback.feedbackText"],
      render: (text, record, index) => {
        return <Typography.Text>{record.userFeedback.feedbackText}</Typography.Text>;
      },
      // sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: "Created at",
      key: "createdAt",
      dataIndex: ["userFeedback.createdAt"],
      render: (text, record, index) => {
        return <Typography.Text>{formatDateTime(record.userFeedback.createdAt)}</Typography.Text>;
      },
      sorter: (a, b) => (new Date(a.userFeedback.createdAt)).getTime() - (new Date(b.userFeedback.createdAt)).getTime(),
      defaultSortOrder: 'descend',
    },
    /*
    {
      title: "Actions",
      key: "actions",
      width: 150,
      fixed: "right",
      render: (text, record, index) => (
        <div style={{ display: "flex" }}>
          <Tooltip title="View">
            <Button
              type="link"
              onClick={() => {
                handleOpenModal(record);
              }}
            >
              Subscribe
            </Button>
          </Tooltip>
        </div>
      ),
    },*/
  ];

  return (
    <div>
      <Typography.Title level={3}>User Feedback</Typography.Title>
      <Table
        loading={loading}
        bordered
        rowKey="id"
        dataSource={userFeedbacks}
        columns={columns}
        pagination={{ total: userFeedbacks.length, defaultPageSize: 30, showSizeChanger: true }}
        onRow={(record) => ({ onClick: () => openViewModal(record.booking) })}
      />
    </div>
  );
};
