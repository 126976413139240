import React from "react";
import { List } from "antd";

function DescriptionList({ children, ...rest }) {
  return (
    <List itemLayout="horizontal" bordered={false} split={true} grid={{ gutter: 1, column: 1 }} style={{ width: 550 }} {...rest}>
      {children}
    </List>
  );
}

export default DescriptionList;
