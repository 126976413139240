import React, { useEffect, useState } from "react";
// import Signin from "./pages/Signin";
import Main from "./Main";
import Login from "./Login";
import { OverlayProvider } from "./context/OverlayContext";
import { ToastProvider } from "./context/ToastContext";
import { NavigationProvider } from "./context/NavigationContext";
import { ConfigProvider } from "antd";
// import locale_se from 'antd/es/locale/sv_SE';
import locale_en from "antd/lib/locale/en_US"; // i.e. changes "sida" to "page" in pagination
import LoginService from "./services/crud/LoginService";

function App() {
  const [loggedIn, setLoggedIn] = useState(true);

  useEffect(() => {
    refreshSession();
  }, []);

  const refreshSession = () => {
    const loggedIn = LoginService.isLoggedIn();
    setLoggedIn(loggedIn);
  };

  const validateMessages = {
    required: "$NAME is required".replace("NAME", `{name}`),
  };
  console.log(process.env.REACT_APP_API_URL);

  return (
    <ConfigProvider form={{ validateMessages }} locale={locale_en}>
      <NavigationProvider>
        <ToastProvider>
          <OverlayProvider>{loggedIn ? <Main /> : <Login />}</OverlayProvider>
        </ToastProvider>
      </NavigationProvider>
    </ConfigProvider>
  );
}

export default App;
