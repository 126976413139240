import React from "react";
import './InfoBox.scss';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const content = (
    <div className="InfoBox">
      { props.children }
    </div>
  );

  return content;
};
