import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OverlayContext } from "../../../context/OverlayContext";
import { ToastContext } from "../../../context/ToastContext";
import { NavigationContext } from "../../../context/NavigationContext";
import { Table, Typography, Button, Form, List, Tag, Tooltip, Input } from "antd";
import { ArrowLeftOutlined, StopOutlined } from "@ant-design/icons";
import WhiteLabelsService from "../../../services/crud/WhiteLabelsService";
import WhiteLabel from "../../../models/WhiteLabels";
import { WhiteLabelFormGenericForm } from "../../../components/forms/WhiteLabelForm";
import DescriptionDataList from "../../../components/DescriptionDataList";

const WhiteLabelView = () => {
  const { loading, loadMessage } = useContext(ToastContext);
  const { openModal, closeModal } = useContext(OverlayContext);
  const [form] = Form.useForm<WhiteLabel>();
  const { id } = useParams();
  const [whiteLabel, setWhiteLabel] = useState<WhiteLabel>({} as WhiteLabel);

  useEffect(() => {
    if (id) {
      form.resetFields();
      loadMessage("Loading white label item...", () =>
        WhiteLabelsService.getWhiteLabel(id).then((data) => {
          setWhiteLabel(data);
        })
      );
    }
  }, [id, form]);

  const openEditModal = () => {
    form.resetFields();
    openModal({
      title: "Edit White Label",
      content: <WhiteLabelFormGenericForm form={form} id={id} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          loadMessage("Updating White Label...", async () => {
            WhiteLabelsService.editWhiteLabel(id, values);
            closeModal();
          });
        });
      },
    });
  };

  return (
    <div>
      <Typography.Title level={2}>White Label</Typography.Title>
      <DescriptionDataList data={whiteLabel} />
      <Button type="primary" onClick={openEditModal}>
        Edit White Label
      </Button>
    </div>
  );
};

export default WhiteLabelView;
