import React, { useState, useEffect, useContext } from "react";
import { Table, Typography, Form, Button, Input, Tooltip } from "antd";
import AuthorizedService from "../services/crud/AuthorizedService";
import { ToastContext } from "../context/ToastContext";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { OverlayContext } from "../context/OverlayContext";
import DeleteForm from "../components/forms/DeleteForm";
import AuthorizedForm, { AuthorizedFormAdd, AuthorizedFormDelete } from "../components/forms/AuthorizedForm";
import InfoBox from "../components/InfoBox";
import Authorized from "../models/Authorized";

function Authorizeds() {
  let navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate(route);
  };
  const [authorizeds, setAuthorizeds] = useState<Authorized[]>([]);
  const [filter, setFilter] = useState("");
  const { openModal, closeModal } = useContext(OverlayContext);
  const { loading, loadMessage } = useContext(ToastContext);
  const [form] = Form.useForm<Authorized>();
  useEffect(() => {
    loadMessage("Loading authorized emails...", () =>
      AuthorizedService.list().then((res) => {
        res.sort((a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()));
        setAuthorizeds(res);
        return true;
      })
    );
  }, []);

  const openCreateModal = () => {
    openModal({
      title: "Authorize email",
      content: <AuthorizedFormAdd form={form} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          closeModal();
          loadMessage("Adding email", () =>
            AuthorizedService.addAuthorizedAccount(values)
              .then((hero) => {
                setAuthorizeds([hero, ...authorizeds]);
                return "Authorized email has been added";
              })
              .catch((res) => {
                throw Error("Authorized email could not be added");
              })
          );
        });
      },
    });
  };

  const openAddModal = (authorized = null) => {
    openModal({
      title: "Add another account to authorized email",
      content: <AuthorizedFormAdd id={authorized.id} form={form} update={true} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          closeModal();
          loadMessage("Updating authorized...", () =>
            AuthorizedService.editAuthorizedAccount(values)
              .then((authorized) => {
                const i = authorizeds.findIndex((item) => item.id === authorized.id);
                authorizeds[i] = authorized;
                setAuthorizeds(authorizeds);
                return "Authorized has been updated";
              })
              .catch((res) => {
                throw Error("Could not update authorized");
              })
          );
        });
      },
    });
  };

  const openUpdateModal = (authorized = null) => {
    openModal({
      title: "Remove authorization",
      danger: true,
      okText: "Delete",
      width: "40%",
      content: <AuthorizedFormDelete id={authorized.id} form={form} update={false}></AuthorizedFormDelete>,
      onOk: () => {
        form.validateFields().then(async (values) => {
          closeModal();
          loadMessage("Deleted authorized...", () =>
            AuthorizedService.deleteAuthorizedAccount(values)
              .then((authorized) => {
                const copy = authorizeds;
                const i = copy.findIndex((item) => item.id === authorized.id);
                copy[i] = authorized;
                setAuthorizeds([...copy]);
                return "Authorized has been deleted";
              })
              .catch((res) => {
                throw Error("Could not deleted authorized");
              })
          );
        });
      },
    });
  };

  const openDeleteModal = (authorized = null) => {
    openModal({
      title: "Remove authorization",
      danger: true,
      okText: "Delete",
      width: "40%",
      content: (
        <DeleteForm form={form} warningText={`Are you sure you want to remove the authorization of ${authorized.email}?`} easy={true}></DeleteForm>
      ),
      onOk: () => {
        closeModal();
        loadMessage("Deleting authorization...", () =>
          AuthorizedService.deleteAuthorized(authorized.id)
            .then((success) => {
              const i = authorizeds.findIndex((item) => item.id === authorized.id);
              authorizeds.splice(i, 1);
              setAuthorizeds(authorizeds);
              return "Authorized deleted";
            })
            .catch((res) => {
              console.log(res);
              throw Error("Could not delete email authorization");
            })
        );
      },
    });
  };

  const filterTable = (data) => data.filter((item) => new RegExp(filter.toLowerCase()).test(item.email.toLowerCase()));
  const columns: any = [
    {
      title: "Email",
      key: "email",
      render: (text, record, index) => `${record.email}`,
      sorter: (a, b) => `${a.email}`.toLowerCase().localeCompare(`${a.email}`.toLowerCase()),
    },
    {
      title: (text, record, index) => {
        return (
          <Tooltip title="Visar konton separerad med komma tecken. Exempel: Konto1, Konto2">
            <div>{"Konton (som skapad användare kommer få access till)"}</div>
          </Tooltip>
        );
      },
      key: "accounts",
      render: (text, record, index) => <div>{record?.accounts?.map((account) => account.name).join(", ")}</div>,
    },
    {
      title: "Actions",
      width: 150,
      render: (text, record, index) => (
        <div style={{ display: "flex" }}>
          <Tooltip title="Lägg till ett konto till den här epost-adressen">
            <Button type="link" size="small" onClick={() => openAddModal(record)}>
              Add
            </Button>
          </Tooltip>

          <Tooltip title="Ta bort ett konto från den här epost-adressen">
            <Button disabled={record?.accounts?.length < 1} type="link" size="small" onClick={() => openUpdateModal(record)}>
              Update
            </Button>
          </Tooltip>

          <Tooltip title="Ta bort epost-adressen från listan">
            <Button danger type="link" size="small" onClick={() => openDeleteModal(record)}>
              Delete
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Button size="small" icon={<ArrowLeftOutlined />} type="link" style={{ marginBottom: "1em" }} onClick={() => gotoPage(-1)}>
        Go back to users
      </Button>
      <InfoBox>
        <p>Här lägger du till epost-adresser som ska få lov att registrera en EC2B-användare och till vilken konto de kommer att knytas till.</p>
        <p>Det har ingen effekt om epost-adresser raderas från denna lista efter att någon har registrerat en användare med epost-adressen.</p>
      </InfoBox>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <Input.Search size="middle" placeholder="Search authorized..." style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} />
        <Button type="primary" size="middle" onClick={() => openCreateModal()}>
          Authorize new email
        </Button>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={filterTable(authorizeds)}
        pagination={{ total: authorizeds.length, defaultPageSize: 30, showSizeChanger: true }}
        loading={loading}
      />
      <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>
        Total of {authorizeds.length} authorized emails
      </Typography.Text>
    </div>
  );
}

export default Authorizeds;
