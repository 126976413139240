import { atom } from "recoil";
import Account from "../models/Account";
import Hub from "../models/Hub";
import Plan from "../models/Plan";
import Subscription from "../models/Subscription";
import UserFeedback from "../models/UserFeedback";

export const plansState = atom<Plan[]>({
  key: "plans", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

export const subscriptionsState = atom<Subscription[]>({
  key: "subscriptions", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

export const hubsState = atom<Hub[]>({
  key: "hubs",
  default: [],
});

export const accountState = atom<Account>({
  key: "account",
  default: {} as Account,
});
