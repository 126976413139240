import React, { useState, useEffect, useContext } from "react";
import { Table, Typography, Button, Input, Form, Tooltip } from "antd";
import ProductService from "../../../services/crud/ProductService";
import { OverlayContext } from "../../../context/OverlayContext";
import { ToastContext } from "../../../context/ToastContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ProductForm from "../../../components/forms/ProductForm";
import DeleteForm from "../../../components/forms/DeleteForm";
import InfoBox from "../../../components/InfoBox";
import { GenericProductsTable } from "../../../components/tables/ProductsTable";

/* text-overflow: ellipsis; instead of regexp ... */

const ProductsTable = () => {
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState("");
  const [tspFilter, setTspFilter] = useState([]);
  // const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const { openModal, closeModal } = useContext(OverlayContext);
  const { loading, loadMessage, showMessage } = useContext(ToastContext);
  const [form] = Form.useForm();

  useEffect(() => {
    loadMessage("Loading products...", () =>
      ProductService.list().then((products) => {
        setProducts(products);
        const tspFilterArray = [];
        for (let i in products) {
          tspFilterArray.push({ text: products[i].tsp.name, value: products[i].tsp.name });
        }
        setTspFilter(tspFilterArray.filter((value, index, self) => self.map((x) => x.text).indexOf(value.text) === index));
        return true;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const openCreateModal = () => {
  //   openModal({
  //     title: "Add new product",
  //     content: <ProductForm form={form} />,
  //     onOk: () => {
  //       form
  //         .validateFields()
  //         .then(async (values) => {
  //           closeModal();
  //           loadMessage("Creating product...", () => ProductService.addProduct(values).then(product => {
  //             setProducts([product, ...products]);
  //             return "Product added";
  //           }).catch(res => {
  //             console.log(res);
  //             throw Error("Could not create product");
  //           }));
  //         })
  //     },
  //   });
  // };

  const openUpdateModal = (product = null) => {
    openModal({
      title: "Update product",
      content: <ProductForm id={product.id} form={form} update={true} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          values = { ...values, pricePerPcVatFraction: values.pricePerPcVatFraction / 100 };
          closeModal();
          loadMessage("Updating product...", () =>
            ProductService.editProduct(product.id, values)
              .then((product) => {
                const i = products.findIndex((item) => item.id === product.id);
                products[i] = product;
                setProducts(products);
                return "Product updated";
              })
              .catch((res) => {
                console.log(res);
                throw Error("Could not update product");
              })
          );
        });
      },
    });
  };

  const openDeleteModal = (product = null) => {
    openModal({
      title: "Delete product",
      danger: true,
      okText: "Delete",
      width: "40%",
      content: (
        <DeleteForm
          form={form}
          warningText={"WARNING! Deleting products that are connected to an active plan will affect all users subscribed to that plan."}
          item="product"
          itemName={product.name}
          assocItemName={product.tsp.name}
          // description="If you want to delete... (replaced item, itemName and assocItemName)"
        ></DeleteForm>
      ),
      onOk: () => {
        closeModal();
        form.validateFields().then(async (values) => {
          if (values.itemNameForDeletion.replace(/^\s+|\s+$/g, "") === product.name.replace(/^\s+|\s+$/g, "")) {
            loadMessage("Deleting product...", () =>
              ProductService.deleteProduct(product.id)
                .then((success) => {
                  const i = products.findIndex((item) => item.id === product.id);
                  products.splice(i, 1);
                  setProducts(products);
                  return "Product deleted";
                })
                .catch((res) => {
                  console.log(res);
                  throw Error("Could not delete product");
                })
            );
          } else {
            showMessage("Name does not match. Plan not deleted.", "warning");
          }
        });
      },
    });
  };

  const columns: any = [
    {
      title: "TSP",
      dataIndex: ["tsp", "name"],
      key: "tspName",
      sorter: (a, b) => a.tsp.name.toLowerCase().localeCompare(b.tsp.name.toLowerCase()),
      filters: tspFilter,
      onFilter: (value, record) => record.tsp.name.indexOf(value) === 0,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record, index) => {
        return !record.physical ? (
          record.name
        ) : (
          <Tooltip title="This product is a 'user item'">
            <Typography.Text>
              {record.name} <ExclamationCircleOutlined />
            </Typography.Text>
          </Tooltip>
        );
      },
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text, record, index) => {
        return (
          <Tooltip title={text} placement="topLeft">
            <Typography.Text style={{ display: "block", width: 200, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
              {text}
            </Typography.Text>
          </Tooltip>
        );
      },
      sorter: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (text, record, index) => {
        return `${record.createdAtAsDate}`;
      },
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: "Actions",
      width: 150,
      render: (text, record, index) => (
        <div style={{ display: "flex" }}>
          <Button type="link" size="small" onClick={() => openUpdateModal(record)}>
            Edit
          </Button>
          <Button type="link" size="small" onClick={() => openDeleteModal(record)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table columns={columns}></Table>;
      <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>Total of {products.length} products</Typography.Text>;
    </div>
  );
};

function Products() {
  return (
    <div>
      <InfoBox>
        <p>Här visas alla produkter.</p>
      </InfoBox>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        {/* <Button type="primary" size="middle" onClick={() => openCreateModal()}>
          Create new product
        </Button> */}
      </div>
      <GenericProductsTable search includeColumns={["id", "name", "description", "productCode", "tsp"]} />
    </div>
  );
}

export default Products;
