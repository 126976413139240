import BaseModel from "../models/BaseModel";

/**
 * Builds a map from list of models to model via id
 * @param list
 * @returns object that maps from list[i].id to list[i] object
 */
export function listToMap(list : Array<BaseModel>): any {
  const map = {};
  list.forEach((item) => {
    if (item.id !== null && item.id !== undefined) {
      map[item.id] = item;
    }
  });
  return map;
}