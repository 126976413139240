import React, { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../context/ToastContext";
import { Form, InputNumber, Input, Select, Spin, Tooltip, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ProductService from "../../services/crud/ProductService";
import Product from "../../models/Product";
import TspService from "../../services/crud/TspService";
import Tsp from "../../models/Tsp";
import PlanService from "../../services/crud/PlanService";
import PlanProductService, { PlanProductDataCreate, PlanProductUpdate } from "../../services/crud/PlanProductService";
import Plan from "../../models/Plan";
import PlanProduct, { IPlanProductsFormData, PlanProductsResponse } from "../../models/PlanProduct";
/**
 * This component is used for adding new products to a plan
 * @param props
 * @returns
 */

export default function PlanProductForm(props) {
  const id = props.id;
  const form = props.form;
  const [plans, setPlans] = useState<Plan[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  // const update: boolean = props.update ?? false;
  const { loading, loadMessage } = useContext(ToastContext);

  useEffect(() => {
    loadMessage("... loading data", async () => {
      const plans = await PlanService.list();
      const products = await ProductService.list();
      setPlans(plans);
      setProducts(products);
    });
  }, [form, id]);
  const planProductTemplate: PlanProductDataCreate = {
    planId: undefined,
    productId: undefined,
    name: "",
    topUpYield: 0,
    pricePerPcInclVat: 0,
    pricePerPcVatFraction: 0,
    pricePerExcessPcInclVat: 0,
    pricePerExcessPcVatFraction: 0,
  };

  const content = (
    <Form initialValues={planProductTemplate} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item label="Plan" name="planId" rules={[{ required: true }]}>
        <Select
          placeholder="Please select a plan"
          options={plans.map((plan: Plan) => {
            return { label: plan.name, value: plan.id };
          })}
        ></Select>
      </Form.Item>
      <Form.Item label="Product" name="productId" rules={[{ required: true }]}>
        <Select
          placeholder="Please select a product"
          options={products.map((product: Product) => {
            return { label: product.name, value: product.id };
          })}
        ></Select>
      </Form.Item>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input placeholder="Please enter a name" />
      </Form.Item>
      <Form.Item label="Top up yield" name="topUpYield" rules={[{ required: true }]}>
        <InputNumber disabled type="number"></InputNumber>
      </Form.Item>
      <Form.Item label="Price per hour">
        <Input.Group compact>
          <Form.Item name="pricePerPcInclVat" rules={[{ required: true }]}>
            <InputNumber type="number" min={0} addonAfter={<Typography.Text>,</Typography.Text>} />
          </Form.Item>
          <Form.Item name="pricePerPcVatFraction" rules={[{ required: true }]}>
            <InputNumber type="number" min={0} addonAfter={<Typography.Text>SEK</Typography.Text>} />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item label="Price per excess hour">
        <Input.Group compact>
          <Form.Item name="pricePerExcessPcInclVat"  rules={[{ required: true }]}>
            <InputNumber type="number" min={0} addonAfter={<Typography.Text>,</Typography.Text>} />
          </Form.Item>
          <Form.Item name="pricePerExcessPcVatFraction" rules={[{ required: true }]}>
            <InputNumber type="number" min={0} addonAfter={<Typography.Text>SEK</Typography.Text>} />
          </Form.Item>
        </Input.Group>
      </Form.Item>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
}

/**
 * This component is used for adding updating a product for a plan
 * @param props
 * @returns
 */

export function PlanProductFormEdit(props) {
  const id = props.id;
  const form = props.form;
  const [planProduct, setPlanProduct] = useState<PlanProductsResponse>();
  // const update: boolean = props.update ?? false;
  const { loading, loadMessage } = useContext(ToastContext);
  const [products, setProducts] = useState<Product[]>([]);
  useEffect(() => {
    loadMessage("... loading data", async () => {
      const planProduct = await PlanProductService.getPlanProduct(id);
      const products = await ProductService.list();
      setPlanProduct(planProduct);
      setProducts(products);
    });
  }, [form, id]);

  const content = (
    <Form initialValues={planProduct} form={form}>
      <Form.Item label="Id" name="id" rules={[{ required: true }]}>
        <Input disabled></Input>
      </Form.Item>
      <Form.Item label="Product" name="productId" rules={[{ required: true }]}>
        <Select
          placeholder="Please select a product"
          options={products.map((product: Product) => {
            return { label: product.name, value: product.id };
          })}
        ></Select>
      </Form.Item>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input placeholder="Please enter a name" />
      </Form.Item>
      <Form.Item label="Top up yield" name="topUpYield" rules={[{ required: true }]}>
        <InputNumber disabled type="number"></InputNumber>
      </Form.Item>
      <Form.Item label="Price per hour">
        <Input.Group compact>
          <Form.Item name="pricePerPcInclVat" rules={[{ required: true }]}>
            <InputNumber type="number" min="0" addonAfter={<Typography.Text>,</Typography.Text>} />
          </Form.Item>
          <Form.Item name="pricePerPcVatFraction" rules={[{ required: true }]}>
            <InputNumber type="number" min="0" addonAfter={<Typography.Text>SEK</Typography.Text>} />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item label="Price per excess hour">
        <Input.Group compact>
          <Form.Item name="pricePerExcessPcInclVat" rules={[{ required: true }]}>
            <InputNumber type="number" min="0" addonAfter={<Typography.Text>,</Typography.Text>} />
          </Form.Item>
          <Form.Item name="pricePerExcessPcVatFraction" rules={[{ required: true }]}>
            <InputNumber type="number" min="0" addonAfter={<Typography.Text>SEK</Typography.Text>} />
          </Form.Item>
        </Input.Group>
      </Form.Item>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
}
