import React, { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../context/ToastContext";
import { Form, Input, InputNumber, Select, Spin, Tooltip } from "antd";
import { LoadingOutlined, QuestionCircleFilled } from "@ant-design/icons";
import AccountService from "../../services/crud/AccountService";
import Account from "../../models/Account";
import { useWatch } from "antd/lib/form/Form";

const AccountForm = (props) => {
  const id = props.id;
  const form = props.form;
  const [account, setAccount] = useState<Account | {}>();
  const { loading, loadMessage } = useContext(ToastContext);
  const type = useWatch(["type"], form);
  useEffect(() => {
    form.resetFields();
    if (id) loadMessage("Loading account...", () => AccountService.getAccount(id).then((data) => setAccount(data.serialize())));
  }, [id, form]);

  const content = (
    <Form initialValues={account} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="type" label="Type" rules={[{ required: true }]}>
        <Select
          options={[
            {
              value: "private",
              label: "Private",
            },
            {
              value: "real_estate",
              label: "Real Estate",
            },
            {
              value: "company",
              label: "Company",
            },
          ]}
        ></Select>
      </Form.Item>
      {type === "private" && (
        <Form.Item name="balance" label="Balance" rules={[{ required: true }]}>
          <InputNumber></InputNumber>
        </Form.Item>
      )}
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};

const CompanyCreate = (props) => {
  const form = props.form;
  const initialAccount: Partial<Account> = { type: "company", balance: 0 };
  const { loading, loadMessage } = useContext(ToastContext);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const content = (
    <Form initialValues={initialAccount} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="type" label="Type" rules={[{ required: true }]}>
        <Select
          options={[
            {
              value: "company",
              label: "Company",
            },
          ]}
        ></Select>
      </Form.Item>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};

const RealEstateCreate = (props) => {
  const form = props.form;
  const initialAccount: Partial<Account> = { type: Account.types.REAL_ESTATE, balance: 0 };
  const { loading, loadMessage } = useContext(ToastContext);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const content = (
    <Form initialValues={initialAccount} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="type" label="Type" rules={[{ required: true }]}>
        <Select
          options={[
            {
              value: Account.types.REAL_ESTATE,
              label: Account.types.REAL_ESTATE,
            },
          ]}
        ></Select>
      </Form.Item>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};

export const AccountForms = {
  CompanyCreate,
  RealEstateCreate,
};

export default AccountForm;
