import React, { useState, useEffect, useContext } from "react";
import { Table, Typography, Input, Tooltip, Button } from "antd";
import UserService from "../../services/crud/UserService";
import { ToastContext } from "../../context/ToastContext";
import { useNavigate } from "react-router-dom";
import User from "../../models/User";
import { TableProps } from "./types";
import UserForm from "../forms/UserForm";
import GenericItemsTable from "./GenericItemsTable";
import Account from "../../models/Account";
const { TextArea } = Input;
/**
 * This table is used to represent a table in database.
 * @param {props} - dataFilter to choose which data to keep. ColumnFilter which columns to display. Default is no filter.
 * @returns table populated with bookings data
 */
const UsersTable = ({
  columnFilter = (col) => {
    return true;
  },
  dataFilter = (data: User) => {
    return true;
  },
  dataLoader,
}: TableProps<User>) => {
  /// A function (booking) => boolean, that is passed to filter() function
  /// to filter out which bookings to keep.
  // Similarly an optional function to filter which columns to keep
  let navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate("/users/" + route);
  };
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState("");
  const { loading, loadMessage } = useContext(ToastContext);
  useEffect(() => {
    loadMessage("Loading users...", () =>
      UserService.list().then((users) => {
        setUsers(users.filter(dataFilter));
        return true;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterTable = (data) => data.filter((item) => new RegExp(filter.toLowerCase()).test(item.toString().toLowerCase()));

  const columns = [
    {
      title: "User",
      dataIndex: "username",
      render: (text, record, index) => (
        <Tooltip title="Go to users profile">
          <Typography.Text style={{ cursor: "pointer" }} onClick={() => gotoPage(`${record.id}`)}>
            {record.username}
          </Typography.Text>
        </Tooltip>
      ),
      sorter: (a, b) => a.username.toLowerCase().localeCompare(b.username.toLowerCase()),
    },
    {
      title: "Name",
      key: "name",
      render: (text, record, index) => `${record.firstName} ${record.lastName}`,
      sorter: (a, b) => `${a.firstName} ${a.lastName}`.toLowerCase().localeCompare(`${b.firstName} ${a.lastName}`.toLowerCase()),
    },
    {
      title: "Saldo",
      key: "privateAccount",
      render: (text, record, index) => `${(record?.privateAccount?.balance??null) !== null ? record?.privateAccount?.balance + " kr": "Missing private account"}` ,
      sorter: (a, b) => `${a?.privateAccount?.balance}`.toLowerCase().localeCompare(`${b?.privateAccount?.balance}`.toLowerCase()),
    },
    {
      title: "Tillhör",
      key: "tillhör",
      render: (text, record:User, index) =>{
        const accounts = record?.notPrivateAccounts;
        return (<div style={{whiteSpace:"pre-wrap"}}>
          {`${accounts?.map((account) =>  Account.typeEmoji(account.type)  + ' ' + account?.name).join("\n")}`}
        </div>)
      } ,
      // sorter: (a, b) => `${a?.privateAccount?.balance}`.toLowerCase().localeCompare(`${b?.privateAccount?.balance}`.toLowerCase()),
    },
    {
      title: "Last login",
      dataIndex: "lastLoginAt",
      render: (text, record, index) => {
        return `${record.lastLoginAtAsDate}`;
      },
      sorter: (a, b) => {
        // Sort down the nulls when sorting DESC
        if (a.lastLoginAt == null && b.lastLoginAt == null) return 0;
        if (a.lastLoginAt == null && b.lastLoginAt != null) return -1;
        if (a.lastLoginAt != null && b.lastLoginAt == null) return 1;
        return a.lastLoginAt.localeCompare(b.lastLoginAt);
      },

    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      render: (text, record, index) => {
        return `${record.createdAtAsDate}`;
      },
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record, index) => {
        return `${record.isRegistered ? "Registered" : "Not registered"}`;
      },
      sorter: (a, b) => a - b,
    },
    // Filters
  ];
  const filterColumns = (columns = []) => columns.filter(columnFilter);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <Input.Search size="middle" placeholder="Search users..." style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} />
        <Button type="primary" size="middle" onClick={() => gotoPage("pre-granted")}>
          Edit authorized emails
        </Button>
      </div>
      <Table
        rowKey="id"
        columns={filterColumns(columns)}
        dataSource={filterTable(users)}
        pagination={{ total: users.length, defaultPageSize: 30, showSizeChanger: true }}
        loading={loading}
      />
      <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>Total of {users.length} users</Typography.Text>
    </div>
  );
};

export const GenericUsersTable = ({ ...rest }: TableProps<User>) => {
  return <GenericItemsTable model={User} form={UserForm} service={UserService} {...rest}></GenericItemsTable>;
};

export default UsersTable;
