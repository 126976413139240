import React, { createContext, useState } from "react";
import { message } from "antd";
import { AxiosError } from "axios";
/**
 * load message is wrapped in a function that catches all errors.
 * It uses message api from antd to display messages.
 */
export const ToastContext = createContext<{
  loading: boolean;
  loadMessage: (loadMsg?: string, loadWhile?: () => Promise<any>) => Promise<Boolean>;
  showMessage: (msg?: string, type?: string) => void;
}>({
  loading: false,
  // setLoading: (l): any => l,
  loadMessage: async (loadMsg = "Loading...", loadWhile = async (): Promise<any> => {}) => true,
  showMessage: (msg = "Done", type = "success") => {},
});

function ToastProvider({ children }) {
  const [toastKey, setToastKey] = useState(1);
  const [loading, setLoading] = useState(false);
  const loadMessage = async (loadMsg = "Loading...", loadWhile = async (): Promise<any> => {}) => {
    setToastKey(toastKey + 1);
    setLoading(true);
    message.loading({ content: loadMsg, key: `toast-${toastKey}`, duration: 3 });
    return loadWhile()
      .then((successMessage) => {
        // console.log(`Success: ${successMessage}`);
        if (typeof successMessage === "boolean") {
          message.destroy();
        } else if (typeof successMessage === "undefined") {
          message.destroy();
        } else {
          message.success({ content: successMessage, key: `toast-${toastKey}`, duration: 2 });
        }
        setLoading(false);
        return true;
      })
      .catch((error) => {
        console.log(`Fail: ${error.message}`, error);
        if (error instanceof AxiosError) {
          // Display the error from server
          if(error?.response?.data?.error){
            message.error({ content: error.response.data.error, key: `toast-${toastKey}`, duration: 2 });
          }
          else { 
            message.error({ content: error.message, key: `toast-${toastKey}`, duration: 2 });
          }
        } else {
          message.error({ content: error.message, key: `toast-${toastKey}`, duration: 2 });
        }
        setLoading(false);
        return false;
      });
  };

  const showMessage = (msg = "Done", type = "success") => {
    switch (type) {
      case "success":
        message.success({ content: msg, duration: 2 });
        break;
      case "info":
        message.info({ content: msg, duration: 2 });
        break;
      case "error":
        message.error({ content: msg, duration: 2 });
        break;
      case "warning":
        message.warning({ content: msg, duration: 2 });
        break;
    }
  };

  return <ToastContext.Provider value={{ loading, loadMessage, showMessage }}>{children}</ToastContext.Provider>;
}

export { ToastProvider };
