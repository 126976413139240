import React, { useState, useEffect, useContext } from "react";
import { Table, Typography, Button, Input, Form, Tag, Tooltip } from "antd";
import SubscriptionService from "../../../services/crud/SubscriptionService";
import { OverlayContext } from "../../../context/OverlayContext";
import { ToastContext } from "../../../context/ToastContext";
import SubscriptionForm from "../../../components/forms/SubscriptionForm";
import {
  // CheckOutlined,
  StopOutlined,
} from "@ant-design/icons";
import DeleteForm from "../../../components/forms/DeleteForm";
import ViewSubscriptionHistory from "../../../components/ViewSubscriptionHistory";
import InfoBox from "../../../components/InfoBox";
import Subscription from "../../../models/Subscription";
function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [filter, setFilter] = useState("");
  const { openModal, closeModal } = useContext(OverlayContext);
  const { loading, loadMessage, showMessage } = useContext(ToastContext);
  const [form] = Form.useForm();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState(undefined), []);

  useEffect(() => {
    loadMessage("Loading subscriptions...", () =>
      SubscriptionService.list().then((subscriptions) => {
        setSubscriptions(subscriptions);
        return true;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateModal = () => {
    openModal({
      title: "Add new subscription",
      content: <SubscriptionForm form={form} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          if (values.date) {
            values.product_request = {
              pickup: values.pickup,
              date: values.date,
              amount: parseInt(values.amount),
            };
          }
          closeModal();
          console.log(values);
          loadMessage("Subscribing user to plan...", () =>
            SubscriptionService.addSubscription(values)
              .then((subscription) => {
                setSubscriptions([subscription, ...subscriptions]);
                return "Subscription added";
              })
              .catch((res) => {
                if (res?.response?.data?.error === "user is missing a credit card") {
                  throw Error(res.response.data.error);
                }
                throw Error("Could not add subscription");
              })
          );
        });
      },
    });
  };

  const filterTable = (data) => data.filter((item) => new RegExp(filter.toLowerCase()).test(item.toString().toLowerCase()));

  const columns: any = [
    {
      title: "id",
      dataIndex: ["id"],
      render: (text, record, index) => text,
      sorter: (a, b) => b - a,
    },
    {
      title: "Account",
      dataIndex: ["account", "name"],
      render: (text, record, index) => record?.account?.name ?? <em>Account deleted</em>,
      sorter: (a, b) => a?.account?.name.toLowerCase().localeCompare(b.account?.name.toLowerCase()),
    },
    {
      title: "Transport Service Provider (TSP)",
      dataIndex: ["plan", "products", [0], "tsp", "name"],
      render: (text, record, index) => {
        if (record.plan.products) {
          return record.plan.products.map(({ id, tsp }) => {
            return (
              <Tooltip key={id} title={<Typography.Paragraph>{tsp.name}</Typography.Paragraph>}>
                <Tag>{tsp.name}</Tag>
              </Tooltip>
            );
          });
        } else {
          return null;
        }
      },
      sorter: (a, b) => b.plan?.products?.length - a.plan?.products?.length,
    },
    {
      title: "Plan",
      dataIndex: ["plan", "name"],
      render: (text, record, index) => text ?? <em>Plan deleted</em>,
      sorter: (a, b) => a.plan?.name.toLowerCase().localeCompare(b.plan?.name.toLowerCase()),
    },
    // {
    //   title: "Products",
    //   dataIndex: ["plan", "products", [0], "name"],
    //   render: (text, record, index) => {
    //     if (record.plan.products) {
    //       return record.plan.products.map((product) => {
    //         return (
    //           <Tooltip
    //             key={product.id}
    //             title={
    //               <Typography.Paragraph>
    //                 Price:<br></br>
    //                 {product.pricePerPcInclVat} SEK for {product.topUpYield} products, then <br></br>
    //                 {product.pricePerExcessPcInclVat} SEK for excessive products. <br></br>
    //                 (prices incl VAT at {product.pricePerPcVatFraction * 100}%.)
    //               </Typography.Paragraph>
    //             }
    //           >
    //             <Tag>
    //               {product.topUpYield} x {text}
    //             </Tag>
    //           </Tooltip>
    //         );
    //       });
    //     } else {
    //       return null;
    //     }
    //   },
    //   sorter: (a, b) => a.plan.products[0].name.toLowerCase().localeCompare(b.plan.products[0].name.toLowerCase()),
    // },
    {
      title: "Status",
      dataIndex: ["paused"],
      render: (text, record, index) => {
        if (record.deletedAt) {
          return (
            <Tag icon={<StopOutlined />} color="red">
              Removed
            </Tag>
          );
        }
        return !record.activated ? (
          <Tag color="grey">Inactivated</Tag>
        ) : record.paused ? (
          <Tag color="orange">Paused</Tag>
        ) : (
          <Tag color="blue">Active</Tag>
        );
        // : <Tag icon={<CheckOutlined />} color="green">Not paused</Tag>
      },
      sorter: (a, b) => (a.activated ? 1 : b.activated ? -1 : a.paused - b.paused),
    },
    // {
    //   title: "Pauses",
    //   key: "paused",
    //   render: (text, record, index) => `${record.usedPauses} / ${record.plan.pauseMaxCountPerYear}`,
    //   sorter: (a, b) => a.paused - b.paused,
    // },
    {
      title: "Subscribed at",
      dataIndex: "subscribedAtAsDate",
      render: (text, record, index) => {
        return `${record.subscribedAtAsDate}`;
      },
      sorter: (a, b) => a.subscribedAt.localeCompare(b.subscribedAt),
    },
    // {
    //   title: "Actions",
    //   width: 150,
    //   render: (text, record, index) => (
    //     <div style={{ display: "flex" }}>
    //       <Button type="link" size="small" onClick={() => openUpdateModal(record)}>
    //         Edit
    //       </Button>
    //       <Button type="link" size="small" onClick={() => openViewHistoryModal(record)}>
    //         History
    //       </Button>
    //       <Button type="link" size="small" onClick={() => openDeleteModal(record)}>
    //         Unsubscribe
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div>
      <InfoBox>
        <p>Lista på alla prenumerationer</p>
      </InfoBox>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <Tooltip title="Search on account name">
          <Input.Search
            size="middle"
            placeholder="Search account name subscriptions..."
            style={{ width: 200 }}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Tooltip>
        <Button hidden disabled type="primary" size="middle" onClick={() => openCreateModal()}>
          Create new subscription
        </Button>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={filterTable(subscriptions)}
        pagination={{ total: subscriptions.length, defaultPageSize: 30, showSizeChanger: true }}
        loading={loading}
      />
      <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>
        Total of {subscriptions.length} subscriptions
      </Typography.Text>
    </div>
  );
}

export default Subscriptions;
