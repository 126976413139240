import { useSetRecoilState } from "recoil";
import { hubsState } from "./CompanyAccountsView";
import { ToastContext } from "../../../context/ToastContext";
import { OverlayContext } from "../../../context/OverlayContext";
import { useContext } from "react";
import AccountService from "../../../services/crud/AccountService";
import React from "react";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { EntitySelector } from "../../../components/forms/selects/EntitySelector";

const AddHubButton = ({ title = "Add hub", accountId, onSuccess = () => {} }: {
  title?: String,
  accountId: number,
  onSuccess?: Function,
}) => {
    const setHubs = useSetRecoilState(hubsState);
    const [form] = useForm<{ accountId: string | number; hubId: number }>();
    const { loading, loadMessage } = useContext(ToastContext);
    const { openModal, closeModal } = useContext(OverlayContext);
    const initialData = { accountId };

    const openCreateModal = async () => {
      const availableHubs = await AccountService.listAvailableHubs(accountId);
      form.resetFields();
      openModal({
        title,
        content: (
          <Form form={form} initialValues={initialData}>
            <Form.Item name="accountId" label="Account Id" required>
              <Input type="text" disabled></Input>
            </Form.Item>
            <Form.Item name="hubId" label="Hub" required>
              <EntitySelector.Data data={availableHubs}></EntitySelector.Data>
            </Form.Item>
          </Form>
        ),
        onOk: () => {
          form.validateFields().then(async (values) => {
            loadMessage("Adding", async () => {
              AccountService.addHub(values.accountId, values.hubId)
                .then(() => {
                  console.log(availableHubs);
                  setHubs((prev) => [availableHubs.find((hub) => hub.id === values.hubId), ...prev]);
                })
                .then(() => onSuccess())
                .then(() => {
                  return "Added hub to account";
                });
            });
            closeModal();
          });
        },
      });
    };
    return (
      <Button disabled={loading} type="primary" onClick={openCreateModal} style={{marginBottom:'24px'}}>
        {title}
      </Button>
    );
  };

  export default AddHubButton;