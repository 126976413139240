/**
 * Gives a user permission to an account
 */

import React, { useEffect, useState } from "react";
import Subform from "../Global/Subform";
import { Checkbox, Form, FormInstance, Input } from "antd";
import { EntitySelector } from "./selects/EntitySelector";

interface AccountUserFormData {
  userId: number;
  accountId: number;
  admin: boolean;
}

type Props = { id?; form: FormInstance };

function UserAccountForm({ form }: Props) {
  const [accountUser, setAccountUser] = useState<AccountUserFormData>();

  const content = (
    <Form initialValues={accountUser} form={form}>
      <Subform>
        <Form.Item name="userId" label="user" required>
          <EntitySelector.Users></EntitySelector.Users>
        </Form.Item>
        <Form.Item name="accountId" label="account" required>
          <EntitySelector.Accounts></EntitySelector.Accounts>
        </Form.Item>
        <Form.Item name="admin" label="admin" required>
          <Checkbox defaultChecked={false}></Checkbox>
        </Form.Item>
      </Subform>
    </Form>
  );

  return content;
}

function UserEmailAccountForm({ id, form }: Props) {
  const content = (
    <Form initialValues={{ id, emails: "" }} form={form}>
      <Subform>
        <Form.Item name="id" label="CompanyId" required>
          <Input />
        </Form.Item>
        <Form.Item name="emails" label="Emails seperated with comma" required>
          <Input.TextArea />
        </Form.Item>
      </Subform>
    </Form>
  );

  return content;
}

export function EmployeeForm({ company, form }: any) {
  useEffect(() => {
    form.resetFields();
  }, [form]);
  const content = (
    <Form initialValues={{ id: company?.id, name: company?.name }} form={form}>
      <Form.Item hidden name="id" label="id" required>
        <Input disabled />
      </Form.Item>
      <Form.Item name="name" label="Company" required>
        <Input disabled />
      </Form.Item>
      <Form.Item name="email" label="Email" required>
        <Input />
      </Form.Item>
    </Form>
  );

  return content;
}

export default UserAccountForm;
