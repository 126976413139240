import { Table } from "antd";
import WhiteLabel from "../../models/WhiteLabels";
import WhiteLabelsService from "../../services/crud/WhiteLabelsService";
import { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../context/ToastContext";
import { TableProps } from "./types";
import Booking from "../../models/Booking";
import GenericItemsTable from "./GenericItemsTable";
import { WhiteLabelFormGenericForm } from "../forms/WhiteLabelForm";

const useService = (fn) => {
  const { loading, loadMessage } = useContext(ToastContext);
  const [data, setData] = useState<WhiteLabel[]>([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    loadMessage("Loading...", () =>
      fn()
        .then((res) => {
          setData(res);
          setError(null);
        })
        .catch(() => {
          setError(error);
          setData([]);
        })
    );
  }, []);

  return { data, error, loading };
};

export default function WhiteLabelsTable() {
  const { data, error, loading } = useService(() => {
    return WhiteLabelsService.list();
  });
  console.log(data);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Header BG Color",
      dataIndex: "headerBgColor",
      key: "headerBgColor",
    },
    {
      title: "Header FG Color",
      dataIndex: "headerFgColor",
      key: "headerFgColor",
    },
    {
      title: "Tabs BG Color",
      dataIndex: "tabsBgColor",
      key: "tabsBgColor",
    },
    {
      title: "Tabs Icon Color",
      dataIndex: "tabsIconColor",
      key: "tabsIconColor",
    },
    {
      title: "Tabs Text Color",
      dataIndex: "tabsTextColor",
      key: "tabsTextColor",
    },
    {
      title: "Tabs Selected Icon Color",
      dataIndex: "tabsSelectedIconColor",
      key: "tabsSelectedIconColor",
    },
    {
      title: "Tabs Selected Text Color",
      dataIndex: "tabsSelectedTextColor",
      key: "tabsSelectedTextColor",
    },
  ];

  return <Table loading={loading} dataSource={data} columns={columns} />;
}

export const GenericWhiteLabelsTable = ({ ...rest }: TableProps<Booking>) => {
  return (
    <GenericItemsTable model={WhiteLabel} form={WhiteLabelFormGenericForm} formCreate={WhiteLabelFormGenericForm} formEdit={WhiteLabelFormGenericForm} service={WhiteLabelsService} {...rest}></GenericItemsTable>
  );
};