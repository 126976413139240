import HttpClient from "../../clients/HttpClient";

export enum PaymentSheetRowType {
  RETURN_PAYMENT = "return_payment",
  INITIAL_PAYMENT = "initial_payment"
}

class PaymentSheetRow {
    booking_id: number
    processor_reference: string
    type: PaymentSheetRowType
}

export class PaymentSheetDTO  {
    payment_sheet: PaymentSheetRow[]
}

export interface AccountsKpiRequest {
  reportName: string;
  accountIds: number[];
}

export interface AccountsKpiReport {
  file: Blob;
  filename: string;
  mimeType: string;
}

export default class ReportService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async getPaymentExcelSheet(payload:{startDate:Date, endDate:Date, tspId:number}): Promise<PaymentSheetDTO> {
    return this.httpClient.post(`/reports/get_payment_sheet`,payload).then((res) => {
      return res.data;
    });
  }

  /**
   * Get Accounts KPI report which will be delivered as an Excel file.
   */
  public static async getAccountsKpiReport(payload: AccountsKpiRequest): Promise<AccountsKpiReport> {
    const res = await this.httpClient.post(`/reports/get_accounts_kpi_report`, payload, true);
    const report: AccountsKpiReport = {
      file: new Blob([res.data], { type: res.headers['content-type'] }),
      filename: res.headers['content-disposition']?.split('filename=')[1]?.replaceAll('"', '') ?? 'KPI report.xlsx',
      mimeType: res.headers['content-type']?.split(';')[0] ?? '',
    };
    return report;
  }
}
