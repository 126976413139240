
import BaseModel from "./BaseModel";

class UserFeedback extends BaseModel {
  id: number;
  bookingsExternalId: number;
  rating: number; // 1 to 5
  feedbackText?: string|null; // optional user feedback
  freshdeskTicketId?: number|null;
  createdAt: Date|null;

  constructor(data) {
    super(data, []);
    this.id = data.id;
    this.bookingsExternalId = data.bookingsExternalId;
    this.rating = data.rating;
    this.feedbackText = data.feedbackText;
    this.freshdeskTicketId = data.freshdeskTicketId;
    this.createdAt = data.createdAt !== null && data.createdAt !== undefined
        ? new Date(data.createdAt)
        : null;
  }

  static getFreshdeskAdminTicketUrl(model: UserFeedback ): string|null {
    const base_url = process.env.REACT_APP_FRESHDESK_BASE_URL ?? 'https://support.ec2b.se';
    return model.freshdeskTicketId !== null && model.freshdeskTicketId !== undefined
      ? `${base_url}/a/tickets/${model.freshdeskTicketId}`
      : null;
  }
}

export default UserFeedback;
