import BaseModel from "./BaseModel";
import Resource from "./Resource";

class Hub extends BaseModel {
  static PUBLIC_PRIVATE_COLORS = {
    private: "orange",
    public: "green",
  };

  id: number;
  referenceId: string;
  latitude: number;
  longitude: number;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  description: string;
  timezone: string;
  access: string;
  name?: string;
  resources?: Resource[] | any;
  tspId: number;
  static __dates = [];
  static __access = ["public", "private"];
  constructor(data) {
    super(data, Hub.__dates);
    this.id = data.id;
    this.referenceId = data.referenceId;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.address = data.address;
    this.zipCode = data.zipCode;
    this.city = data.city;
    this.country = data.country;
    this.description = data.description;
    this.timezone = data.timezone;
    this.access = data.access;
    this.name = data.name;
    this.resources = data.resources;
    this.tspId = data.tspId;
  }
}

export default Hub;
