export enum EC2B_FORM_FIELD_TYPES {
  SINGLE_ANSWER = "SINGLE_ANSWER",
  MULTI_ANSWER = "MULTI_ANSWER",
  CONFIRM_CHECK = "CONFIRM_CHECK",
  INTEGER_INPUT = "INTEGER_INPUT",
  TEXT_INPUT = "TEXT_INPUT",
  CAMERA_OR_PICT_SELECT_INPUT = "CAMERA_OR_PICT_SELECT_INPUT",
  H1 = "H1",
  INFO_TEXT = "INFO_TEXT",
  PICTURE = "PICTURE",
  LINK = "LINK",
}
type Id = string | number;
type AnswerValue = string | number | boolean;
export type EC2BFormResponse = {
  answers?: Record<Id, Record<Id, AnswerValue>>;
  hidden_data?: string;
};
export type EC2BFieldOption = {
  id: Id;
  label: string;
  defaultValue?: AnswerValue;
  // Only in frontend
  value?: AnswerValue;
  required?: boolean;
  error?: boolean;
};
export type EC2BField = {
  id: Id;
  type: EC2B_FORM_FIELD_TYPES | string;
  label?: string;
  description?: string;
  required?: boolean;
  placeholder?: string;
  options: EC2BFieldOption[];
  answers?: Record<Id, Record<Id, AnswerValue>>;
  // Only in frontend
  invalid?: boolean;
};
export type EC2BFormConfig = {
  fields: EC2BField[];
  hidden_data?: string;
};
export interface Ec2bFormResponse {
  answers: Record<string, Record<string, string | number | boolean>>;
  hiddenData?: string;
}
export const mapFieldsToAnswers = (fields: EC2BField[]): Ec2bFormResponse["answers"] => {
  const answersEntries = fields
    ?.map((field) => {
      const optionsEntries = field?.options
        ?.map((option) => {
          const value = option?.value;
          return [option.id, value];
        })
        // Filter undefined values
        .filter((entry) => entry[1] !== undefined);

      if (optionsEntries) {
        const optionAnswers = Object.fromEntries(optionsEntries);
        return [field.id, optionAnswers];
      }
      return [field.id, undefined];
    })
    // Filter undefined values
    .filter((entry) => entry[1] !== undefined);
  const answers = Object.fromEntries(answersEntries);
  return answers;
  // Field
  // options
};
