import BaseModel from "./BaseModel";

class Location extends BaseModel {

    address: string;
    bookingId: number;
    city: string;
    country: string;
    description: string;
    latitude: number;
    longitude: number;
    zipCode: string;
    
    constructor(data) {
        super(data, []);
        this.address = data.address;
        this.bookingId = data.bookingId;
        this.city = data.city;
        this.country = data.country;
        this.description = data.description;
        this.latitude = data.latitude;
        this.longitude = data.longitude;
        this.zipCode = data.zipCode;
    }
}

export default Location;
