import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OverlayContext } from "../../../context/OverlayContext";
import { ToastContext } from "../../../context/ToastContext";
import { NavigationContext } from "../../../context/NavigationContext";
import { Table, Typography, Button, Form, List, Tag, Tooltip } from "antd";
import { ArrowLeftOutlined, StopOutlined } from "@ant-design/icons";
import ProductService from "../../../services/crud/ProductService";
// import ProductForm from "../components/ProductForm";
// import DeleteForm from "../components/DeleteForm";
import Product from "../../../models/Product";
import ProductForm from "../../../components/forms/ProductForm";

import * as _ from "lodash";

import { ViewTitle } from "../../../components/ViewTitle";
import DescriptionDataList from "../../../components/DescriptionDataList";
import { GoBackButton } from "../../../components/GoBackButton";

function ProductView(props) {
  let navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate(route);
  };
  const { id } = useParams<any>();
  const [product, setProduct] = useState<Product>(new Product({}));
  // const gotoPage = useContext(NavigationContext)["gotoPage"];
  const { openModal, closeModal } = useContext(OverlayContext);
  const { loading, loadMessage, showMessage } = useContext(ToastContext);
  const [form] = Form.useForm();
  // const [, updateState] = React.useState();
  // const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    loadMessage("Loading product...", () =>
      Promise.all([ProductService.getProduct(id)]).then((data) => {
        setProduct(data[0]);
        return true;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openProductUpdateModal = (product = null) => {
    openModal({
      title: "Update product",
      content: <ProductForm id={product.id} form={form} update={true} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          closeModal();
          loadMessage("Updating product", () =>
            ProductService.editProduct(
              values.id,
              _.pickBy(values, (x) => x !== null)
            )
              .then((product) => {
                setProduct(product);
                return "Product updated";
              })
              .catch((res) => {
                throw Error("Could not update product");
              })
          );
        });
      },
    });
  };
  // Filters
  const handleEdit = () => {
    openProductUpdateModal(product);
  };
  const title = product.name;
  return (
    <div style={{ position: "relative" }}>
      <GoBackButton></GoBackButton>

      {/* USER DATA */}
      <div>
        <ViewTitle title={title}></ViewTitle>
        <DescriptionDataList
          data={product}
          footer={
            <Button size="small" type="link" onClick={handleEdit}>
              Edit product
            </Button>
          }
        />
      </div>
    </div>
  );
}

export default ProductView;
