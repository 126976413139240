import React, { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../context/ToastContext";
import { Form, Input, Tooltip, Upload, InputNumber, Image, Spin, Space, Button, Select } from "antd";
import { LoadingOutlined, MinusCircleOutlined, PlusOutlined, QuestionCircleFilled } from "@ant-design/icons";
import AuthorizedService from "../../services/crud/AuthorizedService";
import Authorized from "../../models/Authorized";

import Subform from "../Global/Subform";
import AccountService from "../../services/crud/AccountService";
import Account from "../../models/Account";
import { EntitySelector } from "./selects/EntitySelector";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const id = props.id;
  const form = props.form;
  const [authorized, setAuthorized] = useState<Authorized>({} as Authorized);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const update: boolean = props.update ?? false;
  const { loading, loadMessage } = useContext(ToastContext);

  useEffect(() => {
    loadMessage("Loading authorized...", async () => {
      form.resetFields();
      const accounts = await AccountService.list();
      setAccounts(accounts);
      if (id) {
        await AuthorizedService.getAuthorized(id).then((data) => {
          setAuthorized(data.serialize());
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, id, update]);
  const content = (
    <Form initialValues={authorized} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={[{ required: true }]}>
        <Input placeholder="Enter a valid email" type="email" />
      </Form.Item>
      <Form.List name="accounts">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Subform
                key={key}
                content={
                  <>
                    <Form.Item
                      {...restField}
                      name={[name, "pivot", "accountId"]}
                      label="Account"
                      rules={[{ required: true, message: "Missing account" }]}
                    >
                      <Select placeholder={"Select an account"} options={accounts.map((a) => ({ label: a.name, value: a.id }))}></Select>
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "pivot", "admin"]} label="Role" rules={[{ required: true, message: "Missing admin" }]}>
                      <Select
                        options={[
                          { label: "user", value: 0 },
                          { label: "admin", value: 1 },
                        ]}
                      ></Select>
                    </Form.Item>
                    <Tooltip placement="rightTop" title="Tar bort rättigheten">
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Tooltip>
                  </>
                }
              ></Subform>
            ))}
            <Form.Item>
              <Tooltip placement="left" title="Lägger till en rad där ett konto och vilken rättighet som användarn kommer att ha till kontot">
                <Button type="dashed" onClick={() => add({ pivot: { admin: 0 } })} block icon={<PlusOutlined />}>
                  Add account
                </Button>
              </Tooltip>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Tooltip title="När en användare skapas med epost-adressen så kommer den knytas till dessa konto">
        <QuestionCircleFilled />
      </Tooltip>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};

/**
 * Lists all accounts except private accounts
 * @param props
 * @returns
 */
export const AuthorizedFormAdd = (props) => {
  const id = props.id;
  const form = props.form;
  const [authorized, setAuthorized] = useState<Authorized>({} as Authorized);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const update: boolean = props.update ?? false;
  const { loading, loadMessage } = useContext(ToastContext);

  useEffect(() => {
    loadMessage("Loading authorized...", async () => {
      form.resetFields();
      const accounts = await (await AccountService.list()).filter((a) => Account.types.PRIVATE !== a.type);
      setAccounts(accounts);
      if (id) {
        await AuthorizedService.getAuthorized(id).then((data) => {
          setAuthorized(data.serialize());
        });
      }
      return "loaded authorized";
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, id, update]);
  const content = (
    <Form initialValues={authorized} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={[{ required: true }]}>
        <Input disabled={update} placeholder="Enter a valid email" type="email" />
      </Form.Item>
      <Form.Item name="accountId" label="Accounts" rules={[{ required: true }]}>
        <EntitySelector.Data data={accounts.filter((account) => !authorized?.accounts?.find((a) => a.id === account.id))} />
      </Form.Item>
      <Tooltip title="När en användare skapas med epost-adressen så kommer den knytas till dessa konto">
        <QuestionCircleFilled />
      </Tooltip>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};

export const AuthorizedFormDelete = (props) => {
  const id = props.id;
  const form = props.form;
  const [authorized, setAuthorized] = useState<Authorized>({} as Authorized);
  const update: boolean = props.update ?? false;
  const { loading, loadMessage } = useContext(ToastContext);

  useEffect(() => {
    loadMessage("Loading authorized...", async () => {
      form.resetFields();
      if (id) {
        await AuthorizedService.getAuthorized(id).then((data) => {
          setAuthorized(data.serialize());
        });
      }
      return "loaded authorized";
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, id, update]);
  const content = (
    <Form initialValues={authorized} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>

      <Form.Item name="email" label="Email" rules={[{ required: true }]}>
        <Input disabled placeholder="Enter a valid email" type="email" />
      </Form.Item>
      <Form.Item name="accountId" label="Account" rules={[{ required: true }]} dependencies={["operation"]}>
        <Select
          placeholder="Select account to remove access"
          options={authorized?.accounts?.map((account) => {
            return { label: account.name, value: account.id };
          })}
        />
      </Form.Item>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};
