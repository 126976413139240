import React from "react";
import Product from "../../models/Product";
import ProductService from "../../services/crud/ProductService";
import ProductForm from "../forms/ProductForm";
import GenericItemsTable from "./GenericItemsTable";
import { TableProps } from "./types";

function ProductsTable({ ...rest }: TableProps<Product>) {
  return <GenericItemsTable model={Product} service={ProductService} form={ProductForm} {...rest}></GenericItemsTable>;
}

export function GenericProductsTable({ ...rest }: TableProps<Product>) {
  return <GenericItemsTable model={Product} service={ProductService} form={ProductForm} {...rest}></GenericItemsTable>;
}

export default ProductsTable;
