import React from 'react';
import { DatePicker, Space } from 'antd';
import moment, { Moment } from 'moment';

const { RangePicker } = DatePicker;

interface DatePickerCalendarProps {
  onDateChange: (dates: [Moment | null, Moment | null]) => void;
}

const DatePickerCalendar: React.FC<DatePickerCalendarProps> = ({ onDateChange }) => {
  const handleCalendarChange = (dates: [Moment | null, Moment | null]) => {
    onDateChange(dates);
  };

  return (
    <Space direction="vertical" size={12}>
      <RangePicker onCalendarChange={handleCalendarChange} />
    </Space>
  );
};

export default DatePickerCalendar;