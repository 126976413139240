import Product from "../../models/Product";
import HttpClient from "../../clients/HttpClient";

export default class ProductService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async list(params = {}): Promise<Array<Product>> {
    return this.httpClient.get("/products").then((res) => {
      return res.data.map((row) => new Product(row));
    });
  }

  public static async getProduct(id): Promise<Product> {
    return this.httpClient.get(`/products/${id}`).then((res) => {
      return new Product(res.data);
    });
  }

  public static async addProduct(data): Promise<Product> {
    return this.httpClient.post(`/products`, data).then((res) => {
      return new Product(res.data);
    });
  }

  public static async editProduct(id, data): Promise<Product> {
    return this.httpClient.put(`/products/${id}`, data).then((res) => {
      return new Product(res.data);
    });
  }

  public static async deleteProduct(id): Promise<boolean> {
    return this.httpClient.delete(`/products/${id}`, null).then((res) => {
      return true;
    });
  }
}
