import { Table, Typography, Input, Button, Form, Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../context/ToastContext";
import { listToMap } from "../../helpers/array-to-map";
import Resource, { resourceLabel, resourceSubtitle } from "../../models/Resource";
import HubService from "../../services/crud/HubService";
import ResourceService from "../../services/crud/ResourceService";
import { OverlayContext } from "../../context/OverlayContext";
import ResourceForm from "../forms/ResourceForm";
import { TableProps } from "./types";
import GenericItemsTable from "./GenericItemsTable";
import { formatPriceInCents } from "../../utils/common";

function ResourceTable({ dataFilter = (data: Resource) => true, columnFilter = (col) => true }: TableProps<Resource>) {
  const [resources, setResources] = useState([]);
  const [hubs, setHubs] = useState({});
  const [filter, setFilter] = useState("");
  const { loading, loadMessage } = useContext(ToastContext);
  const { openModal, closeModal } = useContext(OverlayContext);
  const [selectedRows, setSelectedRows] = useState<Resource[]>([]);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      await deleteResources(selectedRows);
    } catch (error) {
      console.log(error);
    }
    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  let navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate("/resources/" + route);
  };
  const loadTable = async () => {
    Promise.all([ResourceService.list(), HubService.list()]).then((data) => {
      // Filter+sort data
      const hubsMap = listToMap(data[1]);
      const resources = data[0].filter(dataFilter);
      resources.sort((a, b) => {
        // SORT resource.hub.name ASC, resource.name ASC
        if (a.hubId !== b.hubId) {
          const aHubName: String = hubsMap[a.hubId]?.name ?? "";
          const bHubName: String = hubsMap[b.hubId]?.name ?? "";
          return aHubName.toLowerCase().localeCompare(bHubName.toLowerCase());
        }
        return a.name.toLowerCase().localeCompare(b.name);
      });
      setResources(resources);

      setHubs(hubsMap);
      return true;
    });
  };
  useEffect(() => {
    loadMessage("Loading resources...", () => loadTable());
  }, []);

  const hubName = (hubId) => hubs[hubId]?.name ?? hubs[hubId]?.description ?? "";
  const columns: any = [
    {
      title: "Name",
      key: "name",
      render: (text, record, index) => resourceLabel(record),
      sorter: (a, b) => resourceLabel(a).toLowerCase().localeCompare(resourceLabel(b).toLowerCase()),
    },
    {
      title: "Other",
      dataIndex: ["modelId", "size", "other"],
      render: (text, record, index) => resourceSubtitle(record),
      sorter: (a, b) => resourceSubtitle(a).toLowerCase().localeCompare(resourceSubtitle(b).toLowerCase()),
    },
    {
      title: "MAC address",
      key: "name",
      render: (text, record, index) => `${record.macAddress}`,
      sorter: (a, b) => `${a.macAddress}`.toLowerCase().localeCompare(`${b.macAddress}`.toLowerCase()),
    },
    {
      title: "Hub",
      key: "hubId",
      render: (text, record, index) => hubName(record.hubId),
      sorter: (a, b) => hubName(a.hubId).toLowerCase().localeCompare(hubName(b.hubId).toLowerCase()),
    },
    {
      title: "Price/h",
      dataIndex: "priceIncVatCents",
      key: "priceIncVatCents",
      render: (text, record, index) => `${formatPriceInCents(record.priceIncVatCents, 'h')}`,
      sorter: (a, b) => a.priceIncVatCents - b.priceIncVatCents,
    },
  ];
  // Filters
  const filterColumns = (columns = []) => columns.filter(columnFilter);
  const filterTable = (data) => data.filter((item) => new RegExp(filter.toLowerCase()).test(item.toString().toLowerCase()));
  const handleOpenCreateModal = () => {
    openUpdateModal();
  };
  const openUpdateModal = (resource = null) => {
    form.resetFields();
    openModal({
      title: "Add resource",
      content: <ResourceForm form={form} update={true} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          const newResource = new Resource(values);
          closeModal();
          loadMessage("Adding resource...", () =>
            ResourceService.addResource(newResource)
              .then((resource) => {
                setResources((prev) => [...prev, resource]);
                return "Resource add";
              })
              .catch((res) => {
                console.log(res);
                throw Error("Could not add resource");
              })
          );
        });
      },
    });
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: Resource[]) => {
    console.log("selectedRowKeys changed: ", selectedRows);
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRows,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRows.length > 0;
  const deleteResources = async (resources) => {
    loadMessage("Deleting resources...", () =>
      Promise.all([resources.map((resource) => ResourceService.deleteResource(resource.id))]).then((data) => {
        // Refresh table
        loadTable();
        return true;
      })
    );
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <div>
          <Popconfirm
            title={"You are about to delete " + selectedRows.length + " resources!"}
            open={open}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          >
            <Button type="primary" onClick={showPopconfirm}>
              Delete Resources
            </Button>
          </Popconfirm>
          <Input.Search size="middle" placeholder="Search resources..." style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} />
          <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedRows.length} items` : ""}</span>
        </div>

        <Button type="primary" size="middle" onClick={handleOpenCreateModal}>
          Add resource
        </Button>
      </div>
      <Table
        rowKey="id"
        rowSelection={rowSelection}
        columns={filterColumns(columns)}
        dataSource={filterTable(resources)}
        pagination={{ total: resources.length, defaultPageSize: 30, showSizeChanger: true }}
        onRow={(record) => ({ onClick: () => gotoPage(`${record.id}`) })}
        loading={loading}
      />
      <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>Total of {resources.length} resources</Typography.Text>
    </div>
  );
}

export const GenericResourceTable = ({ ...rest }: TableProps<Resource>) => {
  return <GenericItemsTable model={Resource} form={ResourceForm} service={ResourceService} {...rest}></GenericItemsTable>;
};

export default ResourceTable;
