import React from "react";
import './RatingStars.scss';
import { Typography } from "antd";

type Props = {
  rating?: number | null;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ rating }: Props) => {
  const content = (
    <div className="RatingStars" aria-label={ `rating: ${rating} of 5`}>
      <Typography.Text style={{color: "gold"}}>{'★'.repeat(rating ?? 0)}</Typography.Text>
      <Typography.Text style={{color: "grey"}}>{'★'.repeat(5 - (rating ?? 0))}</Typography.Text>
    </div>
  );

  return content;
};
