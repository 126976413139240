import React, { ReactNode, useEffect } from "react";
import { Typography, Form, Input } from "antd";
import { FormInstance } from "antd/lib/form";
import { WarningFilled } from "@ant-design/icons";

type props = {
  form: FormInstance;
  item?: string;
  assocItemName?: string;
  warningText?: string;
  description?: ReactNode;
  itemName?: string;
  easy?: boolean;
};

const DeleteForm = (props: props) => {
  const form = props.form;
  const item = props.item ?? "item";
  const itemName = props.itemName ?? "delete";
  const assocItemName = props.assocItemName ?? null;
  const warningText = props.warningText ?? "Deleting this item can have serious consequences.";
  const description = props.description ?? null;
  const easy = props.easy ?? false;

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const content = (
    <Form form={form} style={{ display: "block" }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", color: "#cf1322" }}>
        <WarningFilled style={{ marginTop: 6, marginRight: 10 }} />
        <Typography.Paragraph style={{ color: "#cf1322" }}>{warningText}</Typography.Paragraph>
      </div>
      {!easy && (
        <>
          {(!description &&
            ((assocItemName && (
              <Typography.Paragraph>
                To delete {item} for {assocItemName}, type"
                <em style={{ fontStyle: "normal", fontWeight: "bold", color: "#096dd9" }}>{itemName}</em>" in the box below:
              </Typography.Paragraph>
            )) || (
              <Typography.Paragraph>
                To delete {item}, type"
                <em style={{ fontStyle: "normal", fontWeight: "bold", color: "#096dd9" }}>{itemName}</em>" in the box below:
              </Typography.Paragraph>
            ))) || <Typography.Paragraph>{description}</Typography.Paragraph>}

          <Form.Item name="itemNameForDeletion">
            <Input type="text" placeholder={`${itemName.substring(0, itemName.length - 8)}...`} />
          </Form.Item>
        </>
      )}
    </Form>
  );

  return content;
};
export default DeleteForm;
