import React from 'react';
import { Button } from 'antd';
import { generateExcelFile, triggerFileDownload } from './ExcelFileGenerator';
import { UnifiedBookingsTableData } from './BookingsTable';

interface BookingsExportToExcelButtonProps {
  data: UnifiedBookingsTableData[];
}

const BookingsExportToExcelButton : React.FC<BookingsExportToExcelButtonProps> = ({ data }) => {
  const handleExportExcel = () => {
    try {
      const workbook = generateExcelFile(data); // Pass the data here
      const currentDate = new Date().toISOString().split('T')[0];
      const fileName = `EC2B Bookings ${currentDate}.xlsx`; // Specify the generated file name
      triggerFileDownload(workbook,fileName);
    } catch (error) {
      console.error('Error exporting XLSX:', error.message);
    }
  };

  const buttonStyle: React.CSSProperties = {
    backgroundColor: '#1890FF',
    borderColor: '#1890FF',
    color: '#FFFFFF',
  };

  return (
    <Button style={buttonStyle} onClick={handleExportExcel}>
      Export to Excel
    </Button>
  );
};

export default BookingsExportToExcelButton;