import { Form, Input, InputNumber, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Account from "../../../models/Account";
import { ToastContext } from "../../../context/ToastContext";
import AccountService from "../../../services/crud/AccountService";
import { LoadingOutlined } from "@ant-design/icons/lib/icons";

/**
 * Takes in userId in order to load private account
 * @param props
 * @returns
 */
export const PrivateAccountForm = ({ id, form }) => {
  const [account, setAccount] = useState<Account | {}>();
  const { loading, loadMessage } = useContext(ToastContext);
  useEffect(() => {
    form.resetFields();
    if (id)
      loadMessage("Loading...", async () => {
        await AccountService.getPrivateAccount(id).then((data) => setAccount(data));
        return "Loaded account";
      });
  }, [id, form]);

  const content = (
    <Form initialValues={account} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input disabled type="text" />
      </Form.Item>
      <Form.Item name="balance" label="Balance" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};
