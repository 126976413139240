import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { UnifiedBookingsTableData } from './BookingsTable'

/**
 * Generate an Excel file based on the provided data.
 *
 * @param {UnifiedBookingsTableData[]} data - The data to be included in the Excel file.
 * @returns {ExcelJS.Workbook} An ExcelJS workbook containing the generated Excel file.
 */

export function generateExcelFile(data: UnifiedBookingsTableData[]): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Booking Report');

  worksheet.pageSetup.paperSize = 9; // 9 = A4 size
  worksheet.pageSetup.orientation = 'landscape';
  worksheet.pageSetup.margins = {
    left: 0.25, //margins in Inch
    right: 0.25,
    top: 0.25,
    bottom: 0.25,
    header: 0.3,
    footer: 0.3,
  };

  const header = [
    ['Id', 'Name', 'Cost (incl. vat) kr', 'Reference', 'Destination', 'Milage in Km', 'Product', 'Booked from', 'Duration']
  ];

  // Add data to the worksheet
  worksheet.addRows(header);

  const colId = 1;
  const colName = 2;
  const colCost = 3;
  const colReference = 4;
  const colDestination = 5;
  const colMilageInKm = 6;
  const colProduct = 7;
  const colBookedFrom = 8;
  const colDuration = 9;

  data.forEach((record) => {
    const rowData = [
      record.id,
      record.firstName + " " + record.lastName,
      parseFloat(record.cost),
      record.reference,
      record.destination,
      record.milageInKm,
      [
        record.resourceName,
        record.hubName,
      ].filter((value) => value !== null && value !== undefined && value.trim() !== "").join(" - "),
      record.bookedFrom,
      record.duration + " min",
    ];

    worksheet.addRow(rowData);
  });

  // Specify column width
  worksheet.getColumn(colId).width = 9;
  worksheet.getColumn(colName).width = 23;
  worksheet.getColumn(colCost).width = 21;
  worksheet.getColumn(colReference).width = 38;
  worksheet.getColumn(colDestination).width = 45;
  worksheet.getColumn(colMilageInKm).width = 15;
  worksheet.getColumn(colProduct).width = 45;
  worksheet.getColumn(colBookedFrom).width = 20;
  worksheet.getColumn(colDuration).width = 12;

  // Apply header row style
  worksheet.getRow(1).eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F2F2F2' }, // Header background color (2:nd grey in Excel)
    };
    cell.font = { bold: true, color: { argb: '000000' /* black */ }, size: 14};
  });

  // Apply style to columns
  worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
    for (let col = 1; col <= 7; col++) {
      if (col === colCost) {
        row.getCell(col).alignment = {
          horizontal:'right',
          vertical: 'middle',
          wrapText: true,
          indent:1,
        };
        row.getCell(col).numFmt = '0.00' ;
      } else if (col === colDuration) {
        row.getCell(col).alignment = {
          horizontal:'right',
          vertical: 'middle',
          wrapText: true,
        };
      } else {
        row.getCell(col).alignment = {
          horizontal: 'left',
          vertical: 'middle',
          wrapText: true,
        };
      }
    }
  });

  return workbook;
}

export function triggerFileDownload(workbook: ExcelJS.Workbook, fileName: string): void {
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    // Save and initiate download
    saveAs(blob, fileName);
  });
}