import BaseModel from "./BaseModel";
import Plan from "./Plan";
import User from "./User";
import Product from "./Product";
import SubscriptionHistory from "./SubscriptionHistory";
import Account from "./Account";

class Subscription extends BaseModel {
  activated: boolean;
  maxPauses: number;
  paused: boolean;
  planId: number;
  usedPauses: number;
  pausesRemaining: number;
  sharedWith: Array<User>;
  userId: 1;
  plan?: Plan;
  // user: User | any;
  products: Array<Product> | Array<any>;
  subscriptionHistories: Array<SubscriptionHistory> | any;
  account: Account;
  accountId: number | string;
  static __dates = ["lastPauseAt", "lastUnpauseAt", "subscribedAt", "terminatedAt", "bindingPeriodEndsAt", "nextBillingAt", "nextTopUpAt"];

  constructor(data) {
    super(data, Subscription.__dates);
    this.activated = data.activated;
    this.maxPauses = data.maxPauses;
    this.paused = data.paused ?? false;
    this.usedPauses = data.usedPauses;
    this.planId = data.planId;
    this.userId = data.userId;
    this.sharedWith = data.sharedWith ? data.sharedWith.map((data) => new User(data)) : [];
    // this.user = data.user ? new User(data.user) : {};
    this.plan = data.plan ? new Plan(data.plan) : ({} as Plan);
    this.subscriptionHistories = (data.subscriptionHistories ? data.subscriptionHistories.map((data) => new SubscriptionHistory(data)) : []).sort(
      (x) => -x.id
    );
    this.products = data.products
      ? data.products.map(
          (pdata) =>
            new Product({
              ...pdata,
              ...(pdata.pivot
                ? {
                    // id: pdata.pivot.productId ?? null,
                    balance: pdata.pivot.balance ?? 0,
                  }
                : { balance: 0 }),
            })
        )
      : [];

    if (this.plan) {
      this.pausesRemaining = this.plan.pauseMaxCountPerYear - this.usedPauses;

      if (this.products.length > 0) {
        const planProducts = this.plan.products ?? [];
        this.products.forEach((product, k) => {
          product.topUpYield = planProducts[k].topUpYield;
          product.pricePerPcInclVat = planProducts[k].pricePerPcInclVat;
          product.pricePerPcVatFraction = planProducts[k].pricePerPcVatFraction;
          product.pricePerExcessPcInclVat = planProducts[k].pricePerExcessPcInclVat;
          product.pricePerExcessPcVatFraction = planProducts[k].pricePerExcessPcVatFraction;
        });
      }
    }
    this.account = data.account;
    this.accountId = data.accountId;
  }
}

export default Subscription;
