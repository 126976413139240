import React from "react";
import { Form, Input, Button, Typography, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form";

export default (props) => {
  const form: FormInstance = props.form;
  // console.log(form.getFieldValue("extra"), form.getFieldValue("_extraDate"))

  return (
    <Form form={form} style={{display: "flex", flexDirection: "column"}}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>

      <Form.Item style={{width: "100%"}} name="username" label="Username">
        <Input type="email" />
      </Form.Item>
      <Form.Item style={{width: "100%"}} name="password" label="Password">
        <Input.Password/>
      </Form.Item>

      <div style={{flexDirection: "column", display: "flex", justifyContent: "flex-end"}}>
        <Tooltip placement="top" title="We will always remember you. If you want to be forgotten, clear the cache for this website.">
          <Typography.Paragraph style={{fontStyle: "italic", width: 108, alignSelf: "flex-end", cursor: "pointer"}}>
            Remember me?
          </Typography.Paragraph>
        </Tooltip>

        <div style={{alignSelf: "flex-end"}}>

          <Button type="link" style={{color: "#921E2D"}} onClick={() => props.onForgotPassword()}>
            Forgot my password
          </Button>
          <Button type="primary" htmlType="submit" style={{backgroundColor: "#921E2D", border: "0px"}} onClick={() => props.onLogin()}>
            Login
          </Button>
        </div>
      </div>
    </Form>
  );
};
