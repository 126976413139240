import React, { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../context/ToastContext";
import { Form, Input, Spin, Image, Typography, Timeline, Tag, DatePicker, Button, Tooltip, Modal } from "antd";
import { LinkOutlined, LoadingOutlined } from "@ant-design/icons";
import BookingService from "../../services/crud/BookingService";
import Booking, { BookingStatus, ExternalBookingStatus } from "../../models/Booking";
import Subform from "../Global/Subform";
import TspService from "../../services/crud/TspService";
import Tsp from "../../models/Tsp";
import Subscription from "../../models/Subscription";
import SubscriptionService from "../../services/crud/SubscriptionService";
import { EntitySelector } from "./selects/EntitySelector";
import BookingExternalService, { ReturnBookingPayload } from "../../services/crud/BookingExternalService";
import { TspKeyType } from "../../models/tspKeyEnum";
import { EC2B_FORM_FIELD_TYPES, mapFieldsToAnswers } from "../../models/EC2BForm";
import { OverlayContext } from "../../context/OverlayContext";
import { Modes } from "../../Modes";
import { NavigationContext } from "../../context/NavigationContext";
import User from "../../models/User";
import { capitalizeFirstLetter, capitalizeFirstLetterLowerRest } from "../../helpers/string";
import { TimeLineItem } from "../../models/TimeLine";
import { formatDateTime } from "../../utils/dates";
import UserFeedback from "../../models/UserFeedback";
import UserFeedbackService from "../../services/crud/UserFeedbackService";
import RatingStars from "../RatingStars";


// eslint-disable-next-line import/no-anonymous-default-export

export interface BookingModalViewData {
  id: number; // The EC2B booking id
  tspBookingId: number | null; // The TSP native booking id
  bookingLabel: string;
  user: User | null;
  accountName: string;
  totalPrice: string;
  initialPrice: string;
  initialPriceFromWallet: string;
  returnPrice: string;
  returnPriceFromWallet: string;
  status: BookingStatus | ExternalBookingStatus | string;
  referenceId: string;
  createdAt: string;
  timeLine: Array<TimeLineItem>;
  tspLogo?: string;
  bookedFrom: string;
  bookedTo: string;
  isLate: boolean;
  tspKey: TspKeyType;
  bookingType: "external" | "internal";
  tspId: number;
}

export type BookingModalViewType = { id?; form?; booking: BookingModalViewData; update?; setBookings?: any };
const BookingModalView = ({ id, form, update, booking, setBookings }: BookingModalViewType) => {
  const { loading, loadMessage } = useContext(ToastContext);
  const { mode } = useContext(NavigationContext);
  const { closeModal } = useContext(OverlayContext);
  const [tsp, setTsp] = useState<Tsp>();
  const [userFeedback, setUserFeedback] = useState<UserFeedback|null>(null);

  // const update: boolean = props.update ?? false;
  // const [booking, setBooking] = useState({} as any);
  // const { loading, loadMessage } = useContext(ToastContext);

  // useEffect(() => {
  //   form.resetFields();
  //   loadMessage("Loading booking...", () => BookingService.getBooking(id).then((data) => setBooking(data.serialize())));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [form, id]);
  useEffect(() => {
    TspService.getTsp(booking.tspId).then((data) => setTsp(data));
    if (booking.bookingType === "external") {
      UserFeedbackService.getUserFeedback(booking.id).then((data) => setUserFeedback(data)).catch();
    }
    return () => { setTsp(undefined) }
  }, [booking?.id])
  const tspName = tsp?.name ?? "TSP";

  const handleReturnBooking = () => {
    Modal.confirm({
      title: 'Return booking?',
      content: 'Email notifications to the end user may be triggered.',
      onOk: () => {
        returnBooking();
      }
    })
  };

  const returnBooking = () => {
    loadMessage("loading", async () => {
      if (booking.bookingType === "external") {
        const payload: ReturnBookingPayload = {
          tspKey: booking.tspKey,
          bookingId: booking.tspBookingId,
          langKey: "en",
        };
        const returnConfig = await BookingExternalService.returnBookingConfig(booking.tspBookingId, booking.tspKey);
        if (returnConfig?.skipForm || !returnConfig?.form) await BookingExternalService.returnBooking(payload);
        else {
          // Automatically marks all check_box fields as true
          const form = returnConfig.form;
          const fields = form?.fields;
          fields
            .filter((field) => field.type === EC2B_FORM_FIELD_TYPES.CONFIRM_CHECK)
            .forEach((field) => field.options.map((option) => (option.value = true)));
          const answers = mapFieldsToAnswers(fields);
          const formResponse = { answers: answers };
          const returnBookingPayload = { ...payload, formResponse: formResponse };
          await BookingExternalService.returnBooking(returnBookingPayload);
          closeModal();
        }

        const updatedBooking = await BookingExternalService.read(booking.id);

        setBookings((prev) => {
          const bookings = [...prev];
          const newBooking = bookings.find((b) => b.id === booking.id);
          newBooking.status = updatedBooking.status;
          return bookings;
        });
        return "returned booking";
      } else {
        await BookingService.returnBooking(booking.id);
        closeModal();
        const updatedBooking = await BookingService.getBooking(booking.id);
        setBookings((prev) => {
          const bookings = [...prev];
          const newBooking = bookings.find((b) => b.id === booking.id);
          newBooking.status = updatedBooking.status;
          return bookings;
        });
        return "returned booking";
      }
    });
  };

  const returnButtonEnabled = (() => {
    if (booking.bookingType === "external") {
      return [ExternalBookingStatus.LEASE_STARTED, ExternalBookingStatus.LEASE_EXTENDED].includes(booking.status as ExternalBookingStatus);
    } else {
      return [BookingStatus.STATUS_BOOKED].includes(booking.status as BookingStatus);
    }
  })();

  const returnButton = (<Form.Item>
    <h2 style={{ color: 'red', borderBottom: '1px solid red' }}>Danger zone</h2>
      <p>Med denna knapp kan du tvinga en bokning att returneras på samma sätt som om användaren själv avslutar den. Dvs även om användaren inte fysiskt har returnerat fordonet ännu, så förlorar användaren tillgång till nyckeln och vi kan behöva skicka ut någon för att få rätt på fordonet då.</p>
      <p>Om det är en bokning med t.ex. km-avgift så görs en avläsning vid denna tidpunkt och användaren kan sen betala den i appen eller på faktura.</p>
      <Tooltip
        title={
          returnButtonEnabled
            ? "Returns the vehicle and allows other users to start lease."
            : "You can only return bookings that is not returned or completed."
        }
      >
      <Button disabled={!returnButtonEnabled} loading={loading} onClick={handleReturnBooking}>
        Return booking
      </Button>
    </Tooltip>
  </Form.Item>)

  function formatCostDetail(price: String|null, priceFormWallet: String|null): String {
    if (price == null) {
      return 'saknas';
    }
    var s = price;
    if (Modes.SYSTEM_ADMIN === mode || Modes.DEVELOPER === mode) {
      s += ` (${priceFormWallet} from wallet)`;
    }
    return s;

  }

  function initialCostStr(): String {
    return formatCostDetail(booking.initialPrice, booking.initialPriceFromWallet);
  }

  function returnCostStr(): String {
    return formatCostDetail(booking.returnPrice, booking.returnPriceFromWallet);
  }

  const content = (
    <Form>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>

      <Subform
        content={
          <>
            <div style={{ position: "absolute", top: 120, right: 80, width: 120, padding: 16, border: "1px solid #f5f5f5" }}>
              <Image src={booking.tspLogo} preview={false} />
            </div>
            <Form.Item name="bookingLabel" label="Resource">
              <Typography.Text>
                {booking?.bookingLabel}
              </Typography.Text>
            </Form.Item>
            <Form.Item name={["user", "username"]} label="User and Account">
              <Typography.Text>
                {booking?.user?.username} - {booking?.user?.firstName} {booking?.user?.lastName} (id: {booking?.user?.id}) -
                {booking.accountName}
              </Typography.Text>
            </Form.Item>
            <Form.Item name="id" label="EC2B booking id">
              <Typography.Text>{booking.id}</Typography.Text>
            </Form.Item>
            <Form.Item name="tspBookingId" label={"TSP booking id".replace("TSP", tspName)}>
              <Typography.Text>{booking.tspBookingId ?? "-"}</Typography.Text>
            </Form.Item>
            <Form.Item name="price" label="Price">
              <Typography.Text>{booking.totalPrice}</Typography.Text>
              { booking.initialPrice !== null || booking.returnPrice !== null ?
                <Typography.Text
                  style={{display: "block", marginLeft: "1em"}}
                >Booking price: {initialCostStr()} <br/>
                Return price: {returnCostStr()}</Typography.Text>
                : <></>
              }
            </Form.Item>
            <Form.Item name="price" label="Booking">
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start" }}>
                <div style={{ marginRight: 40 }}>
                  <Tag color={Booking.STATUS_COLORS[booking.status.toLowerCase()]}>{capitalizeFirstLetterLowerRest(booking.status)}</Tag>
                  {booking.isLate && <Tag color="red">Late</Tag>}
                </div>
                <div>
                  Ref: <Typography.Text code>{booking.referenceId}</Typography.Text>
                </div>
              </div>
            </Form.Item>
            {Modes.SYSTEM_ADMIN === mode || Modes.DEVELOPER === mode ? <Form.Item name="tsp key" label="TSP key">
              <Typography.Text>{booking.tspKey}</Typography.Text>
            </Form.Item> : <></>}
            {Modes.SYSTEM_ADMIN === mode || Modes.DEVELOPER === mode ? <Form.Item name="feedback" label="User feedback">
              { userFeedback !== null
                ? (<>
                    <RatingStars rating={userFeedback.rating}></RatingStars>
                    <div><Typography.Text>{userFeedback.feedbackText}</Typography.Text></div>
                    {
                      userFeedback.freshdeskTicketId && (
                        <Button
                          href={UserFeedback.getFreshdeskAdminTicketUrl(userFeedback)}
                          target="_blank"
                          type="primary"
                          size="small"
                          icon={ <LinkOutlined /> }
                          style={{marginTop: '4px'}}
                        >Freshdesk Ticket #{userFeedback.freshdeskTicketId}</Button>
                      )
                    }
                  </>)
                : (<></>)
              }
            </Form.Item> : <></>}

            <Form.Item>
              <Timeline mode="left" style={{ position: "relative", left: -145, width: 780 }}>
                {
                  booking.timeLine.map((item) => (
                    <Timeline.Item color={item.color ?? 'green'} label={item.label}>
                      {formatDateTime(item.date)}
                    </Timeline.Item>
                  ))
                }
              </Timeline>
            </Form.Item>

            {Modes.SYSTEM_ADMIN === mode || Modes.DEVELOPER === mode ? returnButton : <></>}
          </>
        }
      />
    </Form>
  );

  return content;
};
export default BookingModalView;

export const AdminGeneralBookingForm = (props) => {
  const form = props.form;
  const id = props.id;
  // const update: boolean = props.update ?? false;
  const [booking, setBooking] = useState(new Booking({}));
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [accounts, setAccounts] = useState([]);
  const [subscription, setSubscriptions] = useState([]);
  const [tsps, setTsps] = useState([]);
  const [startDate, setEndTime] = useState(new Date());
  const [resources, setResources] = useState([]);

  useEffect(() => {
    form.resetFields();
    // Load data
  }, [form, id]);

  if (loading) {
    return <></>;
  }

  const content = (
    <Form initialValues={booking} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Subform
        content={
          <>
            <Form.Item name="username" label="user">
              <EntitySelector.Users valueIndex="username"></EntitySelector.Users>
            </Form.Item>
            <Form.Item name="accountId" label="account">
              <EntitySelector.Accounts></EntitySelector.Accounts>
            </Form.Item>
            <Form.Item name="tspId" label="tsp" dependencies={["accountId"]}>
              <EntitySelector.Tsps></EntitySelector.Tsps>
            </Form.Item>
            <Form.Item name="timeStart" label="start">
              <Input type="datetime-local" />
            </Form.Item>
            <Form.Item name="timeEnd" label="end">
              <Input type="datetime-local" />
            </Form.Item>
            <Form.Item name="resourceId" label="resource" dependencies={["tspId"]}>
              <EntitySelector.Resources></EntitySelector.Resources>
            </Form.Item>
          </>
        }
      />
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};
