import BaseModel from "./BaseModel";
import Product from "./Product";
import Tsp from "./Tsp";
import Subscription from "./Subscription";
import User from "./User";
import Account from "./Account";
import Resource from "./Resource";

class Booking extends BaseModel {
  static STATUS_COLORS = {
    pending: "orange",
    pending_extra_payment: "orange",
    pending_return_invoice: "orange",
    refund_in_progress: "orange",
    completed: "green",
    returned: "green",
    booked: "geekblue",
    lease_started: "cyan",
    lease_extended: "cyan",
    aborted: "grey",
    expired: "grey",
    canceled: "grey",
    refunded: "grey",
    failed: "red",
  };

  userId: number;
  tspId: number;
  productType: string;
  // subscriptionId: number;
  referenceId: string;
  resourceId: number;
  provider: string;
  price: number;
  currency: string;
  providerPrice: number;
  phoneNumber: number;
  canCancel: boolean;
  comment: string;
  status: string;
  extraInfo: string;
  productIdentification: string;
  user: User;
  product: Product;
  tsp: Tsp;
  subscription: Subscription;
  account: Account;
  resource: Resource;

  static __dates = ["pickupDatetime", "destinationDatetime", "bookingStartDatetime", "bookingEndDatetime"];

  constructor(data) {
    super(data, Booking.__dates);
    this.userId = data.userId;
    this.tspId = data.tspId;
    this.productType = data.productType;
    // this.subscriptionId = data.subscriptionId;
    this.referenceId = data.referenceId;
    this.resourceId = data.resourceId;
    this.provider = data.provider;
    this.price = data.price;
    this.currency = data.currency;
    this.providerPrice = data.providerPrice;
    this.phoneNumber = data.phoneNumber;
    this.canCancel = data.canCancel;
    this.comment = data.comment;
    this.status = data.status;
    this.productIdentification = data.productIdentification;
    // Leif:
    //   Api no longer sends this,
    //   but I keep it around now so I in page loader
    //   can join in what I need.
    this.user = data.user ? new User(data.user) : ({} as User);
    this.product = data.product ? new Product(data.product) : ({} as Product);
    this.tsp = data.tsp ? new Tsp(data.tsp) : ({} as Tsp);
    this.subscription = data.subscription ? new Subscription(data.subscription) : ({} as Subscription);
    this.account = data.account ? new Account(data.account) : ({} as Account);
    this.resource = data.resource;
  }
}

export enum BookingStatus {
  STATUS_PENDING = "pending",
  STATUS_BOOKED = "booked",
  STATUS_COMPLETED = "completed",
  STATUS_CANCELED = "canceled",
}

export enum ExternalBookingStatus {
  PENDING = "PENDING",
  BOOKED = "BOOKED",
  LEASE_STARTED = "LEASE_STARTED",
  LEASE_EXTENDED = "LEASE_EXTENDED",
  PENDING_EXTRA_PAYMENT = "PENDING_EXTRA_PAYMENT",
  PENDING_RETURN_INVOICE = "PENDING_RETURN_INVOICE",
  RETURNED = "RETURNED",
  ABORTED = "ABORTED",
  EXPIRED = "EXPIRED",
  CANCELLED = "CANCELLED",
  REFUND_INPROGRESS = "REFUND_INPROGRESS",
  REFUND_INITIATION_FAILED = "REFUND_INITIATION_FAILED",
  REFUNDED = "REFUNDED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export default Booking;
