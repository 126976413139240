import { TableProps, Form, Tooltip, Tag, Input, Table, Typography, Button } from "antd";
import { ColumnType } from "antd/es/table/interface";
import React from "react";
import { useState, useContext, useEffect } from "react";
import { OverlayContext } from "../../../context/OverlayContext";

import Booking from "../../../models/Booking";

import { DetailedBookingView } from "./DetailedBookingView";
import { BookingExternal } from "../../../services/crud/BookingExternalService";
import moment from "moment";
import { BookingModalViewData } from "../../../components/forms/BookingForm";
import User from "../../../models/User";
import Account from "../../../models/Account";
import { formatDateTime } from "../../../utils/dates";

import BookingsExportToExcelButton from './BookingsExportToExcelButton';
import NameDropDownList from "./FilterByNameDropDownList";
import DatePickerCalendar from "./FilterByDateCalendar";

const TableTitleWithTooltip = ({ title = "title", description = "description" }) => {
  return (
    <>
      <Tooltip title={description}>
        <Typography.Text>{title}</Typography.Text>
      </Tooltip>
    </>
  );
};

class BookingTableData extends Booking implements BookingsTableDataInterface {
  cost: string;
  resourceName: string;
  hubName: string;
  reference: string;
  firstName: string;
  lastName: string;
  // In minutes
  pricePerHour: string;
  bookedFrom: string;
  duration: number;
  tspName: string;
  destination: string;
  milageInKm: string;

  constructor(data: Booking) {
    super(data);
    let startDate = new Date(data["bookingStartDatetime"]);
    let endDate = new Date(data["bookingEndDatetime"]);
    let minutesBooked = Math.round(Math.abs(startDate?.getTime() - endDate?.getTime()) / 1000 / 60);
    this.duration = minutesBooked;
    this.cost = ((data.price * minutesBooked) / 60).toFixed(2) + " " + "kr";
    this.pricePerHour = data.price + " " + "kr";
    this.firstName = data.user.firstName;
    this.lastName = data.user.lastName;
    this.reference = data.referenceId;
    this.resourceName = data.resource?.name;
    this.hubName = data.resource?.hub?.name;
    this.tspName = data.tsp?.name;
    this.bookedFrom = startDate?.toLocaleString();
    this.destination = "-";
    this.milageInKm = "-";
  }
}

interface BookingsTableDataInterface {
  id?: string | number;
  firstName: string;
  lastName: string;
  cost: string;
  reference: string;
  bookedFrom: string;
  duration: number;
  hubName: string;
  resourceName: string;
  tspName: string;
  destination: string;
  milageInKm: string;
}

export class UnifiedBookingsTableData implements BookingsTableDataInterface {
  id: string | number;
  tspName: string;
  firstName: string;
  lastName: string;
  cost: string;
  reference: string;
  bookedFrom: string;
  duration: number;
  hubName: string;
  resourceName: string;
  destination: string;
  milageInKm: string;

  constructor(data: BookingsTableDataInterface | BookingTableData) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.cost = data.cost;
    this.reference = data.reference;
    this.bookedFrom = formatDateTime(data.bookedFrom);
    this.duration = data.duration;
    this.hubName = data.hubName;
    this.resourceName = data.resourceName;
    this.destination = data.destination || "-";
    this.milageInKm = data.milageInKm || "-";
  }
}

const toBookingsTableData = (data: BookingExternal | Booking): UnifiedBookingsTableData => {
  if (data instanceof BookingExternal) {
    const newBooking: BookingsTableDataInterface = {
      id: data.id,
      firstName: data.user?.firstName || "",
      lastName: data.user?.lastName || "",
      cost: Number(data.totalPriceInKr).toFixed(2) + " kr",
      reference: data.referenceId,
      bookedFrom: formatDateTime(data.bookFromDateTime),
      duration: Math.round(data.totalDurationInMinutes),
      hubName: "",
      resourceName: data.bookingLabel,
      tspName: "",
      destination: data.destination || "-",
      milageInKm: data.milageInKm || "-",
    };
    return new UnifiedBookingsTableData(newBooking);
  } else {
    return new UnifiedBookingsTableData(new BookingTableData(data));
  }
};

export function BookingsTable({ dataSource = [] }) {
  const mappedData = dataSource.map(toBookingsTableData);
  mappedData.sort(
    (a: any, b: any) =>
      -1 * `${a.bookedFrom}`.localeCompare(`${b.bookedFrom}`)
  )
  const { openModal } = useContext(OverlayContext);
  const openViewModal = (booking:Booking|BookingExternal) => {
    openModal({
      title: "View booking",
      content: <DetailedBookingView booking={booking} />,
      okText: "Close",
      hasCancel: false,
    });
  };

  const columns: ColumnType<UnifiedBookingsTableData>[] = [
    {
      title: "Id",
      dataIndex: ["id"],
      render: (text, record, index) => {
        return <Typography.Text>{text}</Typography.Text>;
      },
    },
    {
      title: "Name",
      dataIndex: ["name"],
      render: (text, record, index) => {
        return (
          <Tooltip title={text}>
            <Typography.Text>{record?.firstName + " " + record?.lastName}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: <TableTitleWithTooltip title="Cost (incl. vat)" description="Total cost. vat included." />,
      dataIndex: ["cost"],
      align: 'right',
      render: (text, record, index) => {
        return text;
      },
    },
    {
      title: "Reference",
      dataIndex: ["reference"],
      render: (text, record, index) => {
        return text;
      },
    },
    {
      title: "Destination",
      dataIndex: ["destination"],
      render: (text, record, index) => {
        return text;
      },
    },
    {
      title: "Milage in Km",
      dataIndex: ["milageInKm"],
      render: (text, record, index) => {
        return text;
      },
    },
    {
      title: <TableTitleWithTooltip title="Product" description="First row is resource name and second row is hub name" />,
      render: (text, record, index) => {
        return (
          <>
            <Tooltip title={"resource: " + record?.resourceName}>
              <Typography.Text>{record?.resourceName}</Typography.Text>
            </Tooltip>
            <br />
            <Tooltip title={"Hub: " + record?.hubName}>
              <Typography.Text>{record?.hubName}</Typography.Text>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Booked from",
      dataIndex: ["bookedFrom"],
      render: (text, record, index) => {
        return text;
      },
    },
    {
      title: "Duration",
      dataIndex: ["duration"],
      align: 'right',
      render: (text, record, index) => {
        return text + " min";
      },
    },
  ];

  const [localSelectedNames, setLocalSelectedNames] = useState<string[]>([]);
  const [localSelectedDates, setLocalSelectedDates] = useState<any>(null);

  const handleNameChange = (selectedOptions: { label: string; value: string; }[]) => {
    const selectedNames = selectedOptions.map(option => option.value);
    setLocalSelectedNames(selectedNames);
  };

  // Apply filters to table
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filteredData, setFilteredData] = useState(mappedData);

  const filterBookingsRecords = () => {

    // Filter bookings according to Names and/or Dates depending on active filters
    var filteredData = mappedData;
    var hasFilter = false;
    if (localSelectedNames.length > 0) {
      filteredData = filteredData.filter((booking) => {
        const fullName = `${booking.firstName} ${booking.lastName}`;
        const nameCondition = localSelectedNames.includes(fullName);

        return nameCondition;
      });
      hasFilter = true;
    }
    // Apply date filter if both start and end date has been given
    if (localSelectedDates !== null && localSelectedDates[0] !== null && localSelectedDates[1] !== null) {
      filteredData = filteredData.filter((booking) => {
        const dateCondition =
          moment(booking.bookedFrom).isSameOrAfter(localSelectedDates[0]) &&
          moment(booking.bookedFrom).isSameOrBefore(localSelectedDates[1])
        return dateCondition;
      });
      hasFilter = true;
    }

    setFilteredData(filteredData);
    setFiltersApplied(hasFilter);

  };

  useEffect(() => {
    filterBookingsRecords();
  }, [localSelectedNames, localSelectedDates]);


  return (
    <div>
      <Typography.Title level={3}>Bookings</Typography.Title>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        {/* <Input.Search size="middle" placeholder="Search bookings..." style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} /> */}
        {/* <Button type="primary" size="middle" onClick={() => openCreateModal()}>
            Create new booking
          </Button> */}

        {/* Booking filter box */}
        <div style={{
          position: 'relative',
          zIndex: '1000',
          color: '#777777',
        }}>
          {/* Filter title*/}
          <div style={{display:'Inline-flex'}}>
            {/* Filter according to name(s) */}
            <div>
              <div>
                <NameDropDownList
                  data={mappedData}
                  options={[]}
                  onChange={handleNameChange}
                />
              </div>
            </div>

            {/* Filter according to date(s) */}
            <div>
              <div style={{marginLeft:'5px',zIndex: '1000',color: '#777777'}}>
              <DatePickerCalendar onDateChange={(dates) => setLocalSelectedDates(dates)} />
              </div>
            </div>

          </div>

        </div>

        <div style={{position: 'relative', marginLeft:'auto', marginTop:'auto'}}>
          <BookingsExportToExcelButton data={filtersApplied ? filteredData : mappedData}/>      {/* Button for exporting data into xlsx*/}
        </div>
        
      </div>
      <Table
        bordered
        rowKey="id"
        columns={columns}
        dataSource={filtersApplied ? filteredData : mappedData}
        //pagination={{ total: bookings.length, defaultPageSize: 30, showSizeChanger: true }}
        onRow={(record) => ({ onClick: () => openViewModal(dataSource.find((d) => d.id  === record.id)) })}
        // loading={loading}
      />
      <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>Total of {filtersApplied ? filteredData.length : mappedData.length} bookings</Typography.Text>
    </div>
  );
}