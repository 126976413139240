import React from "react";
import { Typography, List } from "antd";

export function DescriptionListItem({ label = "", value = "", children }: { label: string; value?: any; children?: any }) {
  return (
    <List.Item style={{ marginBottom: 10 }}>
      <span style={{ textTransform: "capitalize", display: "inline-block", width: 140, fontWeight: 600, verticalAlign: "top", marginLeft: 10 }}>
        {label}
      </span>
      <span style={{ display: "inline-block", width: 400, color: "#bfbfbf" }}>
        {children ? children : <Typography.Text>{value}</Typography.Text>}
      </span>
    </List.Item>
  );
}
