import React from "react";
import HubsTable from "../../../components/tables/HubsTable";

function Hubs() {
  return (
    <div>
      <HubsTable></HubsTable>
    </div>
  );
}

export default Hubs;
