import React, { useState, useEffect, useContext } from "react";
import { Table, Typography, Button, Input, Form, Tooltip, Image } from "antd";
import TspService from "../../../services/crud/TspService";
import { OverlayContext } from "../../../context/OverlayContext";
import { ToastContext } from "../../../context/ToastContext";
import { ExclamationCircleFilled, LinkOutlined } from "@ant-design/icons";
import { TspForm } from "../../../components/forms/TspForm";
import InfoBox from "../../../components/InfoBox";
import Tsp from "../../../models/Tsp";
import { ColumnType } from "antd/lib/table";

function Tsps() {
  const [tsps, setTsps] = useState([]);
  const [filter, setFilter] = useState("");
  const { openModal, closeModal } = useContext(OverlayContext);
  const { loading, loadMessage } = useContext(ToastContext);
  const [form] = Form.useForm();

  useEffect(() => {
    loadMessage("Loading Tsps...", () =>
      TspService.list().then((tsps) => {
        setTsps(tsps);
        return true;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const openCreateModal = () => {
  //   form.resetFields();
  //   openModal({
  //     title: "Add new TSP",
  //     content: <TspForm form={form} />,
  //     onOk: () => {
  //       closeModal();
  //       form
  //         .validateFields()
  //         .then(async (values) => {
  //           loadMessage("Adding TSP...", () => TspService.addTsp(values).then(tsp => {
  //               setTsps([tsp, ...tsps]);
  //               return "TSP has been added";
  //           }).catch(res => {
  //             console.log(res);
  //             throw Error("TSP could not be added");
  //           }));
  //         })
  //     },
  //   });
  // };

  const openUpdateModal = (tsp = null) => {
    form.resetFields();
    openModal({
      title: "Update TSP",
      content: <TspForm id={tsp.id} update={true} form={form} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          closeModal();

          // Set nullable fields to null if they contain empty strings.
          ['phoneNumberDescription', 'supportEmailUrl', 'websiteFaqUrl', 'websiteSupportUrl'].forEach((attr) => {
            if (`${values[attr]}`.trim() === '') {
              values[attr] = null;
            }
          });

          loadMessage("Updating TSP", () =>
            TspService.editTsp(tsp.id, values)
              .then((tsp) => {
                const i = tsps.findIndex((item) => item.id === tsp.id);
                tsps[i] = tsp;
                setTsps(tsps);
                return "TSP has been updated";
              })
              .catch((res) => {
                throw Error("Could not update TSP");
              })
          );
        });
      },
    });
  };

  // const openViewModal = (tsp = null) => {
  //   form.resetFields();
  //   openModal({
  //     title: "View TSP",
  //     content: <TspForm id={tsp.id} update={false} />,
  //     okText: "Close",
  //     hasCancel: false
  //   });
  // };

  const filterTable = (data) => data.filter((item) => new RegExp(filter.toLowerCase()).test(item.toString().toLowerCase()));

  const columns: ColumnType<Tsp>[] = [
    {
      title: "Logo",
      dataIndex: "logo",
      key: "id",
      render: (text, record, index) => <Image src={record.logo} height={30} width={60} style={{ height: 30, width: "auto", cursor: "pointer" }} />,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    },
    {
      title: "Key",
      dataIndex: "tspKey",
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text, record, index) => {
        return (
          <Tooltip title={text}>
            <Typography.Text>
              {text.replace(/^(.{11}[^\s]*).*/, "$1...")} <ExclamationCircleFilled />
            </Typography.Text>
          </Tooltip>
        );
      },
      sorter: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()),
    },
    {
      title: "Phone number",
      dataIndex: "phoneNumber",
      render: (text, record, index) => <>
        <Typography.Text>{record.phoneNumber}</Typography.Text><br/>
        <Typography.Text style={{fontSize: "12px"}}>{record.phoneNumberDescription}</Typography.Text>
      </>,
      sorter: (a, b) => `${a.phoneNumber}`.toLowerCase().localeCompare(`${b.phoneNumber}`.toLowerCase()),
    },
    {
      title: "Support Email",
      dataIndex: "supportEmailUrl",
      render: (text, record, index) => record.supportEmailUrl !== null ? (
        <a href={record.supportEmailUrl} target="_blank" rel="noopener noreferrer">
          {record.supportEmailUrl} <LinkOutlined />
        </a>
      ) : <></>,
      sorter: (a, b) => `${a.supportEmailUrl}`.toLowerCase().localeCompare(`${b.supportEmailUrl}`.toLowerCase()),
    },
    {
      title: "Website",
      dataIndex: "website",
      render: (text, record, index) => (
        <a href={record.website} target="_blank" rel="noopener noreferrer">
          {record.website} <LinkOutlined />
        </a>
      ),
      sorter: (a, b) => `${a.website}`.toLowerCase().localeCompare(`${b.website}`.toLowerCase()),
    },
    {
      title: "FAQ url",
      dataIndex: "websiteFaqUrl",
      render: (text, record, index) => record.websiteFaqUrl !== null ? (
        <a href={record.websiteFaqUrl} target="_blank" rel="noopener noreferrer">
          {record.websiteFaqUrl} <LinkOutlined />
        </a>
      ) : <></>,
      sorter: (a, b) => `${a.websiteFaqUrl}`.toLowerCase().localeCompare(`${b.websiteFaqUrl}`.toLowerCase()),
    },
    {
      title: "Support url",
      dataIndex: "websiteSupportUrl",
      render: (text, record, index) => record.websiteSupportUrl !== null ? (
        <a href={record.websiteSupportUrl} target="_blank" rel="noopener noreferrer">
          {record.websiteSupportUrl} <LinkOutlined />
        </a>
      ) : <></>,
      sorter: (a, b) => `${a.websiteSupportUrl}`.toLowerCase().localeCompare(`${b.websiteSupportUrl}`.toLowerCase()),
    },
    {
      title: "Actions",
      key: "actions",
      width: 150,
      render: (text, record, index) => (
        <div style={{ display: "flex" }}>
          <Button type="link" size="small" onClick={() => openUpdateModal(record)}>
            Edit
          </Button>
          {/* <Button type="link" size="small" onClick={() => openViewModal(record)}>
            View
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <div>
      <InfoBox>
        <p>Mjukvaruutvecklare lägger till nya TSP:er</p>
      </InfoBox>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <Input.Search size="middle" placeholder="Search TSPs..." style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} />
        {/* <Button type="primary" size="middle" onClick={() => openCreateModal()}>
          Create new TSP
        </Button> */}
      </div>
      <Table
        bordered
        rowKey="id"
        columns={columns}
        dataSource={filterTable(tsps)}
        pagination={{ total: tsps.length, defaultPageSize: 30, showSizeChanger: true }}
        loading={loading}
      />
      <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>Total of {tsps.length} TSPs</Typography.Text>
    </div>
  );
}

export default Tsps;
