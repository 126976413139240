import React, { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../context/ToastContext";
import { Form, Input, Select, Spin, Tooltip } from "antd";
import { LoadingOutlined, QuestionCircleFilled } from "@ant-design/icons";
import HubService from "../../services/crud/HubService";
import Hub from "../../models/Hub";
import ResourceService from "../../services/crud/ResourceService";
import Resource from "../../models/Resource";

const HubForm = (props) => {
  const id = props.id;
  const form = props.form;
  const [hub, setHub] = useState<Hub | {}>(
    id ? {} : {
      // initial values for creating new hub
      referenceId: 123,
      country: 'Sweden',
      timezone: 'Europe/Stockholm',
    }
  );
  // const update: boolean = props.update ?? false;
  const { loading, loadMessage } = useContext(ToastContext);

  useEffect(() => {
    form.resetFields();
    if (id) {
      loadMessage("Loading hub...", async () => {
        if (id) await HubService.getHub(id).then((data) => setHub(data.serialize()));
      });
    }
  }, [id, form]);

  /**
   *
  id: number;
  referenceId: string;
  type: string;
  latitude: number;
  longitude: number;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  description: string;
  timezone: string;
  access: string
  name?: string;
   */
  console.log(hub);

  const content = (
    <Form initialValues={hub} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="description" label="Description" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="referenceId" label="Reference Id" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="latitude" label="Latitude" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="longitude" label="longitude" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="address" label="Address" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="zipCode" label="Zip code" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="city" label="City" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="country" label="Country" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="timezone" label="Timezone" rules={[{ required: true }]}>
        <Select options={[{ label: "Europe/Stockholm", value: "Europe/Stockholm" }]}></Select>
      </Form.Item>
      <Form.Item name="access" label="Access" rules={[{ required: true }]}>
        <Select
          options={[
            { label: "private", value: "private" },
            { label: "public", value: "public" },
          ]}
        ></Select>
      </Form.Item>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};

const HubAddResourceForm = (props) => {
  const id = props.id;
  const form = props.form;
  const [hub, setHub] = useState<Hub | {}>();
  const [resources, setResources] = useState<Resource[]>([]);
  // const update: boolean = props.update ?? false;
  const { loading, loadMessage } = useContext(ToastContext);

  useEffect(() => {
    form.resetFields();

    loadMessage("Loading...", async () => {
      if (id) await HubService.getHub(id).then((data) => setHub(data.serialize()));
      const resources = await ResourceService.list();
      // Exclude current resources that is in the hub
      const filteredResources = resources.filter((r) => r.id !== id);
      setResources(filteredResources);
      return;
    });
  }, [id, form]);

  /**
   *
  id: number;
  referenceId: string;
  type: string;
  latitude: number;
  longitude: number;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  description: string;
  timezone: string;
  access: string
  name?: string;
   */

  const content = (
    <Form initialValues={hub} form={form}>
      <Form.Item name="id" label="id">
        <Input type="text" disabled />
      </Form.Item>
      <Form.Item name="name" label="name">
        <Input type="text" disabled />
      </Form.Item>
      <Form.Item name="resourceId" label="Resource">
        <Select
          placeholder="Please select a resource"
          options={resources.map((r: Resource) => {
            return { label: r.name, value: r.id };
          })}
        ></Select>
      </Form.Item>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
};

export { HubAddResourceForm };

export default HubForm;
