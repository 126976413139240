import Account from "./Account";
import BaseModel from "./BaseModel";
import Subscription from "./Subscription";

class BaseUser extends BaseModel {
  username: string;
  password: string;
  addressLine1: string;
  addressLine2: string;
  bankidRef: string;
  city: string;
  driverLicenseNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  personalId: string;
  phoneNumber: string;
  role: string;
  zipCode: string;
  subscriptions: Array<Subscription> | Array<any>;
  isRegistered: boolean;

  static __dates = ["lastLoginAt", "driverLicenseExpiresAt", "bankidVerifiedAt", "emailVerifiedAt", "phoneNumberVerifiedAt"];

  constructor(data) {
    super(data, User.__dates);
    this.username = data.username;
    this.password = data.password;
    this.addressLine1 = data.addressLine1;
    this.addressLine2 = data.addressLine2;
    this.bankidRef = data.bankidRef;
    this.city = data.city;
    this.driverLicenseNumber = data.driverLicenseNumber;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.personalId = data.personalId;
    this.phoneNumber = data.phoneNumber;
    this.role = data.role;
    this.zipCode = data.zipCode;
    this.subscriptions = data.subscriptions ? data.subscriptions.map((sdata) => new Subscription(sdata)) : [];
    this.isRegistered = data.isRegistered;
  }
}

class User extends BaseUser {
  accounts?: Account[];
  privateAccount?: Account;
  notPrivateAccounts?:Account[]
  jwt?: any;
  pivot?: any;
  constructor(data) {
    super(data);
    this.accounts = data.accounts;
    if(this.accounts){
      this.privateAccount = this.accounts.find((account) => account.type === "private");
      this.notPrivateAccounts = this.accounts.filter((account) => account.type !== "private");
    }
    this.jwt = data.jwt;
    this.pivot = data.pivot;
  }





}

export default User;
