import "./Main.scss";
import React, { useState, useContext, useEffect } from "react";
import { Layout, Menu, Button, Typography, Avatar, Select, Tooltip, Space } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  //DashboardOutlined,
  UserOutlined,
  MailOutlined,
  CreditCardOutlined,
  CalendarOutlined,
  ShoppingCartOutlined,
  CarOutlined,
  // BarcodeOutlined,
  RocketOutlined,
  ShopOutlined,
  BankOutlined,
  // RiseOutlined,
  // PercentageOutlined,
} from "@ant-design/icons";
import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import Authorizeds from "./pages/Authorizeds";
import Users from "./pages/admin/users/Users";
import UserView from "./pages/admin/users/UserView";
import Plans from "./pages/admin/plans/Plans";
import Tsps from "./pages/admin/tsps/Tsps";
import Products from "./pages/admin/products/Products";
import Bookings from "./pages/admin/bookings/Bookings";
/*import Heroes from "./pages/Heroes";
import Actions from "./pages/Actions";*/
import Subscriptions from "./pages/admin/subscriptions/Subscriptions";
//import SubscriptionProducts from "./pages/SubscriptionProducts";
import Resources from "./pages/admin/resources/Resources";
import ResourceView from "./pages/admin/resources/ResourceView";
import Hubs from "./pages/admin/hubs/Hubs";
import { NavigationContext } from "./context/NavigationContext";
import Components from "./pages/developer/Components";
import HubView from "./pages/admin/hubs/HubView";
import PrivateAccounts from "./pages/admin/accounts/PrivateAccounts";
import CompanyAccounts from "./pages/admin/accounts/CompanyAccounts";
import RealEstateAccounts from "./pages/admin/accounts/RealEstateAccounts";
import AccountView from "./pages/admin/accounts/AccountView";
import Accounts from "./pages/admin/accounts/Accounts";
import PlanView from "./pages/admin/plans/PlanView";
import ProductView from "./pages/admin/products/ProductView";
import Menus, { SideBarMenu } from "./components/Menus";
import { Modes } from "./Modes";
import CompanyAccountsView from "./pages/admin/accounts/CompanyAccountsView";
import RealEstateAccountsView from "./pages/admin/accounts/RealEstateAccountsView";
import User from "./models/User";
import Support from "./components/Support";
import spec from "../package.json";
import DevContainer from "./components/DevContainer";
import LoginService from "./services/crud/LoginService";
import AuthService from "./services/crud/AuthService";
import { ToastContext } from "./context/ToastContext";
import ReportsPage from "./pages/admin/reports";
import { UserFeedbacksTable } from "./pages/admin/accounts/UserFeedbacks";
import WhiteLabels from "./pages/admin/whiteLabels/WhiteLabels";
import WhiteLabelView from "./pages/admin/whiteLabels/WhiteLabelsView";

const { Header, Content, Sider, Footer } = Layout;
function Main() {
  const { loadMessage } = useContext(ToastContext);
  let navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    AuthService.validateJWT().then((valid) => {
      if (!valid) {
        loadMessage("Invalid JWT token. Please login again.");
        LoginService.logout();
      }
    });
  }, []);
  const handleClick = (route) => {
    navigate(route);
  };
  const [collapsed, setCollapsed] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { history, currentPage, gotoPage, logout, user, mode, setMode } = useContext(NavigationContext);

  let locationPage = location.pathname.split("/")[1];

  return (
    // <Router navigator={history} location={currentPage}>
    // <div style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 2000, backdropFilter: "invert(100%) grayscale(100%) saturate(110%) brightness(110%) contrast(80%) sepia(60%) hue-rotate(320deg)", width: "100%", height: "100%", pointerEvents: "none"}}></div>
    <Layout style={{ height: "100%" }}>
      <Sider
        theme="dark"
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflowX: "clip",
          userSelect: "none",
          backgroundColor: "#921E2D",
          zIndex: 300,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div style={{ maxWidth: collapsed ? 80 : 200 }}>
            <img
              alt="logo"
              src="/_EC2B_neg.png"
              style={{ padding: collapsed ? 10 : "16px 30px", maxWidth: "100%", height: "auto", transition: "width 0.2s, padding 0.2s" }}
            />
          </div>
          <SideBarMenu
            mode="inline"
            defaultSelectedKeys={[locationPage]}
            selectedKeys={[locationPage]}
            defaultOpenKeys={[locationPage]}
            openKeys={[locationPage]}
            theme="dark"
            style={{
              flex: 1,
              maxWidth: "100%",
              backgroundColor: "#921E2D",
              overflowY: "auto",
            }}
            variant={mode}
            handleClick={handleClick}
          ></SideBarMenu>
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Typography.Paragraph
              style={{ textAlign: "center", fontSize: "12px", lineHeight: 1.6, marginBottom: 6, color: "rgba(255, 255, 255, 0.65)" }}
            >
              {!collapsed ? "Version " : ""}
              {spec.version}
            </Typography.Paragraph>
          </div>
        </div>
      </Sider>
      <Layout style={{ overflow: "hidden", zIndex: 200 }}>
        <Header
          style={{
            padding: 16,
            lineHeight: 0,
            backgroundColor: "white",
            display: "flex",
            justifyContent: "space-between",
            boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.02)",
            zIndex: 200,
          }}
        >
          <Space>
            <Button
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              type="primary"
              size="middle"
              onClick={() => setCollapsed(!collapsed)}
            />
            <DevContainer>
              <Tooltip placement="right" title="Displays menu based on mode">
                <Select<Modes>
                  options={[
                    // { label: "DEVELOPER", value: Modes.DEVELOPER },
                    { label: "SYSTEM_ADMIN", value: Modes.SYSTEM_ADMIN },
                    { label: "REAL_ESTATE_ADMIN", value: Modes.REAL_ESTATE_ADMIN },
                    { label: "COMPANY_ADMIN", value: Modes.COMPANY_ADMIN },
                  ]}
                  value={mode}
                  onSelect={(value) => setMode(value)}
                ></Select>
              </Tooltip>
            </DevContainer>
          </Space>

          <Space>
            <Tooltip title={user?.username}>
              <Avatar size={32} style={{ backgroundColor: "#FF9899" }} icon={<UserOutlined />} />
            </Tooltip>
            <Button type="link" onClick={() => logout()}>
              Logout
            </Button>
          </Space>
        </Header>
        {/* <PageHeader title={capitalizeFirstLetter(currentPage)} subTitle="" style={{ backgroundColor: "white" }} /> */}
        <Content
          style={{
            padding: 40,
            minHeight: 280,
            background: "#f5f5f5",
            overflow: "auto",
          }}
        >
          <div
            style={{
              padding: 24,
              backgroundColor: "white",
              boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.015)",
            }}
          >
            {Modes.SYSTEM_ADMIN === mode && (
              <Routes>
                <Route path="/" element={<Navigate to="/users" replace />} />
                {/*<Route path="incoming" element={<Actions />} /> */}
                <Route path="reports" element={<ReportsPage />} />
                <Route path="tsps" element={<Tsps />} />
                <Route path="products" element={<Products />} />
                <Route path="products/:id" element={<ProductView />} />
                <Route path="hubs" element={<Hubs />} />
                <Route path="hubs/:id" element={<HubView />} />
                <Route path="plans" element={<Plans />} />
                <Route path="plans/:id" element={<PlanView />} />
                <Route path="subscriptions" element={<Subscriptions />} />
                {/*<Route path="Subscriptionproducts" element={<SubscriptionProducts />} />*/}
                <Route path="bookings" element={<Bookings />} />
                <Route path="userFeedbacks" element={<UserFeedbacksTable />} />
                {/*<Route path="heroes" element={<Heroes />} />*/}
                <Route path="users/:id" element={<UserView />} />
                <Route path="users" element={<Users />} />
                <Route path="users/pre-granted" element={<Authorizeds />} />
                <Route path="resources/:id" element={<ResourceView />} />
                <Route path="resources" element={<Resources />} />
                <Route path="accounts/:id" element={<AccountView />} />
                <Route path="account" element={<Accounts />} />
                <Route path="privates/:id" element={<ResourceView />} />
                <Route path="privates" element={<PrivateAccounts />} />
                <Route path="companies/:id" element={<CompanyAccountsView />} />
                <Route path="companies" element={<CompanyAccounts />} />
                <Route path="realEstates/:id" element={<RealEstateAccountsView />} />
                <Route path="realEstates" element={<RealEstateAccounts />} />
                <Route path="whiteLabels" element={<WhiteLabels />} />
                <Route path="whiteLabels/:id" element={<WhiteLabelView />} />

                {/* {process.env.NODE_ENV === "development" && <Route path="components" element={<Components />} />} */}
              </Routes>
            )}
            {Modes.COMPANY_ADMIN === mode && (
              <Routes>
                <Route path="/" element={<Navigate to="/companies" replace />} />
                <Route path="companies/:id" element={<CompanyAccountsView />} />
                <Route path="companies" element={<CompanyAccounts />} />
                <Route path="realEstates/:id" element={<RealEstateAccountsView />} />
                <Route path="realEstates" element={<RealEstateAccounts />} />
              </Routes>
            )}

            {Modes.REAL_ESTATE_ADMIN === mode && (
              <Routes>
                <Route path="/" element={<Navigate to="/companies" replace />} />
                <Route path="companies/:id" element={<CompanyAccountsView />} />
                <Route path="companies" element={<CompanyAccounts />} />
                <Route path="realEstates/:id" element={<RealEstateAccountsView />} />
                <Route path="realEstates" element={<RealEstateAccounts />} />
              </Routes>
            )}
            <Support />
          </div>
        </Content>
      </Layout>
    </Layout>
    // </Router>
  );
}

export default Main;
