import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import {
  Form,
  Button,
  Descriptions,
  Dropdown,
  MenuProps,
  Space,
  Table,
  Tooltip,
  Typography,
  Input,
  Alert,
  Card,
  Avatar,
  Divider,
  Grid,
  Row,
  Col,
} from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Account from "../../../models/Account";
import { NavigationContext } from "../../../context/NavigationContext";
import { ToastContext } from "../../../context/ToastContext";
import AccountService from "../../../services/crud/AccountService";
import User from "../../../models/User";
import DescriptionDataList from "../../../components/DescriptionDataList";
import { useForm } from "antd/lib/form/Form";
import { OverlayContext } from "../../../context/OverlayContext";
import { QuestionCircleFilled } from "@ant-design/icons/lib/icons";
import Authorized, { getAuthorizedNotInUsersArray } from "../../../models/Authorized";
import InfoBox from "../../../components/InfoBox";
import SubscriptionService from "../../../services/crud/SubscriptionService";
import { ColumnType } from "antd/lib/table/interface";
import { EntitySelector } from "../../../components/forms/selects/EntitySelector";
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { SubscriptionsTable } from "./SubscriptionsTable";
import { PlansTable } from "./PlansTable";
import Hub from "../../../models/Hub";
import HubService from "../../../services/crud/HubService";
import UserService from "../../../services/crud/UserService";
import { Modes } from "../../../Modes";
import { PrivateAccountFormApplication } from "./PrivateAccountFormApplication";
import AddUserButton from "./AddUserButton";
import AddHubButton from "./AddHubButton";

export const hubsState = atom<Hub[]>({
  key: "real_estate_hubs",
  default: [],
});

export const accountState = atom<Account>({
  key: "real_estate_account",
  default: {} as Account,
});

const Header = () => {
  let navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate(route);
  };
  return (
    <div>
      <Button size="small" icon={<ArrowLeftOutlined />} type="link" onClick={() => gotoPage(-1)}>
        Back to real estates list
      </Button>
    </div>
  );
};

const ProfileSection = ({ account, actions = <></> }) => {
  const [form] = useForm();
  const { loading, loadMessage } = useContext(ToastContext);
  const { openModal, closeModal } = useContext(OverlayContext);
  const setAccount = useSetRecoilState(accountState);

  const handleEdit = () => {
    openEditModal();
  };
  const openEditModal = () => {
    form.resetFields();
    openModal({
      title: "Edit account profile",
      content: <EditProfileForm account={account} form={form} />,
      onOk: () => {
        form.validateFields().then((values) => {
          loadMessage("Adding item...", async () => {
            await AccountService.editAccount(account?.id, values);
            AccountService.getAccount(account?.id).then((account) => {
              setAccount(account);
            });
            return "Updated account profile";
          });
          closeModal();
        });
      },
    });
  };
  const data = { ...account };
  return (
    <div>
      <Card>
        <Typography.Title level={1}>{account?.name}</Typography.Title>
        <DescriptionDataList
          data={data}
          footer={
            <Space>
              <Button disabled={loading} type="primary" onClick={handleEdit}>
                Edit
              </Button>
              {/* <Button size="small" type="link">
              Transfer ownership
            </Button> */}
              {actions}
            </Space>
          }
        />
      </Card>
    </div>
  );
};

const EditProfileForm = ({ account, form }) => {
  return (
    <Form form={form} initialValues={account}>
      <Form.Item name="id" label="id" required>
        <Input disabled />
      </Form.Item>
      <Form.Item name="name" label="name" required>
        <Input />
      </Form.Item>
      <Tooltip title="Name of your company">
        <QuestionCircleFilled></QuestionCircleFilled>
      </Tooltip>
      <Form.Item name="whiteLabelId" label="White label">
        <EntitySelector.WhiteLabels></EntitySelector.WhiteLabels>
      </Form.Item>
    </Form>
  );
};

/**
 * This application is used to add users to an account.
 * It should check if the user is already in the account.
 * if the user is already in the account, it should not add the user and it should display a message. "User already in account"
 * @param param0
 * @returns
 */
const UserAccountFormApplication = ({ title = "Add user", accountId = "", onSuccess = () => {} }) => {
  const [form] = useForm<{ accountId: string | number; emails: string }>();
  const { loading, loadMessage } = useContext(ToastContext);
  const { openModal, closeModal } = useContext(OverlayContext);
  const initialData = { emails: "" };
  const openCreateModal = () => {
    form.resetFields();
    openModal({
      title: "Add emails to account",
      content: (
        <Form form={form} initialValues={initialData}>
          <Form.Item hidden name="id" label="id" required>
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="emails"
            label="Emails"
            rules={[
              {
                required: true,
                validator(rule, value, callback) {
                  if (value && value.length > 0) {
                    const emails = value.split(",").map((email) => email.trim());
                    const valid = emails.every((email) => {
                      return email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
                    });
                    if (valid) {
                      callback();
                    } else {
                      callback("Invalid email");
                    }
                  } else {
                    callback("Emails cannot be empty");
                  }
                },
              },
            ]}
          >
            <Input.TextArea placeholder={"email1@mail.com, email2@mail.com, ..."} />
          </Form.Item>

          <Tooltip title="Emails seperated by comma. For example:ec2b@example.com, email@example.com">
            <QuestionCircleFilled></QuestionCircleFilled>
          </Tooltip>
        </Form>
      ),
      onOk: () => {
        form.validateFields().then(async (values) => {
          const emails = values.emails.split(",").map((email) => email.trim());
          loadMessage("Adding emails", async () => {
            const result = await Promise.any(emails.map(async (email) => AccountService.pregrantEmailToAccount(accountId, email)));
            onSuccess();
          });
          closeModal();
        });
      },
    });
  };
  return (
    <Button type="primary" disabled={loading} onClick={openCreateModal}>
      {title}
    </Button>
  );
};

// const SubscribeFormApplication = ({ title = "Choose a plan subscribe to", accountId, onSuccess = () => {} }) => {
//   const [form] = useForm<{ planId: any; accountId: any }>();
//   const { loading, loadMessage } = useContext(ToastContext);
//   const { openModal, closeModal } = useContext(OverlayContext);
//   const initialData = { accountId };
//   const openCreateModal = () => {
//     form.resetFields();
//     openModal({
//       title,
//       content: (
//         <Form form={form} initialValues={initialData}>
//           <Form.Item hidden name="accountId" label="account" required>
//             <Input disabled />
//           </Form.Item>
//           <Form.Item name="planId" label="plan">
//             <EntitySelector.Plans></EntitySelector.Plans>
//           </Form.Item>
//         </Form>
//       ),
//       onOk: () => {
//         form.validateFields().then(async (values) => {
//           loadMessage("Subscribing", async () => {
//             SubscriptionService.addSubscription({ accountId: accountId, planId: values.planId });
//             onSuccess();
//           });
//           closeModal();
//         });
//       },
//     });
//   };
//   return (
//     <Button disabled={loading} type="link" onClick={openCreateModal}>
//       Subscribe
//     </Button>
//   );
// };

const AccountAuthorizedTable = ({ dataSource = undefined, id, onDeleteSelected = async (key) => {} }) => {
  const { user } = useContext(NavigationContext);
  const [authorized, setAuthorized] = useState<Authorized[]>([]);
  const [filter, setFilter] = useState("");
  const { loading, loadMessage } = useContext(ToastContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate("/companies/" + route);
  };

  function loadTable(id) {
    if (id) {
      // Load all accounts for user
      loadMessage("Loading users...", () =>
        Promise.all([AccountService.getAccount(id)]).then((data) => {
          setAuthorized(data[0]?.authorized);
        })
      );
    }
  }
  useEffect(() => {
    if (dataSource) setAuthorized(dataSource);
  }, [dataSource]);
  useEffect(() => {
    if (id) loadTable(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);

  const items: MenuProps["items"] = [
    { label: "Promote selected to administrator", key: "SET_SELECTED_TO_ADMIN" },
    { label: "Demote selected to user", key: "SET_SELECTED_TO_USER" },
    {
      label: "Remove selected from account",
      key: "REMOVE_SELECTED_FROM_ACCOUNT",
    },
  ];
  const onClick = async ({ key }) => {
    switch (key) {
      case "REMOVE_SELECTED_FROM_ACCOUNT":
        await onDeleteSelected(selectedRowKeys);
        loadTable(id);
        break;
      case "SET_SELECTED_TO_ADMIN":
        const setUserToAdmin = (email, accountId) => {
          return AccountService.updateAccountUser(email, accountId, { admin: true });
        };
        await Promise.allSettled(selectedRowKeys.map((email) => setUserToAdmin(email, id)));
        // const fulfilled = settled.map((result) => result.status === "fulfilled");
        // const rejected = settled.map((result) => result.status === "rejected");
        loadTable(id);
        break;
      case "SET_SELECTED_TO_USER":
        const setUserToUser = (email, accountId) => {
          return AccountService.updateAccountUser(email, accountId, { admin: false });
        };
        await Promise.allSettled(selectedRowKeys.map((email) => setUserToUser(email, id)));
        // const fulfilled = settled.map((result) => result.status === "fulfilled");
        // const rejected = settled.map((result) => result.status === "rejected");
        loadTable(id);
        break;
      // Update selected user account role
    }
  };

  const ActionsDropdown: React.FC<Props> = ({}) => (
    <Dropdown menu={{ items, onClick }} trigger={["click"]}>
      <a>
        <Space>
          Actions
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );

  const columns: ColumnType<Authorized>[] = [
    {
      title: "email",
      key: "email", // Used by UserProfile via columnFilter prop
      dataIndex: ["email"],
      render: (text, record, index) => {
        return <Tooltip title={`${text?.name ?? ""}`.trim()}>{text ?? ""}</Tooltip>;
      },
      // sorter: (a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
    },
    {
      title: "Role",
      key: "role",
      dataIndex: ["pivot", "admin"],
      render: (text, record, index) => {
        if (text === 1) {
          return <Typography.Text>{"admin"}</Typography.Text>;
        }
        if (text === 0) {
          return <Typography.Text>{"user"}</Typography.Text>;
        }
      },
    },
    {
      title: "Created at",
      key: "createdAt",
      dataIndex: ["createdAtAsDate"],
      render: (text, record, index) => {
        return <Typography.Text>{text}</Typography.Text>;
      },
      // sorter: (a, b) => a.balance - b.balance,
    },
  ];
  // Row selection
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Typography.Title level={3}>Invited users (not registered)</Typography.Title>
      <Space style={{ marginBottom: 10, justifyContent: "space-between", width: "100%" }}>
        <Space>
          <ActionsDropdown></ActionsDropdown>
          <span style={{ marginLeft: 8 }}> {`(Selected ${selectedRowKeys.length} items)`} </span>
        </Space>
        {/* <UserAccountFormApplication onSuccess={() => loadTable(id)} accountId={id} /> */}
      </Space>

      <Table
        loading={loading}
        bordered
        rowKey="email"
        dataSource={authorized}
        rowSelection={rowSelection}
        columns={columns}
        pagination={{ total: authorized?.length, defaultPageSize: 30, showSizeChanger: true }}
        // onRow={(record) => ({ onClick: () => gotoPage(record.id) })}
        // loading={loading}
      />
    </div>
  );
};

const AccountUsersTable = ({ dataSource = undefined, id, onDeleteSelected = async (key) => {} }) => {
  const { user, mode } = useContext(NavigationContext);
  const [users, setUsers] = useState<User[]>([]);
  const [filter, setFilter] = useState("");
  const { loading, loadMessage } = useContext(ToastContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate("/companies/" + route);
  };

  function loadTable(id) {
    if (id) {
      // Load all accounts for user
      loadMessage("Loading users...", () =>
        Promise.all([AccountService.getAccount(id)]).then((data) => {
          setUsers(data[0]?.users || []);
        })
      );
    }
  }
  useEffect(() => {
    if (dataSource) setUsers(dataSource);
  }, [dataSource]);
  useEffect(() => {
    if (id) loadTable(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);

  const items: MenuProps["items"] = [
    { label: "Promote selected to administrator", key: "SET_SELECTED_TO_ADMIN" },
    { label: "Demote selected to user", key: "SET_SELECTED_TO_USER" },
    {
      label: "Remove selected from account",
      key: "REMOVE_SELECTED_FROM_ACCOUNT",
    },
  ];
  const onClick = async ({ key }) => {
    switch (key) {
      case "REMOVE_SELECTED_FROM_ACCOUNT":
        await onDeleteSelected(selectedRowKeys);
        loadTable(id);
        break;
      case "SET_SELECTED_TO_ADMIN":
        const setUserToAdmin = (email, accountId) => {
          return AccountService.updateAccountUser(email, accountId, { admin: true });
        };
        await Promise.allSettled(selectedRowKeys.map((email) => setUserToAdmin(email, id)));
        // const fulfilled = settled.map((result) => result.status === "fulfilled");
        // const rejected = settled.map((result) => result.status === "rejected");
        loadTable(id);
        break;
      case "SET_SELECTED_TO_USER":
        const setUserToUser = (email, accountId) => {
          return AccountService.updateAccountUser(email, accountId, { admin: false });
        };
        await Promise.allSettled(selectedRowKeys.map((email) => setUserToUser(email, id)));
        // const fulfilled = settled.map((result) => result.status === "fulfilled");
        // const rejected = settled.map((result) => result.status === "rejected");
        loadTable(id);
        break;
      // Update selected user account role
    }
  };

  const ActionsDropdown: React.FC<Props> = ({}) => (
    <Dropdown menu={{ items, onClick }} trigger={["click"]}>
      <a>
        <Space>
          Actions
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );

  const columnns: ColumnType<User>[] = [
    {
      title: "Username",
      key: "username", // Used by UserProfile via columnFilter prop
      dataIndex: ["username"],
      render: (text, record, index) => {
        return <Tooltip title={`${text ?? ""}`.trim()}>{text ?? ""}</Tooltip>;
      },
      // sorter: (a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
    },
    {
      title: "Name",
      key: "name",
      dataIndex: ["firstName"],
      render: (text, record, index) => {
        return <Typography.Text>{text ? record?.firstName + " " + record?.lastName : "user not registered yet"}</Typography.Text>;
      },
      // sorter: (a, b) => a?.type?.toLowerCase().localeCompare(b?.type?.toLowerCase()),
    },
    {
      title: "Role",
      key: "role",
      dataIndex: ["pivot", "admin"],
      render: (text, record, index) => {
        if (text === 1) {
          return <Typography.Text>{"admin"}</Typography.Text>;
        }
        if (text === 0) {
          return <Typography.Text>{"user"}</Typography.Text>;
        }
      },
    },

    {
      title: "Created at",
      key: "createdAt",
      dataIndex: ["createdAtAsDate"],
      render: (text, record, index) => {
        return <Typography.Text>{text}</Typography.Text>;
      },
      // sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: "Last logged in",
      dataIndex: ["lastLoginAtAsDate"],
      render: (text, record, index) => {
        return <Typography.Text>{text}</Typography.Text>;
      },
      // sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: "Actions",
      dataIndex: ["actions"],
      key: "actions",
      render: (text, record, index) => {
        return (
          <>
            {mode === Modes.SYSTEM_ADMIN && record?.isRegistered && (
              <PrivateAccountFormApplication title={record.username + "'s" + " private account"} id={record.id} />
            )}
          </>
        );
      },
    },
  ];

  // Row selection
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Typography.Title level={3}>Users</Typography.Title>
      <Space style={{ marginBottom: 10, justifyContent: "space-between", width: "100%" }}>
        <Space>
          <ActionsDropdown></ActionsDropdown>
          <span style={{ marginLeft: 8 }}> {`(Selected ${selectedRowKeys.length} items)`} </span>
        </Space>
        <AddUserButton
          accountId={id}
        ></AddUserButton>
      </Space>

      <Table
        loading={loading}
        bordered
        rowKey="username"
        dataSource={users}
        rowSelection={rowSelection}
        columns={columnns}
        pagination={{ total: users.length, defaultPageSize: 30, showSizeChanger: true }}
        // onRow={(record) => ({ onClick: () => gotoPage(record.id) })}
        // loading={loading}
      />
    </div>
  );
};

const HubsTable = ({ dataSource = undefined, id, onDeleteSelected = async (key) => {} }) => {
  const { user, mode } = useContext(NavigationContext);

  const [hubs, setHubs] = useRecoilState(hubsState);
  const [filter, setFilter] = useState("");
  const { loading, loadMessage } = useContext(ToastContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate("/companies/" + route);
  };

  function loadTable(id) {
    if (id) {
      // Load all accounts for user
      loadMessage("Loading users...", () =>
        Promise.all([HubService.list({ accountId: id })]).then((data) => {
          setHubs(data[0]);
        })
      );
    }
  }
  useEffect(() => {
    if (dataSource) setHubs(dataSource);
  }, [dataSource]);
  useEffect(() => {
    if (id) loadTable(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);

  const items: MenuProps["items"] = [
    {
      label: "Remove selected from account",
      key: "REMOVE_SELECTED_FROM_ACCOUNT",
    },
  ];
  const onClick = async ({ key }) => {
    switch (key) {
      case "REMOVE_SELECTED_FROM_ACCOUNT":
        await onDeleteSelected(selectedRowKeys);

        const results = await Promise.allSettled(
          selectedRowKeys.map((hubId) => {
            return AccountService.removeHub(id, `${hubId}`).then((data) => {
              return { data, id: hubId };
            });
          })
        );
        const success = results.filter((result) => result.status === "fulfilled").map((item: any) => item.value.id);
        const failed = results.filter((result) => result.status === "rejected");
        setHubs((prev) => prev.filter((hub) => !success.find((id) => id === hub.id)));
        console.log(success);
        console.log(failed);
        break;
    }
  };

  const columnns: ColumnType<Hub>[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: ["name"],
      render: (text, record, index) => {
        return <Typography.Text>{text}</Typography.Text>;
      },
      // sorter: (a, b) => a?.type?.toLowerCase().localeCompare(b?.type?.toLowerCase()),
    },
    {
      title: "Address",
      key: "address",
      dataIndex: ["address"],
      render: (text, record, index) => {
        return <Typography.Text>{text}</Typography.Text>;
      },
      // sorter: (a, b) => a?.type?.toLowerCase().localeCompare(b?.type?.toLowerCase()),
    },
  ];

  // Row selection
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  console.log(hubs);
  let actionsDropdown = <></>;
  switch (mode) {
    case Modes.SYSTEM_ADMIN:
      actionsDropdown = (
        <Dropdown menu={{ items, onClick }} trigger={["click"]}>
          <a>
            <Space>
              Actions
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      );
      break;
    case Modes.COMPANY_ADMIN:
      actionsDropdown = (
        <Dropdown disabled menu={{}} trigger={["click"]}>
          <a>
            <Space>
              Actions
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      );
      break;
  }
  return (
    <div>
      <Typography.Title level={3}>Hubs</Typography.Title>
      <Space style={{ marginBottom: 10, justifyContent: "space-between", width: "100%" }}>
        <Space>
          {actionsDropdown}
          <span style={{ marginLeft: 8 }}> {`(Selected ${selectedRowKeys.length} items)`} </span>
        </Space>
        <AddHubButton accountId={id}></AddHubButton>
      </Space>

      <Table
        loading={loading}
        bordered
        rowKey="id"
        dataSource={hubs}
        rowSelection={rowSelection}
        columns={columnns}
        pagination={{ total: hubs.length, defaultPageSize: 30, showSizeChanger: true }}
        // onRow={(record) => ({ onClick: () => gotoPage(record.id) })}
        // loading={loading}
      />
    </div>
  );
};

export type Props = {};
/**
 * This page lists all company accounts.
 * @param param0
 * @returns
 */
function RealStateAccountsView({}: Props) {
  const { mode } = useContext(NavigationContext);
  const { id } = useParams<any>();
  const accountId = parseInt(id);
  const [account, setAccount] = useRecoilState(accountState);
  const { loading, loadMessage } = useContext(ToastContext);
  const users = [
    ...(account?.users ?? []),
  ].sort((a, b) => a.username.toLowerCase().localeCompare(b.username.toLowerCase()));
  const authorized = getAuthorizedNotInUsersArray(
    account?.authorized ?? [], account?.users ?? [],
  ).sort((a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()));

  const loadAccount = useCallback(async () => {
    if (accountId)
      return AccountService.getAccount(accountId).then((account) => {
        setAccount(account);
      });
  }, [accountId]);

  useEffect(() => {
    loadMessage("Loading account...", () => loadAccount().then(() => "account loaded"));
  }, [accountId]);
  const handleDelete = (selectedRowKeys) => {
    return Promise.any(selectedRowKeys.map((key) => AccountService.removeEmailFromAccount(id, key + ""))).then(() => loadAccount());
  };
  let profileActions = <></>;
  switch (mode) {
    case Modes.SYSTEM_ADMIN:
      profileActions = (
        <>
          <></>
        </>
      );
      break;
    case Modes.COMPANY_ADMIN:
      profileActions = (
        <></>
      );
      break;

    case Modes.REAL_ESTATE_ADMIN:
      profileActions = (
        <></>
      );
      break;
  }

  return (
    <div>
      <Header></Header>
      <ProfileSection actions={profileActions} account={account}></ProfileSection>
      <div>
        <AccountUsersTable onDeleteSelected={handleDelete} dataSource={users} id={account?.id}></AccountUsersTable>
        <AccountAuthorizedTable onDeleteSelected={handleDelete} dataSource={authorized} id={account?.id}></AccountAuthorizedTable>
        {/* <SubscriptionsTable id={account?.id}></SubscriptionsTable> */}
        {/* <PlansTable id={account?.id}></PlansTable> */}
        <HubsTable id={account?.id}></HubsTable>
      </div>
    </div>
  );
}

// const PlanCard: React.FC<{ plan?: Plan }> = ({ plan }) => {
//   const price = "299kr / mån";
//   const title = "EC2B - Enterprise";
//   return (
//     <Card
//       style={{ width: 300 }}
//       title={title}
//       actions={[
//         <Button type="primary" size="large">
//           Subscribe
//         </Button>,
//       ]}
//     >
//       <Typography.Paragraph>Allt i Basic</Typography.Paragraph>
//     </Card>
//   );
// };

export default RealStateAccountsView;
