import BaseModel from "./BaseModel";
import Tsp from "./Tsp";
import Plan from "./Plan";

class Product extends BaseModel {
  name: string;
  description: string | null;
  physical: boolean;
  productCode: string;

  // plan_product
  topUpYield: number | null;
  topUpMaxAccumulatedCount: number | null;
  topUpWarnAt: number | null;
  pricePerPcInclVat: number | null;
  pricePerPcVatFraction: number | null;
  pricePerExcessPcInclVat: number | null;
  pricePerExcessPcVatFraction: number | null;

  // product_subscription
  balance: number | null;

  tsp: Tsp | any;
  plan: Plan | any;
  tspId: number;
  planId: number;
  constructor(data) {
    super(data, []);
    this.name = data.name;
    this.description = data.description ?? "No description available";
    this.physical = data.physical ?? false;
    this.productCode = data.productCode;
    this.topUpYield = data.topUpYield ?? null;
    this.topUpMaxAccumulatedCount = data.topUpMaxAccumulatedCount ?? null;
    this.topUpWarnAt = data.topUpWarnAt ?? null;
    this.pricePerPcInclVat = data.pricePerPcInclVat ?? null;
    this.pricePerPcVatFraction = data.pricePerPcVatFraction ?? null;
    this.pricePerExcessPcInclVat = data.pricePerExcessPcInclVat ?? null;
    this.pricePerExcessPcVatFraction = data.pricePerExcessPcVatFraction ?? null;
    this.tsp = data.tsp ? new Tsp(data.tsp) : {};
    this.plan = data.plan ? new Plan(data.plan) : {};
    this.tspId = this.tsp?.id;
    this.planId = this.plan?.id;

    this.balance = data.balance ?? null;
    // this.balance = data.pivot?.balance ?? null;

    // if (typeof data.pivot != "undefined") {
    //   this.topUpMaxAccumulatedCount = data.topUpMaxAccumulatedCount ?? 1;
    //   this.topUpWarnAt = data.topUpWarnAt ?? 0;
    //   this.pricePerPcInclVat = data.pricePerPcInclVat ?? 0;
    //   this.pricePerExcessPcInclVat = data.pricePerExcessPcInclVat ?? 0;
    //   this.balance = data.balance;
    // }
  }
}

export default Product;
