import Button from "antd/lib/button";
import React, { useContext } from "react";
import { NavigationContext } from "../../context/NavigationContext";
import { ToastContext } from "../../context/ToastContext";

type Props = {};

function UserLoginButton({}: Props) {
  const { login } = useContext(NavigationContext);
  const { loadMessage } = useContext(ToastContext);

  const handleLogin = () => {
    loadMessage("Logging in as user", () =>
      login("test@mithings.se", "testPassword")
        .then((user) => {
          return "Successfully logged in";
        })
        .catch((res) => {
          throw Error("Wrong username and/or password");
        })
    );
  };

  return <Button onClick={handleLogin}>Login as user</Button>;
}

export default UserLoginButton;
