import React from "react"; // eslint-disable-line @typescript-eslint/no-unused-vars
import { Typography, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

export default (props) => {

    const content = props.content ?? "";
    const title = props.title ?? "";
    const tooltip = props.tooltip ?? null;

    return <div className="sub-form">
        {
            title &&
            <Typography.Paragraph style={{position: "relative", top: -12, marginBottom: 0}}>{title}</Typography.Paragraph>
        }
        {
            tooltip &&
            <Tooltip title={tooltip}>
                <QuestionCircleOutlined style={{marginTop: "-10px"}} />
            </Tooltip>
        }
        {content}
    </div>
} 
