import Tsp from "../../models/Tsp";
import HttpClient from "../../clients/HttpClient";
import { TspKeyType } from "../../models/tspKeyEnum";

export default class TspService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async list(params = {}): Promise<Array<Tsp>> {
    return this.httpClient.get("/tsps", params).then((res) => {
      return res.data.map((row) => new Tsp(row));
    });
  }

  public static async getTsp(id): Promise<Tsp> {
    return this.httpClient.get(`/tsps/${id}`).then((res) => {
      return new Tsp(res.data);
    });
  }

  // QUICKFIX: get tsp by tsp key 
  public static async getTspByKey(key:TspKeyType): Promise<Tsp> {
    try {
      return this.httpClient.get(`/tsps/key/${key}`).then((res) => {
        return new Tsp(res.data);
      });
    } 
    catch (error) {
      console.log("error", error )
    }
    // Fallback to list and find tsp by key
    return this.list().then((res) => {
      return res.find((tsp) => tsp.tspKey === key);
    });
  } 

  public static async addTsp(data): Promise<Tsp> {
    return this.httpClient.post(`/tsps`, data).then((res) => {
      return new Tsp(res.data);
    });
  }

  public static async editTsp(id, data): Promise<Tsp> {
    return this.httpClient.put(`/tsps/${id}`, data).then((res) => {
      return new Tsp(res.data);
    });
  }

  public static async deleteTsp(id): Promise<boolean> {
    return this.httpClient.delete(`/tsps/${id}`, null).then((res) => {
      return true;
    });
  }
}

export const TSP_MOCK_DATA = [
  {
    createdAt: "2022-12-06T13:56:00",
    deletedAt: null,
    description: "cykel med flex",
    fallbackAndroid: "",
    fallbackIos: "",
    id: 1,
    image: "",
    logo: "",
    name: "EC2B Cykelbokning",
    phoneNumber: "153445",
    updatedAt: "2022-12-06T13:56:00",
    urlSchemeAndroid: "com.mithings.monilitybyec2b",
    urlSchemeIos: "com.mithings.mobilitybyec2b",
    website: "www.EC2B.se",
  },
  {
    createdAt: "2022-12-06T13:56:00",
    deletedAt: null,
    description: "flyger fram",
    fallbackAndroid: "https://play.google.com/store/apps/details?id=com.ourgreencar.booking",
    fallbackIos: "https://apps.apple.com/se/app/ourgreencar/id1496004291",
    id: 2,
    image: "",
    logo: "",
    name: "OurGreenCar",
    phoneNumber: "1223445",
    updatedAt: "2022-12-06T13:56:00",
    urlSchemeAndroid: "ourgreencar://",
    urlSchemeIos: "ourgreencar://",
    website: "www.OurGreenCar.se",
  },
  {
    createdAt: "2022-12-06T13:56:00",
    deletedAt: null,
    description: "rullar fram",
    fallbackAndroid: "https://play.google.com/store/apps/details?id=se.skanetrafiken.washington",
    fallbackIos: "https://apps.apple.com/se/app/sk%C3%A5netrafiken/id1180539331",
    id: 3,
    image: "",
    logo: "",
    name: "Skånetrafiken",
    phoneNumber: "1223345",
    updatedAt: "2022-12-06T13:56:00",
    urlSchemeAndroid: "se.skanetrafiken.washington",
    urlSchemeIos: "skanetrafiken://",
    website: "www.Skånetrafiken.se",
  },
  {
    createdAt: "2022-12-06T13:56:00",
    deletedAt: null,
    description: "Cykelleverantör",
    fallbackAndroid: "https://play.google.com/store/apps/details?id=se.skanetrafiken.washington",
    fallbackIos: "https://apps.apple.com/se/app/sk%C3%A5netrafiken/id1180539331",
    id: 10001,
    image: "",
    logo: "",
    name: "EC2B Cykel",
    phoneNumber: "1223345",
    updatedAt: "2022-12-06T13:56:00",
    urlSchemeAndroid: "se.skanetrafiken.washington",
    urlSchemeIos: "skanetrafiken://",
    website: "https://ec2b.se/",
  },
  {
    createdAt: "2022-12-06T13:56:00",
    deletedAt: null,
    description: "Kollektivtrafik",
    fallbackAndroid: "https://play.google.com/store/apps/details?id=se.skanetrafiken.washington",
    fallbackIos: "https://apps.apple.com/se/app/sk%C3%A5netrafiken/id1180539331",
    id: 10002,
    image: "",
    logo: "",
    name: "Skånetrafiken",
    phoneNumber: "1223345",
    updatedAt: "2022-12-06T13:56:00",
    urlSchemeAndroid: "se.skanetrafiken.washington",
    urlSchemeIos: "skanetrafiken://",
    website: "https://ec2b.se/",
  },
  {
    createdAt: "2022-12-06T13:56:00",
    deletedAt: null,
    description: "Bilpool",
    fallbackAndroid: "https://play.google.com/store/apps/details?id=se.skanetrafiken.washington",
    fallbackIos: "https://apps.apple.com/se/app/sk%C3%A5netrafiken/id1180539331",
    id: 10003,
    image: "",
    logo: "",
    name: "MoveAbout",
    phoneNumber: "1223345",
    updatedAt: "2022-12-06T13:56:00",
    urlSchemeAndroid: "se.skanetrafiken.washington",
    urlSchemeIos: "skanetrafiken://",
    website: "https://MoveAbout.se/",
  },
];
