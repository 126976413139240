import ResourceService from "./crud/ResourceService";
import { NOT_IMPLEMENTED_EXCEPTION } from "./Exceptions";

export class AdminService {
  /**
   * Move a resource from a hub to another hub.
   * @param hubId
   * @param resourceId
   * @returns
   */
  static async moveResourceToHub(hubId, resourceId) {
    return ResourceService.editResource(resourceId, { hubId });
  }

  /**
   * Move selected resource from a hub to another hub
   * @param hubId
   * @param resourcesIds
   * @returns
   */
  static async moveResourcesToHub(hubId, resourcesIds) {
    return Promise.all(resourcesIds.map((resourceId) => this.moveResourceToHub(hubId, resourceId)));
  }

  /**
   * Gives an account access to a hub
   * @param hubId
   * @param accountId
   */
  static async addAccountToHub(hubId, accountId) {
    throw NOT_IMPLEMENTED_EXCEPTION;
  }
  /**
   * Adds a product to plan
   * @param planId
   * @param productId
   */
  static async addProductToPlan(planId, productId) {
    throw NOT_IMPLEMENTED_EXCEPTION;
  }

  /**
   * Subscribes account to a plan
   * @param accountId
   * @param planId
   */

  static async subscribeToPlan(accountId, planId) {
    throw NOT_IMPLEMENTED_EXCEPTION;
  }

  /**
   * Enables anyone to register with specified email
   * @param user
   */
  static async preAuthorizeEmail(email) {
    throw NOT_IMPLEMENTED_EXCEPTION;
  }

  /**
   * Adds new preauthorized user
   */

  static async preAuthorizeUser(email) {
    throw NOT_IMPLEMENTED_EXCEPTION;
  }
}
