import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContext } from "../../../context/ToastContext";
import { Table, Typography, Button, Form, List, Tag, Tooltip } from "antd";
import BookingsTable from "../../../components/tables/BookingsTable";
import UserService from "../../../services/crud/UserService";
import Resource, { resourceLabel, resourceSubtitle } from "../../../models/Resource";
import Location from "../../../models/Location";
import User from "../../../models/User";
import ResourceService from "../../../services/crud/ResourceService";
import HubService from "../../../services/crud/HubService";
import Hub from "../../../models/Hub";
import { OverlayContext } from "../../../context/OverlayContext";
import PlanForm from "../../../components/forms/PlanForm";
import ResourceForm from "../../../components/forms/ResourceForm";
import { ViewTitle } from "../../../components/ViewTitle";
import DescriptionList from "../../../components/DescriptionList";
import { DescriptionListItem } from "../../../components/DescriptionListItem";
import { formatPriceInCents, underScoreFilter } from "../../../utils/common";
import DescriptionListItems from "../../../components/DescriptionListItems";
import DescriptionDataList from "../../../components/DescriptionDataList";
import { GoBackButton } from "../../../components/GoBackButton";

function ResourceView(props) {
  let navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate(route);
  };
  const { id } = useParams<any>();
  const [resource, setResource] = useState<Resource | undefined>(new Resource({}));
  const [hub, setHub] = useState<Hub | undefined>();
  const { loading, loadMessage, showMessage } = useContext(ToastContext);
  const { openModal, closeModal } = useContext(OverlayContext);
  const [form] = Form.useForm();

  const handleEdit = async () => {
    openUpdateModal(resource);
  };
  useEffect(() => {
    loadMessage("Loading resource...", async () => {
      const res = await ResourceService.getResource(id);
      setResource(res);
      const hub = await HubService.getHub(res.hubId);
      setHub(hub);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openUpdateModal = (resource = null) => {
    form.resetFields();
    openModal({
      title: "Update resource",
      content: <ResourceForm id={resource.id} form={form} update={true} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          const newResource = new Resource(values);
          closeModal();
          loadMessage("Updating resource...", () =>
            ResourceService.editResource(newResource.id, newResource)
              .then((resource) => {
                setResource(resource);
                return "Resource updated";
              })
              .catch((res) => {
                console.log(res);
                throw Error("Could not update resource");
              })
          );
        });
      },
    });
  };
  return (
    <div>
      <GoBackButton></GoBackButton>
      <ViewTitle title={resourceLabel(resource)}></ViewTitle>
      <DescriptionDataList
        data={{
          ...resource,
          'priceIncVatCents': undefined,
          'price': `${formatPriceInCents(resource.priceIncVatCents, 'h')} incl. VAT`,
        }}
        footer={
          <Button size="small" type="link" onClick={handleEdit}>
            Edit resource
          </Button>
        }
      ></DescriptionDataList>
      <div>
        <div>
          <Typography.Text style={{ marginLeft: 10 }}>{resourceSubtitle(resource)}</Typography.Text>
        </div>
        <div style={{ marginTop: "1em" }}>
          <Typography.Text style={{ marginLeft: 10 }}>{hub?.description ?? ""}</Typography.Text>
        </div>
        <div style={{ marginTop: "1em" }}>
          <Typography.Text style={{ marginLeft: 10 }}>{resource?.macAddress ?? ""}</Typography.Text>
        </div>
      </div>

      {/* BOOKINGS */}
      <Typography.Title level={3} style={{ marginTop: 40, marginLeft: 10 }}>
        Bookings
      </Typography.Title>
      <BookingsTable
        dataFilter={(b) => b.resourceId === parseInt(id)}
        columnFilter={(col) => col.key !== "resourceId" && col.key !== "resourceId.hubId"}
      />
    </div>
  );
}

export default ResourceView;
