import React from 'react';
import { Select, SelectProps } from 'antd';
import { UnifiedBookingsTableData } from './BookingsTable';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';

const options: SelectProps['options'] = [];

interface NameDropDownListProps {
  options: { label: string; value: string }[];
  onChange: (selectedOptions: { label: string; value: string }[]) => void;
  data: UnifiedBookingsTableData[];
}

const NameDropDownList: React.FC<NameDropDownListProps> = ({ onChange, data }) => {

  const uniqueNamesSet = new Set<string>();

  const antOptions = data
    .map((user) => {
      const fullName = `${user.firstName} ${user.lastName}`;
      const value = `${user.firstName} ${user.lastName}`;

      // Check if the name is already in the Set
      if (!uniqueNamesSet.has(value)) {
        uniqueNamesSet.add(value);
        return { label: fullName, value };
      }

      return null;
    })
    .filter(Boolean); // Remove duplicates-nulls

  const handleChange = (selectedValues: string[]) => {
    // Call the external onChange prop with the selected values
    onChange(antOptions.filter(option => selectedValues && selectedValues.includes(option.value)));
  };

  return (
    <Select
      /* mode="multiple" */
      allowClear
      placeholder="User"
      defaultValue={[]}
      onChange={handleChange}
      options={antOptions}
      suffixIcon={<UserOutlined/>}
    />
  );
};

export default NameDropDownList;
