import moment from "moment";

export const formatDateTime = (date: Date | string | undefined, format: string = "YYYY-MM-DD HH:mm:ss"): string => {
  return moment(new Date(date).toLocaleString())?.format(format);
};

export const formatDate = (date: Date | string | undefined, format: string = "YYYY-MM-DD"): string => {
  return moment(new Date(date).toLocaleString())?.format(format);
};

/**
 * Return a Date with time portion set to be 00:00:00 at the local timezone of the client.
 * @param date
 * @returns
 */
export function dateStartOfLocalDay(date: Date | string | undefined): Date {
  const d = new Date(date);
  d.setHours(0);
  d.setMinutes(0);
  d.setHours(0);
  d.setMilliseconds(0);
  return d;
}

/**
 * Return a Date with time portion set to be 23:59:59 at the local timezone of the client.
 * @param date
 * @returns
 */
export function dateEndOfLocalDay(date: Date | string | undefined): Date {
  const d = new Date(date);
  d.setHours(23);
  d.setMinutes(59);
  d.setHours(59);
  d.setMilliseconds(999);
  return d;
}