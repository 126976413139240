import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OverlayContext } from "../../../context/OverlayContext";
import { ToastContext } from "../../../context/ToastContext";
import { NavigationContext } from "../../../context/NavigationContext";
import { Table, Typography, Button, Form, List, Tag, Tooltip } from "antd";
import { ArrowLeftOutlined, StopOutlined } from "@ant-design/icons";
import PlanService from "../../../services/crud/PlanService";
// import PlanForm from "../components/PlanForm";
// import DeleteForm from "../components/DeleteForm";
import Plan from "../../../models/Plan";
import PlanForm from "../../../components/forms/PlanForm";

import * as _ from "lodash";

import { ViewTitle } from "../../../components/ViewTitle";
import DescriptionDataList from "../../../components/DescriptionDataList";
import ProductsTable from "../../../components/tables/ProductsTable";
import Product from "../../../models/Product";
import Subscription from "../../../models/Subscription";
import { GenericSubscriptionsTable } from "../../../components/tables/SubscriptionsTable";
import { GenericPlansTable } from "../../../components/tables/PlansTable";
import { GenericPlanProductProductsTable } from "../../../components/tables/PlanProductsTable";
import { GoBackButton } from "../../../components/GoBackButton";
/**
 * @param props
 * @returns
 */
function PlanView(props) {
  let navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate(route);
  };
  const { id } = useParams<string>();
  const [plan, setPlan] = useState<Plan>(new Plan({}));
  // const gotoPage = useContext(NavigationContext)["gotoPage"];
  const { openModal, closeModal } = useContext(OverlayContext);
  const { loading, loadMessage, showMessage } = useContext(ToastContext);
  const [form] = Form.useForm();
  // const [, updateState] = React.useState();
  // const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    loadMessage("Loading plan...", () =>
      Promise.all([PlanService.getPlan(id)]).then((data) => {
        setPlan(data[0]);
        return true;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openPlanUpdateModal = (plan = null) => {
    openModal({
      title: "Update plan",
      content: <PlanForm id={plan.id} form={form} update={true} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          closeModal();
          loadMessage("Updating plan", () =>
            PlanService.editPlan(
              values.id,
              _.pickBy(values, (x) => x !== null)
            )
              .then((plan) => {
                setPlan(plan);
                return "Plan updated";
              })
              .catch((res) => {
                throw Error("Could not update plan");
              })
          );
        });
      },
    });
  };
  // Filters
  const handleEdit = () => {
    openPlanUpdateModal(plan);
  };
  const title = plan.name;
  const productDataFilter = (data: Product) => {
    return data.planId === plan.id;
  };
  const subscriptionDataFilter = (data: Subscription) => {
    return data.planId === plan.id;
  };

  return (
    <div style={{ position: "relative" }}>
      <GoBackButton></GoBackButton>

      {/* USER DATA */}
      <div>
        <ViewTitle title={title}></ViewTitle>
        <DescriptionDataList
          data={plan}
          footer={
            <Button size="small" type="link" onClick={handleEdit}>
              Edit plan
            </Button>
          }
        />
      </div>
      <GenericPlanProductProductsTable dataFilter={(data) => data.planId + "" == id}></GenericPlanProductProductsTable>
      <GenericSubscriptionsTable dataFilter={subscriptionDataFilter}></GenericSubscriptionsTable>
    </div>
  );
}

export default PlanView;
