import React, { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../context/ToastContext";
// import moment from "moment";
import { Form, Input, Spin, Typography, Tooltip, Button, AutoComplete, Tag, Switch, DatePicker, InputNumber } from "antd";
import UserService from "../../services/crud/UserService";
import PlanService from "../../services/crud/PlanService";
import { LoadingOutlined, QuestionCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import SubscriptionService from "../../services/crud/SubscriptionService";
import Subform from "../Global/Subform";
// import User from "../models/User";
import Subscription from "../../models/Subscription";
import Product from "../../models/Product";

export default function SubscriptionForm(props) {
  const id = props.id;
  const form = props.form;
  const update: boolean = props.update ?? false;
  const [subscription, setSubscription] = useState<Subscription>({} as Subscription);
  const [users, setUsers] = useState([]);
  const [plans, setPlans] = useState([]);
  const [tspId, setTspId] = useState();
  const [products, setProducts] = useState([]);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [productsSum, setProductsSum] = useState({});
  const [fileteredUsers, setFilteredUsers] = useState([]);
  const [fileteredSharedUsers, setFilteredSharedUsers] = useState([]);
  const [fileteredPlans, setFilteredPlans] = useState([]);
  const [pauseMaxCountPerYear, setPauseMaxCountPerYear] = useState(0);
  // const [nextTopUp, setNextTopUp] = useState("unknown");
  const { loading, loadMessage } = useContext(ToastContext);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState(undefined), []);

  useEffect(() => {
    form.resetFields();
    loadMessage("Loading subscription...", () => {
      if (id) {
        return Promise.all([SubscriptionService.getSubscription(id), UserService.list(), PlanService.list()]).then((data) => {
          setSubscription(data[0]);
          setUsers(data[1]);
          setPlans(data[2]);
          setProducts(data[0].serialize()["products"]);
          setSharedUsers(data[0]["sharedWith"]);
          setPauseMaxCountPerYear(data[0]["plan"]["pauseMaxCountPerYear"]);
          // calculateNextTopUp(data[0]);

          const psum = { balance: 0, topUpYield: 0 };
          (data[0].serialize()["products"] as Array<Product>).forEach((product) => {
            psum["balance"] += product.balance;
            psum["topUpYield"] += product.topUpYield;
          });
          setProductsSum(psum);
        });
      } else {
        return Promise.all([UserService.list(), PlanService.list()]).then((data) => {
          setUsers(data[0]);
          setPlans(data[1]);
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, id]);

  // const calculateNextTopUp = (subscription) => {
  //   const now = moment();
  //   // const subscribedAt = moment("2020-01-21");
  //   const subscribedAt = update ? moment(subscription["subscribedAt"]) : moment();
  //   const topUpCycle = parseInt(subscription.plan.topUpCycle);
  //   const cyclesSinceSubscription = Math.ceil(now.diff(subscribedAt, "months") / topUpCycle);
  //   const nextTopUpAt = subscribedAt.add(cyclesSinceSubscription * topUpCycle + 1, "months").format("YYYY-MM-DD");

  //   // @TODO logic for paused
  //   setNextTopUp(nextTopUpAt);
  // }

  const updateUserId = (value) => {
    const selectedUser = users.find((user) => `${user.username}` === value);
    subscription.userId = selectedUser.id;
    form.setFieldsValue({ userId: selectedUser.id });
    // const userId = users.find(user => user.username === value).id;
  };

  const mapUsersToFilterList = (value: string) => {
    const mapUser = (user) => {
      return {
        key: user.id,
        value: user.username,
        label: (
          <div key={user.id}>
            <Button size="small" type="link">
              {user.username}
            </Button>
            ({user.firstName} {user.lastName}, last login at {user.lastLoginAt ?? <em>never</em>})
          </div>
        ),
      };
    };
    const formUserId = form.getFieldValue("userId");
    const filteredList =
      value && value.length > 0
        ? users
            .filter((user) => user.username.toLowerCase().indexOf(value.toLowerCase()) > -1 && parseInt(formUserId) !== user.id)
            .map((user) => mapUser(user))
        : users.filter((user) => formUserId !== user.id).map((user) => mapUser(user));
    return filteredList;
  };
  const filterUsers = (value: string) => setFilteredUsers(mapUsersToFilterList(value));
  const filterSharedUsers = (value: string) => setFilteredSharedUsers(mapUsersToFilterList(value));

  const changePlan = (value) => {
    const selectedPlan = plans.find((plan) => `${plan.name} (${plan.id})` === value);
    subscription.planId = selectedPlan.id;
    form.setFieldsValue({ planId: selectedPlan.id });
    setPauseMaxCountPerYear(selectedPlan.pauseMaxCountPerYear);
    setProducts(selectedPlan.products);
    setTspId(selectedPlan.tspId);
    forceUpdate();
  };

  const filterPlans = (value: string) => {
    setFilteredPlans(
      plans
        .filter((plan) => (value ? plan.name.indexOf(value) > -1 : true))
        .map((plan) => {
          return {
            key: plan.id,
            value: `${plan.name} (${plan.id})`,
            label: (
              <div key={plan.id}>
                <Button size="small" type="link">
                  {plan.name}
                </Button>
                ({plan.tsp.name}, starting at {plan.priceInclVat + plan.priceStartInclVat} SEK)
              </div>
            ),
          };
        })
    );
  };

  const pauseSubscription = () => {
    const usedPauses = subscription.usedPauses;
    const maxPauses = subscription.plan.pauseMaxCountPerYear;
    loadMessage("Pausing subscription...", () =>
      SubscriptionService.pauseSubscription(id)
        .then((s) => {
          subscription.usedPauses = s.usedPauses;
          subscription.paused = true;
          setSubscription(subscription);
          forceUpdate();
          return "Subscription paused";
        })
        .catch((res) => {
          subscription.paused = false;
          forceUpdate();
          if (usedPauses === maxPauses) {
            throw Error("Could not pause subscription because max pauses is reached");
          } else {
            throw Error("Could not pause subscription");
          }
        })
    );
  };

  const unpauseSubscription = () => {
    loadMessage("Unpausing subscription...", () =>
      SubscriptionService.unpauseSubscription(id)
        .then((subscription) => {
          subscription.paused = false;
          setSubscription(subscription);
          forceUpdate();
          return "Subscription unpaused";
        })
        .catch((res) => {
          subscription.paused = true;
          forceUpdate();
          throw Error("Could not unpaused subscription");
        })
    );
  };

  const changeUsedPauses = (pausesRemaining) => {
    if (pausesRemaining) {
      form.setFieldsValue({ usedPauses: pauseMaxCountPerYear - parseInt(pausesRemaining) });
    }
  };

  const changeProductBalance = (productBalance, i) => {
    if (productBalance !== "") {
      products[i].balance = productBalance;
      form.setFieldsValue({ products: products });
    }
  };

  const addUserToNewSubscription = (username: string) => {
    const selectedUser = users.find((user) => (user.username = username));
    const newSharedUsers = [...sharedUsers, selectedUser];
    setSharedUsers(newSharedUsers);
    form.setFieldsValue({ sharedWith: newSharedUsers });
  };

  const shareSubscription = (username: string) => {
    loadMessage("Sharing subscription with user...", () =>
      SubscriptionService.shareSubscription(id, username)
        .then((subscription) => {
          setSharedUsers(subscription.sharedWith);
          forceUpdate();
          return "Subscription shared with user";
        })
        .catch((res) => {
          throw Error("Could not share subscription with user");
        })
    );
  };

  const unshareSubscription = (e) => {
    const username = e.target.parentElement.parentElement.children[0].dataset.shareduser;
    loadMessage("Removing subscription from user...", () =>
      SubscriptionService.unshareSubscription(id, username)
        .then((subscription) => {
          // const i = sharedUsers.findIndex(item => item.username === username)
          // sharedUsers.splice(i, 1);
          // setSharedUsers(sharedUsers);
          setSharedUsers(subscription.sharedWith);
          forceUpdate();
          return "Removed user from subscription";
        })
        .catch((res) => {
          throw Error("Could not remove user from subscription");
        })
    );
  };

  const content = (
    <Form initialValues={{ ...subscription, pickup: true }} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item noStyle name="userId">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item noStyle name="planId">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item noStyle name="usedPauses">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item noStyle name="sharedWith">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item noStyle name="paused">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item noStyle name="activated">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item noStyle name="pickup">
        <Input type="hidden" />
      </Form.Item>

      {update && (
        <>
          <Form.Item label="Activated" rules={[{ required: true }]}>
            <Switch defaultChecked={subscription.activated} onChange={(c) => form.setFieldsValue({ activated: c })} />
          </Form.Item>
          <Tooltip title="When inactive: Users can use current balance; Subscription wont be charged; No top-ups; User can not make pay as you go bookings (inc additional credits). ">
            <QuestionCircleFilled />
          </Tooltip>
        </>
      )}

      {/* USER */}
      <Form.Item name={["user", "username"]} label="User" rules={[{ required: true }]}>
        <AutoComplete
          // dropdownMatchSelectWidth={252}
          onClick={() => {
            filterUsers("");
          }}
          options={fileteredUsers}
          onSelect={updateUserId}
          onSearch={filterUsers}
          disabled={update}
          notFoundContent="No users found"
        >
          <Input.Search type="text" />
        </AutoComplete>
      </Form.Item>

      {/* PLAN */}
      <Form.Item name={["plan", "name"]} label="Plan" rules={[{ required: true }]}>
        <AutoComplete
          onClick={() => {
            filterPlans("");
          }}
          options={fileteredPlans}
          onSelect={changePlan}
          onSearch={filterPlans}
          disabled={update}
          notFoundContent="No plans found"
        >
          <Input.Search type="text" />
        </AutoComplete>
      </Form.Item>

      {/* SUBSCRIBED AT */}
      {update && (
        <>
          <Form.Item label="Plan price">
            <Typography.Paragraph>
              {subscription.plan?.priceInclVat} SEK{" "}
              {subscription.plan?.priceStartInclVat !== 0 && `(and ${subscription.plan?.priceStartInclVat} SEK in startup fee)`}
            </Typography.Paragraph>
          </Form.Item>
          <Tooltip title="This cost is in addition to, or instead of the top-up cost">
            <QuestionCircleFilled />
          </Tooltip>
          <Form.Item name={"subscribedAt"} label="Subscribed at">
            <Typography.Paragraph>{subscription["subscribedAtAsDate"]}</Typography.Paragraph>
          </Form.Item>
          <Form.Item label="Binding ends at">
            <Typography.Paragraph>{subscription["bindingPeriodEndsAtAsDate"]}</Typography.Paragraph>
          </Form.Item>
          <Form.Item label="Next subscription payment">
            <Typography.Paragraph>{subscription["nextBillingAtAsDate"]}</Typography.Paragraph>
          </Form.Item>
        </>
      )}

      {/* PAUSES */}
      {update && (
        <>
          <Form.Item label="Remaining top-up-skips">
            <Input.Group compact>
              <Form.Item noStyle name="pausesRemaining" rules={[{ required: true }]}>
                <InputNumber type="number" onChange={(value) => changeUsedPauses(value)} />
              </Form.Item>

              <Form.Item noStyle>
                {(subscription.paused && (
                  <Button onClick={() => unpauseSubscription()} type="primary" ghost={true}>
                    Unpause
                  </Button>
                )) || (
                  <Button onClick={() => pauseSubscription()} danger>
                    Skipp next Top-up
                  </Button>
                )}
              </Form.Item>
              {/* <Form.Item noStyle name="paused" rules={[{ required: true }]} initialValue={false}>
                <Radio.Group buttonStyle="outline" onChange={e => update ? togglePauseSubscription(e.target.value) : null} name="paused">
                  <Radio.Button value={false}>Active</Radio.Button>
                  <Radio.Button className="danger" value={true}>Paused</Radio.Button>
                </Radio.Group>
              </Form.Item> */}
            </Input.Group>
          </Form.Item>
          <Tooltip
            title="Users can pause their subscriptions for their next subscription cycle,
                          meaning they wont be debited or get topped up with credits for that cycle.
                          Observe that subscriptions has a maximum pause limit which cannot be exceeded,
                          so if the subscription cannot be paused, try to update their remaining pauses"
          >
            <QuestionCircleFilled />
          </Tooltip>
        </>
      )}

      {/* SHARED */}
      {update && (
        <Subform
          title="Subscription shared with"
          content={
            <>
              <div style={{ width: "100%", marginBottom: 24 }}>
                {sharedUsers.length > 0 ? (
                  sharedUsers.map((user, i: number) => {
                    return (
                      <Tag key={i} closable onClose={unshareSubscription}>
                        <span style={{ color: "#40a9ff" }} className="sharedUser" data-shareduser={user.username}>
                          {user.username}
                        </span>
                      </Tag>
                    );
                  })
                ) : (
                  <Typography.Paragraph>No users</Typography.Paragraph>
                )}
              </div>
              <Form.Item label="Add user">
                <AutoComplete
                  onClick={() => {
                    filterSharedUsers("");
                  }}
                  options={fileteredSharedUsers}
                  onSelect={update ? shareSubscription : addUserToNewSubscription}
                  onSearch={filterSharedUsers}
                  notFoundContent="No users found"
                >
                  <Input.Search type="text" />
                </AutoComplete>
              </Form.Item>
            </>
          }
        />
      )}

      {/* PRODUCT BALANCE */}
      {/* {(update && (productsSum["balance"] > 0 || productsSum["topUpYield"] > 0) && ( */}
      {(update && (
        <Subform
          title="Balance"
          content={
            <>
              {(products as Array<Product>).map((product, i: number) => {
                return (
                  !product.physical && (
                    <React.Fragment key={`plan-product-${i}`}>
                      <Form.Item noStyle name={["products", i, "id"]} label={product.name}>
                        <Input type="hidden" />
                      </Form.Item>
                      <Form.Item name={["products", i, "balance"]} label={product.name}>
                        <InputNumber
                          type="number"
                          onChange={(value) => changeProductBalance(value, i)}
                          addonAfter={<Typography.Text>Tops up with {product.topUpYield}</Typography.Text>}
                        />
                      </Form.Item>
                      <Tooltip
                        title={
                          <>
                            Product: {product.name}
                            <br></br>
                            Description: {product.description}
                            <br></br>
                            Base price: {product.pricePerPcInclVat} SEK (incl VAT)<br></br>
                            VAT: {product.pricePerPcVatFraction * 100}%
                          </>
                        }
                      >
                        <ExclamationCircleOutlined />
                      </Tooltip>
                    </React.Fragment>
                  )
                );
              })}
              <Typography.Paragraph>
                {
                  subscription.paused || !subscription.activated ? (
                    <em>The plan is on hold and the user will not be topped up with new credit points.</em>
                  ) : (
                    <em>The user will be topped up with new credits on {subscription["nextTopUpAtAsDate"]}</em>
                  )
                  // : <em>The user will be topped up with new credits on {nextTopUp}</em>
                }
              </Typography.Paragraph>
            </>
          }
        />
      )) || (
        <>
          <Subform
            title="Products"
            content={
              <>
                {(products as Array<Product>).map((product, i: number) => (
                  <Tooltip
                    key={`product-${i}`}
                    placement="topLeft"
                    title={`${product.pricePerExcessPcInclVat} SEK (${product.pricePerExcessPcVatFraction}% VAT)`}
                  >
                    <Typography.Text style={{ width: "100%", color: "#434343", marginTop: 10 }}>
                      {product.name}
                      {product.pricePerPcInclVat !== 0 && ` - ${product.pricePerPcInclVat} SEK`}
                      {product.pricePerExcessPcInclVat !== 0 && ` (${product.pricePerExcessPcInclVat} SEK for excessive usage)`}
                    </Typography.Text>
                    {/* <Typography.Text style={{width:"100%", color:"##bfbfbf", marginTop: 10}}>{product.pricePerExcessPcInclVat}</Typography.Text> */}
                  </Tooltip>
                ))}
              </>
            }
          />
          {tspId === 3 && (
            <Subform
              title="Pickup"
              content={
                <div style={{ width: "100%" }}>
                  <Form.Item label="Pickup" rules={[{ required: true }]}>
                    <Switch defaultChecked={true} onChange={(c) => form.setFieldsValue({ pickup: c })} />
                  </Form.Item>
                  <Form.Item label="Pick up date" name="date" rules={[{ required: true }]}>
                    <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item label="How many" name="amount" rules={[{ required: true }]}>
                    <InputNumber type="number" />
                  </Form.Item>
                </div>
              }
            />
          )}
        </>
      )}
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
}
