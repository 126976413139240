import WhiteLabel from "../../models/WhiteLabels";
import HttpClient from "../../clients/HttpClient";

export default class WhiteLabelsService {
    static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

    public static async list(params = {}): Promise<Array<WhiteLabel>> {
        return this.httpClient.get("/white_labels", params).then((res) => {
            return res.data.map((row) => new WhiteLabel(row));
        });
    }

    public static async getWhiteLabel(id): Promise<WhiteLabel> {
        return this.httpClient.get(`/white_labels/${id}`).then((res) => {
            return new WhiteLabel(res.data);
        });
    }

    public static async addWhiteLabel(data): Promise<WhiteLabel> {
        return this.httpClient.post(`/white_labels`, data).then((res) => {
            return new WhiteLabel(res.data);
        });
    }

    public static async editWhiteLabel(id, data): Promise<WhiteLabel> {
        return this.httpClient.put(`/white_labels/${id}`, data).then((res) => {
            return new WhiteLabel(res.data);
        });
    }

    public static async deleteWhiteLabel(id): Promise<boolean> {
        return this.httpClient.delete(`/white_labels/${id}`, null).then((res) => {
            return true;
        });
    }
}
