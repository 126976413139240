import React from "react";

import InfoBox from "../../../components/InfoBox";

import ResourcesTable from "../../../components/tables/ResourceTable";

function Resources() {
  return (
    <div>
      <ResourcesTable></ResourcesTable>
    </div>
  );
}

export default Resources;
