import React, { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../context/ToastContext";
import { Form, Input, Spin, DatePicker, Tooltip, Select } from "antd";
import { LoadingOutlined, QuestionCircleFilled } from "@ant-design/icons";
import ResourceService from "../../services/crud/ResourceService";
import Resource from "../../models/Resource";
import Hub from "../../models/Hub";
import HubService from "../../services/crud/HubService";
import ProductService from "../../services/crud/ProductService";
import Product from "../../models/Product";
import Tsp from "../../models/Tsp";
import TspService from "../../services/crud/TspService";

export default function ResourceForm(props) {
  const id = props.id;
  const form = props.form;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const update: boolean = props.update ?? false;
  const { loading, loadMessage } = useContext(ToastContext);
  const [resource, setResource] = useState<Resource>({} as Resource);
  const [hubs, setHubs] = useState<Hub[]>([] as Hub[]);
  const [products, setProducts] = useState<Product[]>([]);
  const [tsps, setTsps] = useState<Tsp[]>([]);

  useEffect(() => {
    form.resetFields();
    loadMessage("Loading...", async () => {
      if (id) {
        await ResourceService.getResource(id).then((data) => {
          setResource(data.serialize());
        });
      }
      await Promise.all([
        HubService.list(),
        ProductService.list(),
        TspService.list()
      ]).then(([hubs, products, tsps]) => {
        setHubs(hubs);
        setProducts(products);
        setTsps(tsps);
      });
    });
  }, [form, id]);
  const hubOptions = hubs
  .sort((a, b) => ((a.name ?? a.description).toLowerCase().localeCompare((b.name ?? b.description).toLowerCase())))
  .map((h) => {
    return { value: h.id, label: `${h?.name ?? h?.description} (Id: ${h.id})` };
  });

  const productOptions = products.map((p) => {
    return { value: p.id, label: `${p?.name ?? p?.description} (Id: ${p.id})` };
  });

  const tspsOptions = tsps.map((t) => {
    return { value: t.id, label: `${t?.name ?? t?.description} (Id: ${t.id})` };
  });

  const formFieldSpecs = [
    {
      name: "macAddress",
      label: "LINKA Mac Address",
      type: "text",
      rules: [{ required: false }],
      tooltip: "LINKA bike lock mac adreess, obtained using LINKA operator app and scan for nearby locks",
    },
    {
      name: "hubId",
      label: "Hub",
      type: "select",
      rules: [{ required: true }],
      inputProps: {
        options: [...hubOptions],
      },
      tooltip: false,
    },
    {
      name: "id",
      label: "Id",
      type: "text",
      rules: [{ required: false }],
      inputProps: { disabled: true },
      tooltip: false,
    },
    {
      name: "productId",
      label: "Product Id",
      type: "select",
      rules: [{ required: true }],
      inputProps: { options: [...productOptions] },
      tooltip: "The product that this resource will be used for",
    },
    {
      name: "tspId",
      label: "tsp",
      type: "select",
      rules: [{ required: true }],
      inputProps: { options: [...tspsOptions] },
      tooltip: "This is required in order to create a booking",
    },
  ];

  const content = (
    <Form initialValues={{ ...resource }} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      {/* First and last name */}
      <Form.Item label="Type">
        <Form.Item name="type" noStyle rules={[{ required: true }]}>
          <Input type="text" />
        </Form.Item>
      </Form.Item>
      <Tooltip title="Bike type eg. 'Elcykel' (wont't be shown in the app)">
        <QuestionCircleFilled />
      </Tooltip>
      <Form.Item label="Name">
        <Form.Item name="name" noStyle rules={[{ required: true }]}>
          <Input type="text" />
        </Form.Item>
      </Form.Item>
      <Tooltip title="The bicycle name eg. 'Elcykel 5'.">
        <QuestionCircleFilled />
      </Tooltip>
      <Form.Item label="Other">
        <Input.Group compact>
          <Form.Item name="modelId" noStyle rules={[{ required: false }]}>
            <Input type="text" />
          </Form.Item>
          <Form.Item name="size" noStyle rules={[{ required: false }]}>
            <Input type="text" />
          </Form.Item>
          <Form.Item name="other" noStyle rules={[{ required: false }]}>
            <Input type="text" />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Tooltip title="modelId, size, other">
        <QuestionCircleFilled />
      </Tooltip>
      <Form.Item label="Price per hour in cents">
        <Form.Item name="priceIncVatCents" noStyle rules={[{ required: false }]} >
          <Input type="number" />
        </Form.Item>
      </Form.Item>
      <Tooltip title="Price per hour including VAT in cents. Eg. 1050 for 10,50 SEK per hour.">
        <QuestionCircleFilled />
      </Tooltip>
      {formFieldSpecs.map((specs, index) => {
        let field = [];
        switch (specs.type) {
          case "text":
            field.push(
              <Form.Item key={"form-item-" + index} name={specs.name} label={specs.label} rules={specs.rules}>
                <Input key={"form-input-" + index} type={specs.type} {...specs.inputProps} />
              </Form.Item>
            );
            break;
          case "select":
            field.push(
              <Form.Item key={"form-item-" + index} name={specs.name} label={specs.label} rules={specs.rules}>
                <Select key={"form-select-" + index} {...specs.inputProps} />
              </Form.Item>
            );
            break;
        }
        if (specs.tooltip) {
          field.push(
            <Tooltip title={specs.tooltip}>
              <QuestionCircleFilled />
            </Tooltip>
          );
        }
        return field;
      })}
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
}
