import React, { useState, useEffect } from "react";
import { Select, SelectProps } from "antd";
import UserService from "../../../services/crud/UserService";
import User from "../../../models/User";
import TspService from "../../../services/crud/TspService";
import Tsp from "../../../models/Tsp";
import Account from "../../../models/Account";
import AccountService from "../../../services/crud/AccountService";
import Resource from "../../../models/Resource";
import ResourceService from "../../../services/crud/ResourceService";
import Plan from "../../../models/Plan";
import PlanService from "../../../services/crud/PlanService";
import HubService from "../../../services/crud/HubService";
import Hub from "../../../models/Hub";
import WhiteLabel from "../../../models/WhiteLabels";
import WhiteLabelsService from "../../../services/crud/WhiteLabelsService";
import { DefaultOptionType } from "antd/lib/select";

function Users({ valueIndex = "id", ...props }: SelectProps & { valueIndex?: string }) {
  type Type = User;
  const service = UserService;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Type[]>([]);

  useEffect(() => {
    setLoading(true);
    service
      .list()
      .then((data) => setData(data))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Select
      loading={loading}
      options={data.map((data: Type) => {
        return { label: data.username, value: data[valueIndex] } as DefaultOptionType;
      })}
      {...props}
    ></Select>
  );
}

function Tsps(props: SelectProps) {
  type Type = Tsp;
  const service = TspService;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Type[]>([]);

  useEffect(() => {
    setLoading(true);
    service
      .list()
      .then((data) => {
        data.sort((a, b) => a.name.localeCompare(b.name));
        return setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Select
      loading={loading}
      options={data.map((data: Type) => {
        return { label: data.name, value: data.id } as DefaultOptionType;
      })}
      {...props}
    ></Select>
  );
}

function Accounts(props: SelectProps) {
  type Type = Account;
  const service = AccountService;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Type[]>([]);

  useEffect(() => {
    setLoading(true);
    service
      .list()
      .then((data) => setData(data))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Select
      loading={loading}
      options={data.map((data: Type) => {
        return { label: data.name, value: data.id } as DefaultOptionType;
      })}
      {...props}
    ></Select>
  );
}
function Data(props: SelectProps & { data }) {
  return (
    <Select
      options={props.data.map((data) => {
        return { label: data.name, value: data.id } as DefaultOptionType;
      })}
      {...props}
    ></Select>
  );
}
function UserAccounts(props: SelectProps & { userId }) {
  type Type = Account;
  const service = AccountService;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Type[]>([]);

  useEffect(() => {
    setLoading(true);
    service
      .listAccountsOnUser(props.userId)
      .then((data) => setData(data))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId]);
  return (
    <Select
      loading={loading}
      options={data.map((data: Type) => {
        return { label: data.name, value: data.id } as DefaultOptionType;
      })}
      {...props}
    ></Select>
  );
}

function Resources(props: SelectProps) {
  type Type = Resource;
  const service = ResourceService;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Type[]>([]);

  useEffect(() => {
    setLoading(true);
    service
      .list()
      .then((data) => setData(data))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Select
      loading={loading}
      options={data.map((data: Type) => {
        return { label: data.name, value: data.id } as DefaultOptionType;
      })}
      {...props}
    ></Select>
  );
}

function Plans(props: SelectProps) {
  type Type = Plan;
  const service = PlanService;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Type[]>([]);

  useEffect(() => {
    setLoading(true);
    service
      .list()
      .then((data) => setData(data))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Select
      loading={loading}
      options={data.map((data: Type) => {
        return { label: data.name, value: data.id } as DefaultOptionType;
      })}
      {...props}
    ></Select>
  );
}

function Hubs(props: SelectProps) {
  type Type = Hub;
  const service = HubService;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Type[]>([]);

  useEffect(() => {
    setLoading(true);
    service
      .list()
      .then((data) => setData(data))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Select
      loading={loading}
      options={data.map((data: Type) => {
        return { label: data.name, value: data.id } as DefaultOptionType;
      })}
      {...props}
    ></Select>
  );
}

function WhiteLabels(props: SelectProps) {
  type Type = WhiteLabel;
  const service = WhiteLabelsService;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Type[]>([]);
  const options = data.map((data: Type) => {
    return { label: data.name, value: data.id } as DefaultOptionType;
  });
  useEffect(() => {
    setLoading(true);
    service
      .list()
      .then((data) => setData(data))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Select loading={loading} defaultValue={null} options={[{ value: null, label: "No White Label" }, ...options]} {...props}></Select>;
}

export const EntitySelector = { Users, Tsps, Accounts, Resources, UserAccounts, Data, Plans, Hubs, WhiteLabels };
