import HttpClient from "../../clients/HttpClient";
import AccountsKpiConfiguration from "../../models/AccountsKpiConfiguration";

export default class AccountsKpiConfigurationService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async list(params = {}): Promise<Array<AccountsKpiConfiguration>> {
    return this.httpClient.get("/accounts_kpi_config", params).then((res) => {
      return res.data.map((row) => new AccountsKpiConfiguration(row));
    });
  }

  public static async getAccountsKpiConfiguration(id): Promise<AccountsKpiConfiguration> {
    return this.httpClient.get(`/accounts_kpi_config/${id}`).then((res) => {
      return new AccountsKpiConfiguration(res.data);
    });
  }

  public static async addAccountsKpiConfiguration(data): Promise<AccountsKpiConfiguration> {
    return this.httpClient.post(`/accounts_kpi_config`, data).then((res) => {
      return new AccountsKpiConfiguration(res.data);
    });
  }

  public static async editAccountsKpiConfiguration(id, data): Promise<AccountsKpiConfiguration> {
    return this.httpClient.put(`/accounts_kpi_config/${id}`, data).then((res) => {
      return new AccountsKpiConfiguration(res.data);
    });
  }

  public static async deleteAccountsKpiConfiguration(id): Promise<boolean> {
    return this.httpClient.delete(`/accounts_kpi_config/${id}`, null).then((res) => {
      return true;
    });
  }
}
