import React, { useState, useEffect, useContext } from "react";
import { Table, Typography, Input, Tooltip, Form, Button } from "antd";
import { ToastContext } from "../../../context/ToastContext";

import { useNavigate } from "react-router-dom";
import WhiteLabelsTable, { GenericWhiteLabelsTable } from "../../../components/tables/WhiteLabelsTable";
import { OverlayContext } from "../../../context/OverlayContext";
import WhiteLabelForm, { WhiteLabelFormGenericForm } from "../../../components/forms/WhiteLabelForm";
import WhiteLabelsService from "../../../services/crud/WhiteLabelsService";
import WhiteLabel from "../../../models/WhiteLabels";

function WhiteLabels() {
  return (
    <div>
      <Typography.Title level={2}>White Label configurations</Typography.Title>
      <GenericWhiteLabelsTable
        actions={{ edit: true, delete: true }}
        columnFilter={(v) => ["name", "description", "actions"].includes(v?.key + "")}
        formCreate={WhiteLabelFormGenericForm}
        formEdit={WhiteLabelFormGenericForm}
        model={WhiteLabel}
        service={WhiteLabelsService}
      ></GenericWhiteLabelsTable>
    </div>
  );
}

export default WhiteLabels;
